/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useMemo, useState } from "react";

// third-party modules
import { Card, MenuAnchor, DotColor, IconNames } from "@onehq/anton";
import {
  ADD,
  BaseResource,
  GrowlAlertDispatch,
  Link,
  ResourceRoute,
  useDispatchGrowlContext
} from "@onehq/framework";
import { useLazyQuery } from "@apollo/client";

// app modules
import {
  useGetTextStatusOptionsQuery,
  TextStatus,
  LoadSendProjectDocument,
  LoadSendProjectOption,
  TextType,
  useGetTextWithAvailableProjectPhonesQuery
} from "../../../generated/graphql";
import { formatDate, formatPhone } from "../../../utils/helperFunctions";
import { getOptionFieldsAndColor } from "../../../utils/options";
import textStatus from "../../../utils/textStatus";
import { ActionItemPropsWithTestId } from "../../../types";
import { PROJECTS_PATH } from "../../../constants";
import sections from "../sections";
import ProjectTestTexts from "../../projects/ProjectAnchor/ProjectTestTexts";
import { addErrorAlert } from "../../../utils";
import { useNavigate } from "react-router-dom";

const COLORS: DotColor[] = [
  "wine50",
  "mint60",
  "royal50",
  "pea60",
  "gold60",
  "gold80",
  "iris50",
  "iris80"
];

interface TextAnchorProps {
  id: string;
  route: ResourceRoute;
}
const TextAnchor = ({ id }: TextAnchorProps) => {
  // used for growl alerts
  const alert: GrowlAlertDispatch = useDispatchGrowlContext();
  const navigateTo = useNavigate();

  // fetch text data
  const { data, loading } = useGetTextWithAvailableProjectPhonesQuery({
    fetchPolicy: "cache-and-network",
    variables: { id }
  });
  // text data
  const textData = useMemo(() => {
    return data?.text;
  }, [data]);

  const [sendTextQuery] = useLazyQuery(LoadSendProjectDocument);

  // phones for the "from" input
  // the options are the project-phone phones, with the text from phone first
  const fromPhones = useMemo(() => {
    const fromPhone = textData?.fromPhone;
    const projectPhonePhones = textData?.project?.projectPhones?.map(
      p => p.phone
    );
    const cleanFromPhones = Array.from(new Set(projectPhonePhones));
    const index = cleanFromPhones.findIndex(p => p.id === fromPhone?.id);
    if (index > -1) cleanFromPhones.splice(index, 1);
    return fromPhone ? [fromPhone, ...cleanFromPhones] : cleanFromPhones;
  }, [textData]);

  // text body (empty string if not defined)
  const body = textData?.body || "";

  // getter for the word Send or Resend regarding text status
  const SendOrResend =
    textData?.textStatus === TextStatus.Ready ? "Send" : "Resend";

  // show modal state variable
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);

  // text status data and loading state from api
  const { data: statusOptionsData, loading: statusOptionsloading } =
    useGetTextStatusOptionsQuery();

  const textStatusOptions = getOptionFieldsAndColor(statusOptionsData, COLORS);

  // if statuses are loading, show skeleton
  if (loading || statusOptionsloading || !textStatusOptions)
    return <MenuAnchor skeleton />;

  const infoCard = textData
    ? [
        <Card.BasicInfo
          key={"text-menu-anchor-info-1"}
          data={[
            { label: "Status", value: textData.textStatus },
            {
              label: "From",
              value: textData.fromPhone?.number
                ? formatPhone(textData.fromPhone.number)
                : "--"
            },
            {
              label: "To",
              value: textData.toPhone?.number
                ? formatPhone(textData.toPhone.number)
                : "--"
            },
            {
              label: "Project",
              value: (
                <Link to={`/${PROJECTS_PATH}/${textData.project?.id}`}>
                  {textData.project?.name || "--"}
                </Link>
              )
            },
            {
              label: "Texter",
              value: (
                <Link to={`/users/${textData.texter?.id}`}>
                  {textData.texter?.name || "--"}
                </Link>
              )
            },
            { label: "Sent At", value: formatDate(textData.sentAt) }
          ]}
        />,
        <Card.BasicInfo
          key={"text-menu-anchor-info-2"}
          data={[{ label: "Body", value: body }]}
        />
      ]
    : [];

  const sendText = (phonesTo: string[], body = "", fromPhoneId?: string) => {
    setShowModal(false);
    void sendTextQuery({
      fetchPolicy: "no-cache",
      variables: {
        projectId: textData?.project.id,
        option: LoadSendProjectOption.TestText,
        phoneIds: phonesTo,
        body,
        fromPhoneId
      },
      onCompleted: response => {
        const errors =
          (response?.loadSendProject?.errors as string[] | undefined) || [];
        if (errors?.length > 0) {
          addErrorAlert(alert, "Error, text not sent", errors.join(". "));
        } else {
          alert({
            type: ADD,
            payload: {
              title: "All changes saved",
              message: response.loadSendProject.message,
              variant: "success"
            }
          });
          navigateTo(`/projects/${textData?.project.id}/test_texts`);
        }
      },
      onError: err => {
        addErrorAlert(alert, "Error, text not sent", err.message);
        console.error(err.message); // the error if that is the case
      }
    });
  };

  // actions for kebab menu
  const kebabMenuItems = [
    {
      name: SendOrResend,
      icon: "send",
      "data-testid": "action-sendText",
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick: () => handleShow()
    }
  ] as ActionItemPropsWithTestId[];

  const menuAnchorProps = {
    editLinks: sections,
    cards: infoCard,
    name: `${textData?.project?.name} - ${textData?.texter?.name}`,
    // is text status is active (any textStatus in active group) or ready, show send text action
    kebabMenuItems:
      textData?.textStatus === TextStatus.Ready ||
      textData?.textType === TextType.Test
        ? kebabMenuItems
        : undefined,
    subItem: "Text",
    currentStatus: textData?.textStatus,
    statusColor: textStatus(textData?.textStatus || TextStatus.Ready),
    type: textData?.textStatus,
    onEditPath: "general",
    onViewPath: "overview",
    defaultIcon: "book" as IconNames
  };

  return (
    <>
      {/* Menu Anchor */}
      <BaseResource.Anchor {...menuAnchorProps} />
      {/* Send Text Modal */}
      {showModal && textData && (
        <ProjectTestTexts
          projectName={textData.project.name}
          message={textData.body || ""}
          projectMediaUrl={textData.project.mediaUrl}
          projectMediaPosition={textData.project.mediaPosition}
          fromPhones={fromPhones}
          managerPhones={textData.project.manager.phones}
          clientPhones={textData.project.client.phones}
          alreadySelectedPhone={textData.toPhone}
          projectMessage={textData.project.message}
          onSend={sendText}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

export default TextAnchor;
