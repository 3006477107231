// app modules
import { TextStatus, TextType as type } from "../../generated/graphql";
// component's types
import { TextType, WorkData, WorkItem, WorkItemType, WorkType } from "./types";
import { formatPhone } from "../../utils/helperFunctions";

// default item while loading the data of a work
const unloadedCurrentItem: WorkItemType = {
  id: "0",
  text: {
    id: "0",
    textStatus: TextStatus.Ready,
    textType: type.Test,
    project: {
      id: "0",
      message: ""
    },
    toPhone: {
      id: "0",
      number: ""
    }
  }
};

// functions
export function formatWorkList(workDataList: WorkType[]): WorkData[] {
  return workDataList.map((work: WorkType) => formatWork(work));
}

function formatWork(work: WorkType): WorkData {
  const currentText = work.currentItem?.text;

  return {
    id: work.id,
    name: work.name || "",
    generalMessage:
      currentText?.renderedMessage || currentText?.project.message,
    projectId: currentText?.project.id || "",
    items: [],
    currentItem: formatItem(unloadedCurrentItem),
    currItemIdx: 0,
    paginationInfo: {
      loading: false,
      pages: [],
      page: work.currentPage || 0,
      pageIndex: 0, // cant say the page position in the list of pages. calculate when loading pages
      hasNext: false,
      hasPrevious: false,
      total: 0
    },
    progress: { processed: 0, total: 0, errors: 0 },
    mediaUrl: work.currentItem?.text?.project.mediaUrl || undefined,
    status: "ready"
  };
}

export function formatItems(
  workItems: WorkItemType[],
  message?: string
): WorkItem[] {
  return workItems.map(workItem =>
    formatItem(workItem, workItem.text?.renderedMessage || message)
  );
}

function formatItem(workItem: WorkItemType, message?: string): WorkItem {
  return {
    id: workItem.id,
    contact: {
      phone: formatPhone(workItem.text?.toPhone?.number as string),
      message
    },
    text: workItem.text as TextType
  };
}

export function sortByName(array: any[]) {
  array.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
}

export function hasNextItem(work: WorkData, index?: number) {
  return (
    !work.paginationInfo.loading &&
    (hasNextItemInPage(work, index) || hasNextPage(work))
  );
}

export function hasPreviousItem(work: WorkData, index?: number) {
  return (
    !work.paginationInfo.loading &&
    (hasPreviousItemInPage(index) || hasPreviousPage(work))
  );
}

export function hasNextPage(work: WorkData) {
  return work.paginationInfo.hasNext;
}

export function hasPreviousPage(work: WorkData) {
  return work.paginationInfo.hasPrevious;
}

export function hasNextItemInPage(work: WorkData, index?: number) {
  return index !== undefined && index < work.items.length - 1;
}

export function hasPreviousItemInPage(index?: number) {
  return index !== undefined && index > 0;
}
