import { StepperItemIndex as Index } from "@onehq/anton";
import { ProjectStep } from "../generated/graphql";

interface Step {
  stepName: string;
  stepPosition: Index;
}
type StepsMap = Step[];

// the first Step in a StepsMap must be the default one
const projectSteps: StepsMap = [
  {
    stepName: ProjectStep.BasicInfo,
    stepPosition: { index: 0, subIndex: -1 }
  },
  {
    stepName: ProjectStep.Setup,
    stepPosition: { index: 1, subIndex: 0 }
  },
  {
    stepName: ProjectStep.Summary,
    stepPosition: { index: 1, subIndex: 1 }
  },
  {
    stepName: ProjectStep.Sender,
    stepPosition: { index: 2, subIndex: -1 }
  },
  {
    stepName: ProjectStep.SendingSchedule,
    stepPosition: { index: 3, subIndex: -1 }
  },
  {
    stepName: ProjectStep.Texters,
    stepPosition: { index: 4, subIndex: -1 }
  }
];

// a list of steppers' names. currently, we only have one
type StepsMaps = "Project";

// we can have many steppers, each one with their own StepsMap
// this function associate a name with a StepsMap
function stepperNameToStepsMaps(stepperName: StepsMaps) {
  switch (stepperName) {
    case "Project":
    default:
      return projectSteps;
  }
}

// step position (pair index-subindex) to step name in enum (like ProjectStep)
// e.g. {index: 0, subindex: -1} => "BasicInfo"
export const stepIndexToName = (stepsMap: StepsMaps, position: Index) => {
  const steps = stepperNameToStepsMaps(stepsMap);
  const stepName = steps.find(step => {
    return (
      step.stepPosition.index === position.index &&
      step.stepPosition.subIndex === position.subIndex
    );
  })?.stepName;
  return stepName || steps[0].stepName;
};

// step name in enum (like ProjectStep) to step position (pair index-subindex)
// e.g. "BasicInfo" => {index: 0, subindex: -1}
export const stepNameToIndex = (stepsMap: StepsMaps, stepName: string) => {
  const steps = stepperNameToStepsMaps(stepsMap);
  const stepPosition = steps.find(step => {
    return step.stepName === stepName;
  })?.stepPosition;
  return stepPosition || steps[0].stepPosition;
};
