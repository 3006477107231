// TODO: remove if no longer necessary
import React from "react";
import { Fieldset, TextField, PhoneField } from "@onehq/anton";

const ClientPhonesForm = () => {
  return (
    <Fieldset>
      <PhoneField label="Number" name="number" />
      <TextField label="Description" name="description" />
    </Fieldset>
  );
};

export default ClientPhonesForm;
