import React from "react";
import { RowFieldsListFragment } from "../../generated/graphql";
import { Table } from "@onehq/anton";
import { formatDate, formatPhone } from "../../utils";
import { rowColumnNumber as columnNumber } from "../../constants/rowConstants";
import { BaseTableProps } from "../../types";

export type RowTableProps = BaseTableProps<RowFieldsListFragment>;

export const getRowTableColumns = () => {
  const tableHeader = [
    {
      header: "To Phone",
      accessorKey: "toPhoneNumber"
    },
    {
      header: "Sheet",
      accessorKey: "sheet",
      enableSorting: false
    }
  ];

  columnNumber.forEach((col, i) => {
    tableHeader.push({
      header: col,
      accessorKey: `column${columnNumber[i]}`
    });
  });

  tableHeader.push(
    {
      header: "Created At",
      accessorKey: "createdAt"
    },
    {
      header: "Updated At",
      accessorKey: "updatedAt"
    }
  );

  return tableHeader;
};

export const rowTableDataFormatter = (data?: Array<RowFieldsListFragment>) => [
  ...(data?.map((row: RowFieldsListFragment) => {
    return {
      ...row,
      sheet: row.sheet?.name,
      toPhoneNumber: formatPhone(row.toPhoneNumber),
      createdAt: formatDate(row.createdAt),
      updatedAt: formatDate(row.updatedAt)
    };
  }) || [])
];

const RowTable = ({ data = [], ...props }: RowTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={getRowTableColumns()}
      data={rowTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default RowTable;
