/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { badgeCell, CellDataType, Table } from "@onehq/anton";
import { Link } from "@onehq/framework";
import { PhoneListFieldsFragment } from "../../generated/graphql";
import {
  addSpacesBetweenWords,
  addSpacesBetweenWordsWithAcronyms,
  formatPhoneWithProviderCircle,
  phoneStatusBadgeColor
} from "../../utils";
import { BaseTableProps } from "../../types";
import { PHONES_PATH } from "../../constants";

export type PhoneTableProps = BaseTableProps<PhoneListFieldsFragment>;

export const PhoneTableColumns = [
  {
    id: "number",
    header: "Number",
    accessorKey: "number"
  },
  {
    id: "phoneTypeId",
    header: "Type",
    accessorKey: "phoneType"
  },
  {
    id: "phoneStatusId",
    header: "Status",
    accessorKey: "phoneStatus"
  },
  {
    id: "phoneClassId",
    header: "Class",
    accessorKey: "phoneClass"
  },
  {
    id: "state.abbreviation",
    header: "State",
    accessorKey: "state.abbreviation",
    enableSorting: false
  },
  {
    id: "doNotContact",
    header: "Do not contact",
    accessorKey: "doNotContact",
    enableSorting: false
  },
  {
    id: "description",
    header: "Description",
    accessorKey: "description",
    dataType: "longText" as CellDataType
  }
];

export const phoneTableDataFormatter = (
  data?: Array<PhoneListFieldsFragment>
) => [
  ...(data?.map((item: PhoneListFieldsFragment) => {
    const {
      id,
      doNotContact,
      phoneClass,
      phoneType,
      phoneStatus,
      state,
      description
    } = item;

    return {
      doNotContact,
      number: (
        <Link to={`/${PHONES_PATH}/${id}/overview`}>
          {formatPhoneWithProviderCircle(item)}
        </Link>
      ),
      phoneClass,
      phoneStatus: badgeCell({
        value: addSpacesBetweenWordsWithAcronyms(phoneStatus),
        color: phoneStatusBadgeColor(phoneStatus)
      }),
      description,
      phoneType: addSpacesBetweenWords(phoneType),
      state: state?.name
    };
  }) || [])
];

const PhoneTable = ({ data = [], ...props }: PhoneTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={PhoneTableColumns}
      data={phoneTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default PhoneTable;
