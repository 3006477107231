import { DotColor } from "@onehq/anton";
import { TeamStatus } from "../generated/graphql";

export default function teamStatus(status: TeamStatus): DotColor {
  switch (status) {
    case TeamStatus.Active:
      return "pea60" as DotColor;
    case TeamStatus.Inactive:
      return "iris50" as DotColor;
    default:
      return "wine50" as DotColor;
  }
}

export function teamStatusBadgeColor(status: TeamStatus): DotColor {
  switch (status) {
    case TeamStatus.Active:
      return "green" as DotColor;
    case TeamStatus.Inactive:
      return "purple" as DotColor;
    default:
      return "red" as DotColor;
  }
}
