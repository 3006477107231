import { CustomCurrentUserDocument, UserType } from "../generated/graphql";

export const getApolloUserTypeMock = (userType: UserType) => {
  return [
    {
      request: {
        query: CustomCurrentUserDocument,
        variables: {}
      },
      result: {
        data: {
          currentUser: {
            id: "example-id",
            email: "example@onehq.com",
            googleEmailAddress: "example@onehq.com",
            hasRefreshToken: false,
            createdAt: "2022-10-20T11:37:22Z",
            updatedAt: "2022-10-20T11:37:22Z",
            deletedAt: null,
            lockedAt: null,
            name: "example-name",
            phones: [],
            userType, // userType is used for permissions
            work: [],
            __typename: "User"
          }
        }
      }
    }
  ];
};
