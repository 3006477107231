import React from "react";
import { Fieldset, TextField } from "@onehq/anton";
import { theme } from "@onehq/style";

export const listFormOmitValues = [
  "createdAt",
  "updatedAt",
  "lastLoadedAt",
  "listStatus",
  "listStatusName",
  "projectsCount",
  "rowsCount",
  "sheetsCount",
  "sheets",
  "__typename"
];

interface ListFormFieldsProps {
  customName?: string;
  hideLegend?: boolean;
}

const ListFormFields = ({
  customName = "name",
  hideLegend = true
}: ListFormFieldsProps) => (
  <Fieldset
    legend={hideLegend ? undefined : "Basic Info"}
    style={{ right: theme.space.spacing8 }}
  >
    <TextField label="Name" name={customName} required />
    <TextField label="Google Sheet Key" name="googleSheetKey" required />
  </Fieldset>
);

export default ListFormFields;
