// react modules
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// third-party modules
import {
  ActionItemProps,
  Card,
  ConfirmationModal,
  DotColor
} from "@onehq/anton";
import {
  ADD,
  BaseResource,
  REMOVE,
  ResourceRoute,
  useDispatchGrowlContext
} from "@onehq/framework";

// app modules
import {
  refetchGetTeamQuery,
  useDestroyTeamMutation,
  DestroyTeamMutation,
  useGetTeamStatusOptionsQuery,
  TeamFieldsFragment,
  TeamStatus,
  useGetTeamQuery,
  useUpdateTeamMutation,
  ToggleFavoriteMutation,
  useToggleFavoriteMutation,
  useIsFavoriteQuery
} from "../../../generated/graphql";
import {
  addSpacesBetweenWords,
  formatDate,
  formatNumber
} from "../../../utils/helperFunctions";
import teamStatus from "../../../utils/teamStatus";
import { getOptionFieldsAndColor } from "../../../utils/options";
import sections from "../sections";

const dropdownHeading = "UPDATE STATUS";
const COLORS: DotColor[] = ["iris50", "pea60"];

export interface TeamAnchorProps {
  id: string;
  route: ResourceRoute;
}

const TeamAnchor = ({ id }: TeamAnchorProps) => {
  const alert = useDispatchGrowlContext();
  const navigateTo = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [idForAlert] = useState(Math.floor(Math.random() * 10000 + 1));

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const { data } = useGetTeamQuery({
    variables: { id },
    context: {
      headers: {
        isView: "true",
        resource: "Team"
      }
    }
  });
  const team = data?.team;

  const { data: statusOptionsData, loading: statusOptionsloading } =
    useGetTeamStatusOptionsQuery();

  const teamStatusOptions = getOptionFieldsAndColor(statusOptionsData, COLORS);

  const [updateTeamStatus] = useUpdateTeamMutation({
    refetchQueries: [refetchGetTeamQuery({ id })]
  });

  const { data: isFavoriteData } = useIsFavoriteQuery({
    fetchPolicy: "no-cache",
    variables: { teamId: id }
  });

  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  useEffect(() => {
    setIsFavorite(isFavoriteData?.isFavorite || false);
  }, [isFavoriteData]);

  const [toggleFavorite] = useToggleFavoriteMutation({
    onCompleted: (response: ToggleFavoriteMutation) => {
      setIsFavorite(response.toggleFavorite?.isFavorite || false);
    }
  });

  const [destroyTeamMutation] = useDestroyTeamMutation({
    onCompleted: (response: DestroyTeamMutation) => {
      alert({
        type: REMOVE,
        payload: {
          id: idForAlert,
          title: "",
          variant: "success"
        }
      });
      if (Object.keys(response?.destroyTeam?.errors || {}).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: "The team has been deleted successfully",
            variant: "success"
          }
        });
        navigateTo("/teams");
      }
    },
    onError: err => {
      console.error(err); // the error if that is the case
    }
  });

  const deleteTeam = async () => {
    alert({
      type: ADD,
      payload: {
        id: idForAlert,
        title: "Deleting Team",
        variant: "progress",
        indeterminate: true
      }
    });
    await destroyTeamMutation({ variables: { id } });
  };

  const changeStatus = async (
    currentTeam: TeamFieldsFragment,
    newStatus: TeamStatus
  ) => {
    const newTeam = {
      id: currentTeam.id,
      teamStatus: newStatus
    };
    await updateTeamStatus({
      variables: { id: newTeam.id, attributes: newTeam }
    }).then(
      () => {
        console.log("success");
      },
      (err: any) => {
        console.log(err);
      }
    );
  };

  const kebabMenuItems = [
    {
      name: "Delete",
      icon: "trash2",
      color: "wine50",
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick: () => handleShow()
    }
  ] as ActionItemProps[];

  const infoCard = (
    <Card.BasicInfo
      data={[
        { label: "Name", value: team?.name },
        {
          label: "Team Status",
          value: addSpacesBetweenWords(team?.teamStatus)
        },
        {
          label: "Members",
          value: formatNumber(team?.membersCount as number)
        },
        {
          label: "Created At",
          value: formatDate(team?.createdAt)
        },
        {
          label: "Updated At",
          value: formatDate(team?.updatedAt)
        }
      ]}
    />
  );

  if (statusOptionsloading || !teamStatusOptions || !team)
    return <BaseResource.Anchor skeleton />;

  const menuAnchorProps = {
    editLinks: sections,
    cards: [infoCard],
    name: team?.name,
    subItem: "Team",
    statusOptions: teamStatusOptions,
    currentStatus: team?.teamStatus,
    type: team?.teamStatus,
    kebabMenuItems,
    changeStatus: (status: TeamStatus) => changeStatus(team, status),
    dropdown: {
      status: teamStatus(team?.teamStatus),
      heading: dropdownHeading,
      links: teamStatusOptions,
      onClick: (status: TeamStatus) => changeStatus(team, status)
    },
    onFavoriteToggle: () => toggleFavorite({ variables: { teamId: id } }),
    onEditPath: "general",
    onViewPath: "overview",
    favorite: isFavorite
  };

  return (
    <>
      <BaseResource.Anchor {...menuAnchorProps} />
      <ConfirmationModal
        message="Are you sure you want to delete this team?"
        title="Delete Team?"
        confirmLabel="Delete"
        confirmIcon="trash2"
        open={showModal}
        handleClose={handleClose}
        onConfirm={deleteTeam}
      />
    </>
  );
};

export default TeamAnchor;
