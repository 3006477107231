/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { LinkId } from "@onehq/anton";

import {
  CustomDomainListFieldsFragment,
  FilterOperation,
  GetCampaignsListQueryVariables,
  GetCustomDomainsListQueryVariables,
  GetProviderColorListQueryVariables,
  GetTextDefaultsListQueryVariables,
  ProviderColorQueryFieldsFragment,
  ListFilterQueryFilterFields,
  useGetCampaignsListLazyQuery,
  useGetCustomDomainsListLazyQuery,
  useGetProviderColorListQuery,
  useGetListFiltersListLazyQuery,
  useGetTextDefaultsListLazyQuery
} from "../../../generated/graphql";
import {
  ORGANIZATION_MENU_ANCHOR_VIEW_LINKS as LIST_VIEW,
  organizationTabFilters
} from "../../../constants";
import { addSpacesBetweenWords, PAGE_SIZE } from "../../../utils";
import DataOverview from "../../../components/pages/DataOverview";
import { List } from "@onehq/framework";
import {
  TextDefaultTableColumns,
  textDefaultTableDataFormatter
} from "../../../components/TextDefault/TextDefaultTable";
import {
  CampaignTableColumns,
  campaignTableDataFormatter
} from "../../../components/Campaign/CampaignTable";
import AuditReportForm from "../../auditReport/auditReport";
import { useResourceTabsProvider } from "../../../providers/resource_tabs_provider";
import styled from "styled-components";
import { spacingTheme } from "@onehq/style";
import {
  FiltersTableColumns,
  listFilterTableDataFormatter
} from "../../../components/ListFilter/ListFilterTable";
import { message } from "../../../components/ListFilter/utils";
import BillingReportForm from "../../billingReport/billingReport";

export const DomainTableColumns = [
  {
    header: "Domain",
    accessorKey: "domain",
    enableSorting: false
  }
];

export const ProviderColorsTableColumns = [
  {
    header: "Provider",
    accessorKey: "provider",
    enableSorting: false
  },
  {
    header: "Color",
    accessorKey: "color",
    enableSorting: false
  }
];

export const DomainTableDataFormatter = (
  data?: Array<CustomDomainListFieldsFragment>
) => [
  ...(data?.map((item: CustomDomainListFieldsFragment) => {
    return { domain: item.domain };
  }) || [])
];

const FlexContainter = styled.div`
  display: flex;
  width: fit-content;
  justify-content: center;
  gap: 6px;
`;

const ProviderColorShow = styled.div<{ color: string }>`
  height: ${spacingTheme.spacing20};
  width: ${spacingTheme.spacing20};
  border-radius: 2px;
  background-color: ${props => props.color};
`;

export const ProviderColorsTableDataFormatter = (
  data?: Array<ProviderColorQueryFieldsFragment>
) => [
  ...(data?.map((item: ProviderColorQueryFieldsFragment) => ({
    ...item,
    provider: addSpacesBetweenWords(item.provider),
    color: (
      <FlexContainter>
        <ProviderColorShow color={item.color} />
        {item.color.toLocaleUpperCase()}
      </FlexContainter>
    )
  })) || [])
];

const campaignsTableColumns = CampaignTableColumns.filter(
  c => c.accessorKey !== "code"
);

interface OrganizationShowProps {
  id: string;
  activeLinkId: LinkId;
}

const OrganizationShow = ({ activeLinkId, id }: OrganizationShowProps) => {
  const { setValue: resourceTabsProviderSetValue } = useResourceTabsProvider();

  const [
    ,
    {
      data: textDefaultsData,
      loading: loadingTextDefaults,
      refetch: refetchTextDefaults
    }
  ] = useGetTextDefaultsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: organizationTabFilters({
      id,
      selectedTab: LIST_VIEW.TEXT_DEFAULTS
    }).variables as GetTextDefaultsListQueryVariables
  });

  const handleTextDefaultsRefetch = (
    options: GetTextDefaultsListQueryVariables
  ) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchTextDefaults(options);
  };

  const [
    ,
    {
      data: domainsData,
      loading: loadingDomains,
      refetch: refetchCustomDomains
    }
  ] = useGetCustomDomainsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: organizationTabFilters({ id, selectedTab: LIST_VIEW.DOMAINS })
      .variables as GetCustomDomainsListQueryVariables
  });

  const handleCustomDomainsRefetch = (
    options: GetCustomDomainsListQueryVariables
  ) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchCustomDomains(options);
  };

  const [
    ,
    {
      data: campaignsData,
      loading: loadingCampaigns,
      refetch: refetchCampaigns
    }
  ] = useGetCampaignsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: organizationTabFilters({
      id,
      selectedTab: LIST_VIEW.UNREGISTERED_CAMPAIGNS
    }).variables as GetCampaignsListQueryVariables
  });

  const handleCampaignsRefetch = (options: GetCampaignsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchCampaigns(options);
  };

  const [
    ,
    { data: filtersData, loading: loadingFilters, refetch: refetchFilters }
  ] = useGetListFiltersListLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      first: PAGE_SIZE,
      filters: [
        {
          field: ListFilterQueryFilterFields.ClientId,
          operation: FilterOperation.With,
          value: "false"
        },
        {
          field: ListFilterQueryFilterFields.ProjectId,
          operation: FilterOperation.With,
          value: "false"
        }
      ]
    }
  });

  const {
    data: providerColorsData,
    loading: loadingProviderColors,
    refetch: refetchProviderColors
  } = useGetProviderColorListQuery({
    fetchPolicy: "no-cache",
    variables: organizationTabFilters({
      id,
      selectedTab: LIST_VIEW.PROVIDER_COLORS
    }).variables as GetProviderColorListQueryVariables
  });

  const handleProviderColorsRefetch = (
    options: GetProviderColorListQueryVariables
  ) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      after: options.after || ""
    }));
    void refetchProviderColors(options);
  };

  useEffect(() => {
    if (activeLinkId === LIST_VIEW.LIST_FILTERS) {
      void refetchFilters();
    } else if (activeLinkId === LIST_VIEW.DOMAINS) {
      void refetchCustomDomains().then(() => {
        resourceTabsProviderSetValue(
          organizationTabFilters({ id, selectedTab: LIST_VIEW.DOMAINS })
            .variables as GetCustomDomainsListQueryVariables
        );
      });
    } else if (activeLinkId === LIST_VIEW.TEXT_DEFAULTS) {
      void refetchTextDefaults().then(() => {
        resourceTabsProviderSetValue(
          organizationTabFilters({
            id,
            selectedTab: LIST_VIEW.TEXT_DEFAULTS
          }).variables as GetTextDefaultsListQueryVariables
        );
      });
    } else if (activeLinkId === LIST_VIEW.UNREGISTERED_CAMPAIGNS) {
      void refetchCampaigns().then(() => {
        resourceTabsProviderSetValue(
          organizationTabFilters({
            id,
            selectedTab: LIST_VIEW.UNREGISTERED_CAMPAIGNS
          }).variables as GetCampaignsListQueryVariables
        );
      });
    }
  }, [activeLinkId]);

  return (
    <>
      {activeLinkId === LIST_VIEW.OVERVIEW && <DataOverview />}
      {activeLinkId === LIST_VIEW.TEXT_DEFAULTS && (
        <List
          accessor="textDefaults"
          columns={TextDefaultTableColumns}
          data={textDefaultsData}
          dataFormat={textDefaultTableDataFormatter}
          loading={loadingTextDefaults}
          refetchQuery={handleTextDefaultsRefetch}
        />
      )}
      {activeLinkId === LIST_VIEW.DOMAINS && (
        <List
          accessor="customDomains"
          columns={DomainTableColumns}
          data={domainsData}
          dataFormat={DomainTableDataFormatter}
          loading={loadingDomains}
          refetchQuery={handleCustomDomainsRefetch}
        />
      )}
      {activeLinkId === LIST_VIEW.UNREGISTERED_CAMPAIGNS && (
        <List
          accessor="campaigns"
          columns={campaignsTableColumns}
          data={campaignsData}
          dataFormat={campaignTableDataFormatter}
          loading={loadingCampaigns}
          refetchQuery={handleCampaignsRefetch}
        />
      )}
      {activeLinkId === LIST_VIEW.AUDIT_REPORT && <AuditReportForm />}
      {activeLinkId === LIST_VIEW.BILLING_REPORT && <BillingReportForm />}
      {activeLinkId === LIST_VIEW.LIST_FILTERS && (
        <>
          <List
            accessor="listFilters"
            columns={FiltersTableColumns}
            data={filtersData}
            dataFormat={listFilterTableDataFormatter}
            loading={loadingFilters}
            refetchQuery={refetchFilters}
          />
          <div>{message("Organization")}</div>
        </>
      )}
      {activeLinkId === LIST_VIEW.PROVIDER_COLORS && (
        <List
          accessor="providerColors"
          columns={ProviderColorsTableColumns}
          data={providerColorsData}
          dataFormat={ProviderColorsTableDataFormatter}
          loading={loadingProviderColors}
          refetchQuery={handleProviderColorsRefetch}
        />
      )}
    </>
  );
};

export default OrganizationShow;
