// react modules
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// third-party modules
import {
  ActionItemProps,
  Card,
  ConfirmationModal,
  IconNames
} from "@onehq/anton";
import {
  ADD,
  BaseResource,
  ResourceRoute,
  useDispatchGrowlContext
} from "@onehq/framework";

// app modules
import {
  useGetCampaignQuery,
  useDestroyCampaignMutation,
  DestroyCampaignMutation
} from "../../../generated/graphql";
import sections from "../sections";
import { CAMPAIGNS_PATH } from "../../../constants/campaignConstants";

export interface CampaignAnchorProps {
  id: string;
  route: ResourceRoute;
}

const CampaignAnchor = ({ id }: CampaignAnchorProps) => {
  const alert = useDispatchGrowlContext();
  const navigateTo = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [destroyCampaign] = useDestroyCampaignMutation({
    onCompleted: (response: DestroyCampaignMutation) => {
      if (Object.keys(response?.destroyCampaign?.errors || {}).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: "The campaign has been deleted successfully",
            variant: "success"
          }
        });
        navigateTo(`/${CAMPAIGNS_PATH}`);
      }
    },
    onError: err => console.error(err.message)
  });

  const { data, loading } = useGetCampaignQuery({
    variables: { id }
  });
  const campaign = data?.campaign;
  if (loading || !campaign) return <BaseResource.Anchor skeleton />;

  const deleteCampaign = async () => {
    await destroyCampaign({ variables: { id } });
  };

  const kebabMenuItems = [
    {
      name: "Delete",
      icon: "trash2",
      color: "wine50",
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick: () => handleShow()
    }
  ] as ActionItemProps[];

  const infoCard = (
    <Card.BasicInfo
      data={[
        { label: "Provider", value: campaign.brand.provider },
        { label: "Code", value: campaign.code }
      ]}
    />
  );

  const menuAnchorProps = {
    name: campaign.name,
    resourceName: "Campaign",
    cards: [infoCard],
    editLinks: sections,
    kebabMenuItems,
    onEditPath: "general",
    onViewPath: "overview",
    defaultIcon: "briefcase" as IconNames
  };

  return (
    <>
      <BaseResource.Anchor {...menuAnchorProps} />
      <ConfirmationModal
        message="Are you sure you want to delete this Campaign?"
        title="Delete Campaign?"
        confirmLabel="Delete"
        confirmIcon="trash2"
        open={showModal}
        handleClose={handleClose}
        onConfirm={deleteCampaign}
      />
    </>
  );
};

export default CampaignAnchor;
