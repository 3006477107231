/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useMemo } from "react";

// third-party modules
import styled from "styled-components";
import {
  Fieldset,
  SelectField,
  NestedForm,
  useWatchContext,
  MultiSwitchField
} from "@onehq/anton";

// app modules
import {
  useCustomCurrentUserQuery,
  UserType,
  useGetTeamsListOptionsQuery,
  ProjectStatusGroup,
  DeliveryType
} from "../../../generated/graphql";
import ProjectTexterFormFields from "./ProjectTexterFormFields";
import { addSpacesBetweenWords } from "../../../utils";

const StyledNestedForm = styled(NestedForm)`
  margin-top: 20px;
`;

interface ProjectTexterFormProps {
  hideLegend?: boolean;
}

const ProjectTexterForm = ({ hideLegend = true }: ProjectTexterFormProps) => {
  // watchers
  const isEdit = useWatchContext("id");
  const deliveryType = useWatchContext("deliveryType");
  const projectStatusGroup = useWatchContext("projectStatusGroup");

  // queries
  const { data: currentUser } = useCustomCurrentUserQuery({
    fetchPolicy: "cache-and-network"
  });
  const { data: teamsList } = useGetTeamsListOptionsQuery({
    fetchPolicy: "cache-and-network"
  });

  // constants
  const role = currentUser?.currentUser.currentRole.name;
  const userType = role ? (role.replace(/\s/g, "") as UserType) : undefined;
  const isClient = userType === UserType.Client;

  // all teams for options
  const teamsOptions = useMemo(() => {
    return teamsList?.teams?.nodes
      ? teamsList.teams.nodes.map(t => ({ label: t?.name, value: t?.id }))
      : [];
  }, [teamsList]);

  const deliveryTypeOptions =
    Object.keys(DeliveryType).map(item => ({
      label: addSpacesBetweenWords(item),
      value: item
    })) || [];

  const CustomProjectTexters: React.FC = useMemo(() => {
    return () => (
      <ProjectTexterFormFields
        userType={userType}
        isRequired={!isEdit}
        deliveryType={deliveryType}
        projectStatusGroup={projectStatusGroup}
      />
    );
  }, [deliveryType, isEdit, currentUser, projectStatusGroup]);

  function isProjectStatusNotSetup() {
    return (
      projectStatusGroup !== undefined &&
      projectStatusGroup !== null &&
      projectStatusGroup !== ProjectStatusGroup.Setup
    );
  }

  return (
    <Fieldset legend={!hideLegend && "Texters"}>
      {/* @ts-ignore */}
      <MultiSwitchField
        label="Delivery Type"
        name="deliveryType"
        tabs={deliveryTypeOptions}
        disabled={
          currentUser?.currentUser.currentRole?.name !== UserType.Client ||
          isProjectStatusNotSetup()
        }
        required
      />
      {deliveryType === DeliveryType.External && isClient && (
        <>
          {/* @ts-ignore */}
          <SelectField
            isMulti
            label="Teams"
            name="projectTeamsAttributes"
            options={teamsOptions}
            disabled={projectStatusGroup === ProjectStatusGroup.Ready}
          />
        </>
      )}
      <StyledNestedForm
        name="projectTextersAttributes"
        component={CustomProjectTexters}
        addable={false}
      />
    </Fieldset>
  );
};

export default ProjectTexterForm;
