/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { AuditReport as Audit } from "../../generated/graphql";
import { Table, TableProps } from "@onehq/anton";
import { BaseTableProps } from "../../types";
import { toInteger, toNumber } from "lodash";

export type AuditTableProps = BaseTableProps<Audit>;

export const AuditTableColumns: TableProps["columns"] = [
  { header: "Provider", accessorKey: "provider" },
  { header: "Date", accessorKey: "date", dataType: "date" },
  {
    header: "Outbound SMS Count",
    accessorKey: "outboundSmsCount",
    dataType: "number"
  },
  {
    header: "Outbound MMS Count ",
    accessorKey: "outboundMmsCount",
    dataType: "number"
  },
  {
    header: "Inbound SMS Count ",
    accessorKey: "inboundSmsCount",
    dataType: "number"
  },
  {
    header: "Inbound MMS count",
    accessorKey: "inboundMmsCount",
    dataType: "number"
  },
  {
    header: "Outbound SMS Cost (0.035/sms)",
    accessorKey: "outboundSmsCost",
    dataType: "number"
  },
  {
    header: "Outbound MMS Cost (0.035/mms)",
    accessorKey: "outboundMmsCost",
    dataType: "number"
  },
  {
    header: "Inbound SMS Cost (0.02/inbound)",
    accessorKey: "inboundSmsCost",
    dataType: "number"
  },
  {
    header: "Inbound MMS Cost (0.02/inbound)",
    accessorKey: "inboundMmsCost",
    dataType: "number"
  }
];

export const auditTableDataFormatter = (data?: Array<Audit>) => {
  return [
    ...(data?.map((item: Audit) => {
      return {
        provider: item.provider,
        date: new Date(item.date as string),
        outboundSmsCount: toInteger(item.outboundSmsCount),
        outboundMmsCount: toInteger(item.outboundMmsCount),
        inboundSmsCount: toInteger(item.inboundSmsCount),
        inboundMmsCount: toInteger(item.inboundMmsCount),
        outboundSmsCost: toNumber(item.outboundSmsCost) || 0,
        outboundMmsCost: toNumber(item.outboundMmsCost) || 0,
        inboundSmsCost: toNumber(item.inboundSmsCost) || 0,
        inboundMmsCost: toNumber(item.inboundMmsCost) || 0
      };
    }) || [])
  ];
};

const AuditTable = ({ data = [], ...props }: AuditTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={AuditTableColumns}
      data={auditTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default AuditTable;
