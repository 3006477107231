import React from "react";

import { FrameworkApp, buildApolloClient } from "@onehq/framework";

import fragmentTypes from "./generated/fragmentTypes";
import appNavLinks from "./appNavLinks";
import { privateRoutes, publicRoutes } from "./routes";
import Work from "./components/Work";
import { accessToken } from "./client";
import Logo from "./assets/imgs/layout-logo.png";

const apollo = buildApolloClient({
  options: {
    fragmentTypes,
    uri: process.env.REACT_APP_BACKEND_END_POINT,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }
});

const App = () => {
  // TODO: fix apollo configuration
  // FrameworkApp includes the ApolloClient provider
  // so we must manually pass in the client to the currentUser query
  // const { data } = useCustomCurrentUserQuery({ client: client });

  const getAccessToken = () => {
    const auth = accessToken;

    return !!auth;
  };

  return (
    <FrameworkApp
      apollo={apollo}
      linkSections={appNavLinks}
      logo={Logo}
      isAuthenticated={getAccessToken}
      publicRoutes={publicRoutes}
      routes={privateRoutes}
      customBaseRedirect={"/dashboard"}
      footer={<Work />}
    />
  );
};

export default App;
