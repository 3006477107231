import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Authentication,
  TextField,
  FormBuilder,
  Button,
  Alert,
  Fieldset,
  ThemeProvider
} from "@onehq/anton";
import Logo from "../../assets/imgs/authenticationLogo.png";
import { PageContainer, ButtonsWrapper } from "../../components/authStyles";

const AuthBase = Authentication.Base;

// @ts-ignore
const ResponsiveTextField = props => <TextField span={24} {...props} />;

const SignUpPage = () => {
  const navigateTo = useNavigate();

  const [error] = useState<String>("");
  const refForm = useRef<HTMLFormElement>(null);

  const onSubmit = () => {
    navigateTo("../login");
  };

  const formComponent = (
    <FormBuilder
      ref={refForm}
      autosave={false}
      type="dialogForm"
      values={{
        email: "",
        password: ""
      }}
      // eslint-disable-next-line @typescript-eslint/require-await
      onSubmit={async () => {
        onSubmit();
      }}
    >
      <Fieldset>
        <ResponsiveTextField label="Full Name" name="name" />
        <ResponsiveTextField label="Username" name="username" />
        <ResponsiveTextField label="Email" name="email" />
        <ResponsiveTextField type="password" label="Password" name="password" />
        <ButtonsWrapper>
          <Button
            variant="primary"
            fullWidth
            onClick={() => onSubmit()}
            data-testid={"button"}
          >
            Sign Up
          </Button>
        </ButtonsWrapper>
      </Fieldset>
    </FormBuilder>
  );

  return (
    <ThemeProvider>
      <PageContainer>
        <AuthBase logoImg={Logo} logoStyle={{ height: "100px" }}>
          {formComponent}
          {error && <Alert title="error" message={error} variant="warning" />}
        </AuthBase>
      </PageContainer>
    </ThemeProvider>
  );
};

export default SignUpPage;
