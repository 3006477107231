import React from "react";

import {
  Fieldset,
  NestedForm,
  SelectField,
  ColorPickerField
} from "@onehq/anton";
import { providerOptions } from "../../../brands/sections";

const NestedProviderColor: React.FC = () => {
  return (
    <>
      {/* @ts-ignore */}
      <SelectField
        label="Provider"
        name="provider"
        options={providerOptions}
        required
        span={12}
      />
      <ColorPickerField label="Select color" name="color" required span={12} />
    </>
  );
};

const ProviderColorsForm = () => {
  return (
    <Fieldset>
      <NestedForm
        name="providerColors"
        component={NestedProviderColor}
        removable
        addable
      />
    </Fieldset>
  );
};

export default ProviderColorsForm;
