import { Route } from "@onehq/framework";

import TermsShow from "./TermsShow";

const TermsRoute = new Route({
  name: "Terms",
  path: "terms",
  component: TermsShow
});

export default TermsRoute;
