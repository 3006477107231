import React from "react";
import { Fieldset, TextField, SelectField } from "@onehq/anton";
import { teamStatusOptions } from "../../../utils/options";
import { theme } from "@onehq/style";

interface TeamGeneralFormFieldsProps {
  hideLegend?: boolean;
}

const TeamGeneralFormFields = ({
  hideLegend = true
}: TeamGeneralFormFieldsProps) => (
  <Fieldset
    legend={!hideLegend && "Basic Info"}
    style={{ right: theme.space.spacing8 }}
  >
    <TextField label="Name" name="name" required />
    {/* @ts-ignore */}
    <SelectField
      label="Team Status"
      name="teamStatus"
      options={teamStatusOptions}
      required
    />
  </Fieldset>
);

export default TeamGeneralFormFields;
