// react modules
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// third-party modules
import {
  ActionItemProps,
  Card,
  ConfirmationModal,
  IconNames
} from "@onehq/anton";
import {
  ADD,
  BaseResource,
  ResourceRoute,
  useDispatchGrowlContext
} from "@onehq/framework";

// app modules
import {
  useGetBrandQuery,
  useDestroyBrandMutation,
  DestroyBrandMutation
} from "../../../generated/graphql";
import sections from "../sections";
import { BRANDS_PATH } from "../../../constants/brandConstants";

export interface BrandAnchorProps {
  id: string;
  route: ResourceRoute;
}

const BrandAnchor = ({ id }: BrandAnchorProps) => {
  const alert = useDispatchGrowlContext();
  const navigateTo = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [destroyBrand] = useDestroyBrandMutation({
    onCompleted: (response: DestroyBrandMutation) => {
      if (Object.keys(response?.destroyBrand?.errors || {}).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: "The brand has been deleted successfully",
            variant: "success"
          }
        });
        navigateTo(`/${BRANDS_PATH}`);
      }
    },
    onError: err => console.error(err.message)
  });

  const { data, loading } = useGetBrandQuery({
    variables: { id }
  });
  const brand = data?.brand;
  if (loading || !brand) return <BaseResource.Anchor skeleton />;

  const deleteBrand = async () => {
    await destroyBrand({ variables: { id } });
  };

  const kebabMenuItems = [
    {
      name: "Delete",
      icon: "trash2",
      color: "wine50",
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick: () => handleShow()
    }
  ] as ActionItemProps[];

  const infoCard = (
    <Card.BasicInfo
      data={[
        { label: "Client", value: brand.client?.name || "--" },
        { label: "Provider", value: brand.provider },
        { label: "Throughput", value: brand.throughput }
      ]}
    />
  );

  const menuAnchorProps = {
    cards: [infoCard],
    name: brand.name,
    resourceName: "Brand",
    editLinks: sections,
    kebabMenuItems,
    onEditPath: "general",
    onViewPath: "overview",
    defaultIcon: "shield" as IconNames
  };

  return (
    <>
      <BaseResource.Anchor {...menuAnchorProps} />
      <ConfirmationModal
        message="Are you sure you want to delete this Brand?"
        title="Delete Brand?"
        confirmLabel="Delete"
        confirmIcon="trash2"
        open={showModal}
        handleClose={handleClose}
        onConfirm={deleteBrand}
      />
    </>
  );
};

export default BrandAnchor;
