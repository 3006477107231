import { useEffect } from "react";

function useDocumentTitle(titles: (string | undefined)[] | string) {
  const title =
    typeof titles === "string" ? titles : titles.filter(val => val).join(" - ");

  useEffect(() => {
    document.title = title;
  }, [title]);
}

export default useDocumentTitle;
