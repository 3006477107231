import {
  ListFilterFieldsFragment as ListFilter,
  ListFilterInput,
  ListFilterType
} from "../../generated/graphql";
import { BasicOption as Option } from "../../types";
import { stringsToOptions } from "../../utils";

// interfaces
export type FilterTypeGroup = "Carrier" | "State" | "Other";

export interface ListFilterValue {
  type: FilterTypeGroup;
  subType: string[];
}

export interface ListFilterOptions {
  type: FilterTypeGroup;
  subType: Option[];
}

export type ListFilterResource = "Client" | "Project" | "Organization";

// helper values
const { Carrier, State } = ListFilterType;

const ListFilterInputs = [
  "carrierId",
  "id",
  "listFilterType",
  "stateId",
  "_destroy"
];

// helper functions
export function formatFilters(filters: ListFilter[]): ListFilterOptions[] {
  const carriers = filters
    .filter(f => f.carrier)
    .map(f => f.carrierId?.split("::::").pop()) as string[];
  const states = filters
    .filter(f => f.state)
    .map(f => f.state?.name) as string[];
  const others = filters
    .filter(f => !f.carrier && !f.state)
    .map(f => f.listFilterType);

  const final: ListFilterOptions[] = [];
  const carriersFilter: ListFilterOptions = {
    type: "Carrier",
    subType: stringsToOptions(carriers, true, str => str)
  };
  final.push(carriersFilter);

  const statesFilter: ListFilterOptions = {
    type: "State",
    subType: stringsToOptions(states, true, str => str)
  };
  final.push(statesFilter);

  const otherFilters: ListFilterOptions = {
    type: "Other",
    subType: stringsToOptions(others, true)
  };
  final.push(otherFilters);

  return final;
}

export function normalizeFilters(
  filters: ListFilterValue[],
  previous: ListFilter[]
): ListFilterInput[] {
  const updatedCarriers = normalizer(filters, previous, "Carrier");
  const updatedStates = normalizer(filters, previous, "State");
  const updatedOthers = normalizer(filters, previous, "Other");
  const changes = [...updatedCarriers, ...updatedStates, ...updatedOthers];
  return changes.map(c => {
    return ListFilterInputs.reduce((acc, key) => {
      // Add only the specified attributes
      if (key in c) acc[key] = c[key];
      return acc;
    }, {});
  });
}

function normalizer(
  filters: ListFilterValue[],
  previous: ListFilter[],
  type: FilterTypeGroup
) {
  const related = previous.filter(f => is(f, type));
  const olds = related.map(f => getValue(f, type));
  const news = filters.find(f => f.type === type)?.subType || [];
  const dels = olds.filter(o => !news?.find(n => n === o));
  const adds = news.filter(n => !olds.find(o => n === o));

  const initial: ListFilterInput[] = related;
  const final: ListFilterInput[] = initial.map(f => {
    if (dels.includes(getValue(f as ListFilter, type))) f._destroy = true;
    return f;
  });
  adds.forEach(a => {
    if (type === "Other") {
      final.push({ id: null, listFilterType: a as ListFilterType });
    } else if (type === "Carrier") {
      const carrier = `Carrier::::${a}`;
      final.push({ id: null, listFilterType: Carrier, carrierId: carrier });
    } else {
      final.push({ id: null, listFilterType: State, stateId: a });
    }
  });
  return final.filter(f => f.id === null || f._destroy);
}

function is(f: ListFilter, type: FilterTypeGroup): boolean {
  const key = type.toLocaleLowerCase();
  if (type === "Other" && !f.carrier && !f.state) return true;
  else if (f[key]) return true;
  else return false;
}
function getValue(f: ListFilter, type: FilterTypeGroup): string {
  if (type === "Carrier") return f.carrierId?.split("::::").pop() || "";
  else if (type === "State") return f.state?.name || "";
  else return f.listFilterType;
}

export function message(resource: ListFilterResource) {
  let message = "";
  switch (resource) {
    case "Client":
      message = `
        * These List Filters will override the Organization’s List Filters.
        However, they will be overridden by this Client’s Project List Filters.
      `;
      break;
    case "Project":
      message = `
        * These List Filters will override both the Organization’s and Client’s List Filters.
      `;
      break;
    default:
      message = `
        * These List Filters will be overridden by this Organization’s Client and Project List Filters.
      `;
      break;
  }
  return message.trim();
}
