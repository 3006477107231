/* eslint-disable react-hooks/exhaustive-deps */
import {
  ProjectTexterFieldsFragment,
  TextInsights
} from "../../generated/graphql";
import { CellDataType } from "@onehq/anton";
import { formatDate, formatNumber } from "../../utils/helperFunctions";
import { BaseTableProps } from "../../types";

export type ProjectTexterTableProps =
  BaseTableProps<ProjectTexterFieldsFragment>;

export const TextInsightsTableColumns = [
  {
    header: "Provider",
    accessorKey: "provider",
    enableSorting: false
  },
  {
    header: "Time",
    accessorKey: "time",
    enableSorting: false
  },
  {
    header: "Sent by minute",
    dataType: "number" as CellDataType,
    accessorKey: "sentByMinute",
    enableSorting: false
  },
  {
    header: "Average per second",
    dataType: "number" as CellDataType,
    accessorKey: "averagePerSecond",
    enableSorting: false
  }
];

export const textInsightsDataFormatter = (data?: Array<TextInsights>) => [
  ...(data?.map((item: TextInsights) => ({
    provider: item.provider,
    time: formatDate(item.time),
    sentByMinute: formatNumber(item.sentByMinute),
    averagePerSecond: formatNumber(item.averagePerSecond)
  })) || [])
];
