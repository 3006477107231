import React from "react";
import styled from "styled-components";
import { theme } from "@onehq/style";
import { Icon } from "@onehq/anton";

const EmptyContent = styled.div`
  margin-bottom: 2rem;
  flex-direction: column;
  height: 20vh;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  color: ${theme.color.neutral40};
`;

export const NO_DATA_FOUND = "No Data Found..";

const DataOverview = () => (
  <EmptyContent>
    <Icon
      height={45}
      width={45}
      name="menu"
      color={"neutral95"}
      strokeWidth={4.5}
    />
    {NO_DATA_FOUND}
  </EmptyContent>
);

export default DataOverview;
