import {
  FilterOperation,
  GetProjectsListCsvDocument,
  GetRowsListCsvDocument,
  ProjectQueryFilterFields,
  RowQueryFilterFields
} from "../generated/graphql";
import { BaseFilterTabsProps } from "../types";
import { PAGE_SIZE } from "../utils";

export const LISTS_PATH = "lists";

export const LIST_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  PROJECTS: "Projects",
  ROWS: "Rows"
};

export const LIST_VIEW_LINKS = [
  {
    id: LIST_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    text: LIST_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    name: LIST_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    path: "/overview"
  },
  {
    id: LIST_MENU_ANCHOR_VIEW_LINKS.ROWS,
    text: LIST_MENU_ANCHOR_VIEW_LINKS.ROWS,
    name: LIST_MENU_ANCHOR_VIEW_LINKS.ROWS,
    path: "/rows"
  },
  {
    id: LIST_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    text: LIST_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    name: LIST_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    path: "/projects"
  }
];

export const listTabFilters = ({ id, selectedTab }: BaseFilterTabsProps) => {
  const filterOptions = {
    [LIST_MENU_ANCHOR_VIEW_LINKS.PROJECTS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: ProjectQueryFilterFields.ListId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetProjectsListCsvDocument
    },
    [LIST_MENU_ANCHOR_VIEW_LINKS.ROWS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: RowQueryFilterFields.ListId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetRowsListCsvDocument
    }
  };
  return filterOptions[selectedTab];
};
