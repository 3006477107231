import React, { useRef, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import { FormBuilder } from "@onehq/anton";
import { GrowlAlertDispatch, useDispatchGrowlContext } from "@onehq/framework";

import {
  ClientFieldsFragment,
  ClientInput,
  CreateClientMutationResult,
  PhoneType,
  UpdateClientMutationResult
} from "../../generated/graphql";
import { GenericFormProps, SelectFieldOptionType } from "../../types";
import ClientFormFields from "./ClientFormFields";
import {
  addAlert,
  removeAlert,
  showErrorMessage
} from "../../utils/notifications";

export interface ClientInputWithAgencyData extends ClientInput {
  agency?: ClientFieldsFragment["agency"];
}
export interface ClientFormProps
  extends GenericFormProps<ClientInputWithAgencyData> {
  formType?: string;
  resourceToFill?: {
    type: String;
    id: String;
  };
  onSave?: () => void;
  onDiscard?: () => void;
}

interface ClientFormNavigationProps {
  client?: SelectFieldOptionType;
}

const ClientForm = (props: ClientFormProps) => {
  const values = props.values;

  const location = useLocation();
  const navigationProps = location.state as ClientFormNavigationProps;

  const agencyValue = navigationProps?.client?.value || values.agencyId;
  const agencyLabel = navigationProps?.client?.label || values.agency?.name;

  const isEdit = !!(values && values.id);

  // Notification
  const alert: GrowlAlertDispatch = useDispatchGrowlContext();
  const [alertID] = useState(Math.floor(Math.random() * 10000 + 1));

  const refForm = useRef<HTMLFormElement>(null);
  const onSubmit = () => {
    if (refForm && refForm.current) {
      refForm.current.submit();
    }
  };

  const initialValues = {
    ...values,
    phones: values.phones?.length ? values.phones : [{}],
    agencyId: agencyValue
      ? {
          label: agencyLabel,
          value: agencyValue
        }
      : undefined
  };

  return (
    <FormBuilder
      ref={refForm}
      autosave={isEdit}
      values={initialValues}
      type="dialogForm"
      // prettier-ignore
      // eslint-disable-next-line
      onSubmit={async ({...attr}, {setError}) => {
        const alertTitle = isEdit ? "client.save" : "client.create";
        addAlert(alert, alertTitle, alertID);
        // load phones attributes
        const newAttr = _.omit(attr, ['agency']) as any;

        newAttr.phones = attr.phones?.filter(phone => phone.id || phone.number).map(phone => {
          const {id, description} = phone;
          const number = phone.number ? phone.number.replace(/[-()\s_]/g, "") : "";
          
          return {
            id: id || null,
            phoneType: PhoneType.Client,
            number,
            description,
            _destroy: (id && number?.trim() === "" && (!description || description?.trim() === ""))
          };
        });

        if (isEdit) {
          props.mutation({ variables: { id: attr.id, attributes: newAttr }})
            .then(({ data } : UpdateClientMutationResult) => {
              removeAlert(alert, alertID);
              if (data?.updateClient?.errors) {
                showErrorMessage(data?.updateClient?.errors, setError);
              } else {
                props.onSave && props.onSave();
              }
          });
        } else {
          props.mutation({variables: {attributes: newAttr}})
            .then(({ data } : CreateClientMutationResult) => { 
              removeAlert(alert, alertID);
              if (data?.createClient?.errors) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                showErrorMessage(data?.createClient?.errors, setError);
              } else {
                props.onSave && props.onSave();
              }
          });
        }
      }}
    >
      <ClientFormFields {...props} onSubmit={onSubmit} />
    </FormBuilder>
  );
};

export default ClientForm;
