import styled from "styled-components";
import {
  CheckboxField,
  ContentCard,
  Dialog,
  Modal,
  TextField
} from "@onehq/anton";
import { PageContainer } from "../../../components/authStyles";
import { theme } from "@onehq/style";

const { neutral50 } = theme.color;
const { spacing24, spacing32, spacing40 } = theme.space;
const { md, lg } = theme.font;
export const CustomImageContainer = styled(ContentCard)`
  width: 1600px;
  height: 620px;
  background-size: cover;
  background-position: center;
  border-radius: 0 1.6rem 1.6rem 0;
`;

export const PageWrapper = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${theme.color.white};
`;

export const FormContainer = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 52px;
  padding-top: ${spacing32};
`;

export const Footer = styled.div`
  width: 100%;
  height: 88px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: auto;
  background-color: ${props => (props.color ? props.color : "red")};
`;

export const SubmitButtonForm = styled.button`
  width: 100%;
  height: 48px;
  background-color: ${props => (props.color ? props.color : "red")};
  border: none;
  color: white;
  margin: ${theme.space.spacing8} ${theme.space.spacing16};
  border-radius: ${theme.space.spacing4};
  cursor: pointer;
`;

export const InputTittleForm = styled(TextField)`
  background-color: white;
`;

export const HeaderWrapper = styled.div`
  justify-content: center;
  align-items: center;
`;

export const TitleHeader = styled.h1`
  font-size: large;
  text-align: center;
  color: antiquewhite;
`;

export const SubTitleHeader = styled.h2`
  text-align: center;
  font-size: 44px;
  font-weight: 500;
  margin: 0;
  color: ${props => (props.color ? props.color : "red")};
`;

export const FormWrapper = styled.div`
  max-width: 614px;
  width: 100%;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
`;

export const FooterWrapper = styled.div`
  justify-content: center;
  align-items: center;
`;

export const FooterText = styled.h4`
  font-weight: normal;
  text-align: center;
  color: white;
  font-size: ${theme.space.spacing16};
`;

export const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 726px;
`;

export const ResponsiveImage = styled.img`
  height: auto;
  max-width: 200px;
  height: 60px;
  border-radius: 2.5%;
`;

export const CheckboxWrapper = styled.div`
  // display: flex;
  // flex-direction: column;
`;

export const Header = styled.h1`
  background-color: ${props => (props.color ? props.color : "red")};
  text-align: center;
  display: inline-block;
  color: white;
  margin: 0;
  width: 100%;
  font-size: 48px;
  padding: 13.5px 0;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  margin-top: ${props => props.theme.space.spacing32};
  margin-bottom: ${props => props.theme.space.spacing32};
`;

export const StyledSpan = styled.span<{ color?: string; marginLeft?: boolean }>`
  display: flex;
  align-items: flex-start;
  color: #334466;
  margin-left: -165px;
  font-size: 14px;
`;

export const CustomLink = styled.a`
  color: ${props => props.color};
  cursor: pointer;
  text-decoration: underline;
`;

export const StyledCheckboxField = styled(CheckboxField)`
  margin: 0;
  padding: 0;
`;

export const LinkIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const StyledModal = styled(Modal)`
  opacity: 1 !important;
  transform: scale(1) !important;
  flex-direction: row;
  gap: ${theme.space.spacing20};
  width: 85%;
  height: 100%;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${neutral50};
  font-size: ${lg.size};
  font-weight: ${md.type.medium.fontWeight};
  line-height: 1;
  padding-bottom: ${spacing24};
  overflow-y: auto;
`;

export const StyledDialog = styled(Dialog)`
  box-shadow: none;
`;

export const CloseWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: end; // This will push the close button to the far right
  align-items: center;
`;

export const DialogBody = styled.div`
  position: relative;
`;

export const CheckboxWithLinksWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ScrollableContent = styled.div`
  font-size: ${lg.size};
  font-weight: ${md.type.medium.fontWeight};
  line-height: 1;
  height: 100%;
  width: fit-content;
  overflow-y: auto; /* Enables vertical scrolling */
  padding: 20px 40px 20px 40px; /* Padding inside the container */
`;

export const InfoContainer = styled.div`
  background-color: white;
`;

export const Text = styled.p`
  color: ${neutral50};
  font-size: 20px;
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: 996px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin: 0 auto;
`;

export const StyledH2 = styled.h2`
  font-size: 32px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  color: ${props => (props.color ? props.color : "red")};
  margin-right: auto;
`;

export const AlertWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Banner = styled.div`
  padding: ${spacing40} 0;
  width: 100%;

  & > * + * {
    margin-top: ${spacing24};
  }
`;
