// react modules
import React from "react";

// third-party modules
import styled, { css } from "styled-components";
import { theme } from "@onehq/style";
import {
  Dialog as DefaultDialog,
  useGetValue,
  useSetFieldValue
} from "@onehq/anton";
import { ADD, useDispatchGrowlContext } from "@onehq/framework";

// app modules
import {
  useCreateCampaignMutation,
  useCreateClientMutation,
  useCreateListMutation,
  useCreatePhoneMutation,
  useCreateTeamMutation,
  useCustomCurrentUserQuery
} from "../../generated/graphql";
import ClientForm from "../Client/ClientForm";
import PhoneForm from "../Phone/PhoneForm";
import ListForm from "../List/ListForm";
import TeamForm from "../../pages/teams/sections/TeamGeneralForm";
import CampaignForm from "../Campaign/CampaignForm";
import { formatPhoneOption } from "../../utils";

const { spacing8, spacing16 } = theme.space;

const Dialog = styled(DefaultDialog)<{ footerHeigh?: string }>`
  border-radius: ${theme.border.radius.lg};
  max-width: 592px;
  max-height: 80%;
  width: 70%;
  overflow: auto;
  position: fixed;
  z-index: 4;
  right: ${spacing16};
  ${props =>
    props.footerHeigh !== undefined &&
    css`
      bottom: ${props.footerHeigh};
    `}
`;

const Header = styled(DefaultDialog.Header)`
  border-top-left-radius: ${spacing8};
  border-top-right-radius: ${spacing8};
`;

const FormWrapper = styled.div`
  padding: ${spacing16};

  & > form > :first-child > :last-child {
    padding: ${spacing8};
    padding-top: 0;
  }
`;

const Overlay = styled.div`
  background-color: rgba(31, 46, 76, 0.2);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
`;

const FormConfigs = {
  Client: {
    form: ClientForm,
    mutation: useCreateClientMutation
  },
  Phone: {
    form: PhoneForm,
    mutation: useCreatePhoneMutation
  },
  List: {
    form: ListForm,
    mutation: useCreateListMutation
  },
  Team: {
    form: TeamForm,
    mutation: useCreateTeamMutation
  },
  Campaign: {
    form: CampaignForm,
    mutation: useCreateCampaignMutation
  }
};

export interface FloatingFormProps {
  isEdit?: boolean;
  open: boolean;
  onClose: () => void;
  // if we are gonna use attachNewValueToInput, these props are mostly irrelevant
  searchFieldInputs?: any;
  setSearchFieldInputs?: React.Dispatch<React.SetStateAction<any>>;
  setSubmitForm?: Function;
  //
  phoneToAdd?: "phoneId" | "externalPhoneId" | "toPhoneId" | "fromPhoneId";
  variant?: "Client" | "Phone" | "List" | "Team" | "Campaign";
  defaultValues?: any; // default values for the form
  showOverlay?: boolean; // the form will be display with an overlay
  // to be able to use this feature, this floatingform must be rendered inside the FormBuilder that contains the searchInput
  attachNewValueToInput?: boolean; // if true, adding a new element fetch the new element in the input (gets selected)
  customFieldName?: string; // ignore standard variantId format (and phone formats) and directly sets the name of the field
  customValueFormatter?: (value: any) => any;
}

const FloatingForm = ({
  isEdit = false,
  open,
  onClose,
  searchFieldInputs,
  setSearchFieldInputs,
  setSubmitForm,
  phoneToAdd = "phoneId",
  variant = "Client",
  defaultValues = {},
  showOverlay = false,
  attachNewValueToInput = false,
  customFieldName,
  customValueFormatter
}: FloatingFormProps) => {
  const Form = FormConfigs[variant].form;
  const useCreateMutation = FormConfigs[variant].mutation;

  // alerts
  const alert = useDispatchGrowlContext();
  const getValue = useGetValue();
  const setValue = useSetFieldValue();

  // current input name to fetch new value
  const inputName =
    customFieldName ||
    (variant === "Phone" ? phoneToAdd : variant.toLowerCase() + "Id");

  const attachNewElementToInput = (value: any) => {
    // get current value of the input
    const currentInputValue = getValue(inputName);
    // if current input is a multi select, fetch new value into selected elements, otherwise just set new value
    const newInputValue = Array.isArray(currentInputValue)
      ? [...currentInputValue, value]
      : value;
    // set new value
    setValue(inputName, newInputValue);
  };

  const [create] = useCreateMutation({
    onCompleted: (response: any) => {
      const result = response?.[`create${variant}`];
      const newOptionValue = customValueFormatter
        ? customValueFormatter(result.resource)
        : result.resource?.id;

      const hasErrors = Object.keys(result?.errors as Object).length > 0;

      // load the initial input with the new data
      if (!hasErrors && variant === "Phone") {
        const newOption = isEdit
          ? newOptionValue
          : {
              ...formatPhoneOption(result.resource),
              value: newOptionValue
            };

        !!setSearchFieldInputs &&
          setSearchFieldInputs({
            ...searchFieldInputs,
            [inputName]: newOption
          });
        attachNewValueToInput && attachNewElementToInput(newOption);
      } else if (!hasErrors) {
        const newOption = isEdit
          ? newOptionValue
          : {
              label: result.resource?.name,
              value: newOptionValue
            };
        !!setSearchFieldInputs &&
          setSearchFieldInputs({
            ...searchFieldInputs,
            [inputName]: newOption
          });
        attachNewValueToInput && attachNewElementToInput(newOption);
      }

      if (!hasErrors) {
        !!setSubmitForm && setSubmitForm(true);
        onClose();
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: `The ${variant} has been created successfully`,
            variant: "success"
          }
        });
      } else {
        const errors = Object.entries(result.errors as Object);
        const message = errors.reduce((previous, [key, value]) => {
          const space = previous ? " " : "";
          return `${previous}${space}${key}: ${value}.`;
        }, "");
        alert({
          type: ADD,
          payload: { title: "Error", message, variant: "error" }
        });
      }
    },
    onError: err => console.error(err)
  });

  // TODO: create a way to know the footer height or it to be of no relevance here.
  // currently, there is not a way to get the footer height, so we will use the a fixed value, the work height: 82px.
  const { data: currentUser } = useCustomCurrentUserQuery();
  const hasWorkBar = !!currentUser?.currentUser.work?.length;
  const dialogBottomSpace = `calc(${hasWorkBar ? 82 : 0}px + ${spacing16})`;

  return (
    <>
      {/* we don't use 'open' in the Dialog's 'open' prop in order to reset the inputs */}
      {open && (
        <>
          <Dialog open onClose={onClose} footerHeigh={dialogBottomSpace}>
            <Header>New {variant}</Header>
            <FormWrapper>
              <Form
                mutation={create}
                values={defaultValues}
                onDiscard={onClose}
              />
            </FormWrapper>
          </Dialog>
          {showOverlay && <Overlay onClick={onClose} />}
        </>
      )}
    </>
  );
};

export default FloatingForm;
