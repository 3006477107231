import React from "react";
import {
  isOptPage,
  redirectToOptPages
} from "../../../utils/redirectToOptPages";

export const privacyContent = (
  <>
    <h2>No privacy terms found</h2>
  </>
);

const PrivacyShow = () => {
  const selectedComponent = () => {
    if (isOptPage()) {
      return redirectToOptPages();
    } else {
      return privacyContent;
    }
  };
  return selectedComponent();
};

export default PrivacyShow;
