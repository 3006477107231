/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { LinkId } from "@onehq/anton";
import { List } from "@onehq/framework";

import {
  FilterOperation,
  GetProjectsListQuery,
  GetProjectsListQueryVariables,
  GetTextsListQuery,
  GetTextsListQueryVariables,
  ProjectTexterQueryFilterFields,
  useGetProjectsListLazyQuery,
  useGetProjectTextersListLazyQuery,
  useGetTextsListLazyQuery
} from "../../../generated/graphql";
import {
  getTextTableColumns,
  useTextTableDataFormatter
} from "../../../components/Text/TextTable";
import {
  ProjectTableColumns,
  projectTableDataFormatter
} from "../../../components/Project/ProjectTable";
import {
  userTabFilters,
  USER_MENU_ANCHOR_VIEW_LINKS as VIEW_LINKS
} from "../../../constants";
import DataOverview from "../../../components/pages/DataOverview";
import { useResourceTabsProvider } from "../../../providers/resource_tabs_provider";

interface UserShowProps {
  id: string;
  setTotalPages?: Function;
  activeLinkId: LinkId;
}

const UserShow = ({ id, ...props }: UserShowProps) => {
  const { setValue: resourceTabsProviderSetValue } = useResourceTabsProvider();

  useEffect(() => {
    if (props.activeLinkId === VIEW_LINKS.PROJECTS) {
      void refetchProjectTexters().then(results => {
        void refetchProjects();
        resourceTabsProviderSetValue(
          userTabFilters({
            id,
            selectedTab: VIEW_LINKS.PROJECTS,
            projectTexters: results.data.projectTexters?.nodes?.map(
              pt => pt?.projectId as string
            )
          }).variables as GetProjectsListQueryVariables
        );
      });
    } else if (props.activeLinkId === VIEW_LINKS.TEXTS) {
      void refetchTexts().then(() => {
        resourceTabsProviderSetValue(
          userTabFilters({ id, selectedTab: VIEW_LINKS.TEXTS })
            .variables as GetTextsListQueryVariables
        );
      });
    }
  }, [props.activeLinkId]);

  const [, { data: textsData, loading: loadingTexts, refetch: refetchTexts }] =
    useGetTextsListLazyQuery({
      fetchPolicy: "no-cache",
      variables: userTabFilters({ id, selectedTab: VIEW_LINKS.TEXTS })
        .variables as GetTextsListQueryVariables,
      onCompleted: (response: GetTextsListQuery) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        props.setTotalPages && props.setTotalPages(response.texts.totalCount);
      }
    });

  const handleTextsRefetch = (options: GetTextsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchTexts(options);
  };

  const formattedTexts = useTextTableDataFormatter(
    // @ts-ignore
    textsData?.texts?.nodes || []
  );

  // getting project texters related to this user
  const [, { data, loading, error, refetch: refetchProjectTexters }] =
    useGetProjectTextersListLazyQuery({
      fetchPolicy: "cache-and-network",
      variables: {
        filters: [
          {
            field: ProjectTexterQueryFilterFields.UserId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      }
    });

  const hasValues =
    !!data && !loading && !error && !!data.projectTexters?.nodes;
  const projectTexters = hasValues
    ? data.projectTexters?.nodes?.map(pt => pt?.projectId as string)
    : [];

  // getting projects to show, using the projectTexters list to make the filters
  const [
    ,
    { data: projectsData, loading: loadingProjects, refetch: refetchProjects }
  ] = useGetProjectsListLazyQuery({
    fetchPolicy: "no-cache",
    variables: userTabFilters({
      id,
      projectTexters,
      selectedTab: VIEW_LINKS.PROJECTS
    }).variables as GetProjectsListQueryVariables,
    onCompleted: (response: GetProjectsListQuery) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      props.setTotalPages && props.setTotalPages(response.projects.totalCount);
    }
  });

  const handleProjectsRefetch = (options: GetProjectsListQueryVariables) => {
    resourceTabsProviderSetValue(prev => ({
      ...prev,
      ...options
    }));
    void refetchProjects(options);
  };

  // reset counter in overview view
  if (props.setTotalPages && props.activeLinkId === VIEW_LINKS.OVERVIEW)
    props.setTotalPages(null);

  return (
    <>
      {props.activeLinkId === VIEW_LINKS.OVERVIEW && <DataOverview />}
      {props.activeLinkId === VIEW_LINKS.TEXTS && (
        <List
          accessor="texts"
          columns={getTextTableColumns()}
          data={{
            ...textsData,
            texts: {
              ...textsData?.texts,
              nodes: formattedTexts
            }
          }}
          loading={loadingTexts}
          refetchQuery={handleTextsRefetch}
        />
      )}
      {props.activeLinkId === VIEW_LINKS.PROJECTS && (
        <List
          accessor="projects"
          columns={ProjectTableColumns}
          data={projectsData}
          dataFormat={projectTableDataFormatter}
          loading={loadingProjects}
          refetchQuery={handleProjectsRefetch}
        />
      )}
    </>
  );
};

export default UserShow;
