import React from "react";
import styled from "styled-components";
import {
  isOptPage,
  redirectToOptPages
} from "../../../utils/redirectToOptPages";

export const StyledPageContainer = styled.div`
  width: auto;
  height: 100%;
  text-align: justify;
  background-color: #ffffff;
  font-size: 15px;
  padding: 100px;
  overflow-y: auto;
`;

export const termsContent = (
  <>
    {`PRIVACY POLICY`}
    <br />
    <br />
    {`Last Updated: 2022-12-21.`}
    <br />
    <br />
    {`We value your privacy, and we want to help make your experience with Ready
    Gop ("ReadyGOP", "we", "us", "our") as satisfying and safe as possible.
    We have established this Privacy Policy to explain how ReadyGOP collects
    Personal Information of individuals who visit our Web site, send us
    e-mails, or participate in features and services offered online, through
    subscription or otherwise, (collectively, the "Site"), and how ReadyGOP
    uses and discloses that Personal Information. ReadyGOP reserves the
    right, at its discretion, to change, modify, add, or remove portions of
    this Privacy Policy at any time by posting the changes to this page. Ready
    Gop will endeavor to notify you of changes made to this Privacy Policy via
    the email address you provided to ReadyGOP. You understand that you have
    an affirmative obligation to check this Privacy Policy periodically for
    changes and you hereby agree to periodically review this Privacy Policy
    for such changes. Your continued use of the Site and the services provided
    through the Site following the posting of changes to this Privacy Policy
    will constitute your acceptance of these changes. This Privacy Policy was
    last updated on March 2, 2016 "Personal Information," as used herein, is
    information that allows a person to directly identify an individual, such
    as name or email address, and information that may later be combined with
    such identifying information. This Privacy Policy is incorporated into and
    is subject to the ReadyGOP Terms of Use. By using the Site, you expressly
    consent to the information handling practices described in this Privacy
    Policy.`}
    <br />
    <br />
    {`THE SITE.`}
    <br />
    {`ReadyGOP enables registered users ("ReadyGOP Users") to connect and
    communicate with other registered users, collaborate and participate
    within a message board, and utilize other services and features of the
    website. In its operation of the Site, and its provision of its services
    and features to ReadyGOP Users, ReadyGOP collects, uses, and retains all
    information, including Personal Information and other data that you
    disclose when you register, as well as other data that may be collected
    after your registration, such as through utilizing services and features
    of the Site.`}
    <br />
    <br />
    {`COLLECTION OF PERSONAL INFORMATION.`}
    <br />
    {`You must register to become an ReadyGOP User and participate in the
    message board and other services and features of the Site. As part of the
    registration and as required by other services and features of the Site,
    you may be required to provide certain Personal Information, including
    your name, email address, date of birth, and other information. You also
    may have other opportunities to voluntarily provide Personal Information
    through connections you make and interactions you have with other Ready
    Gop Users, or through services and features offered or provided by the
    Site. For example, if you contact us to provide feedback or request
    support we collect your email address and other Personal Information that
    you provide. Other ReadyGOP Users may request additional Personal
    Information from you. Certain Personal Information collected may be
    retained. Other Personal Information may be passed along by collaborating
    or interacting with other ReadyGOP Users, such as within a message board.
    Please note, however, that while the general public can not view
    information provided on the message board, other ReadyGOP Users can view
    this information, even absent a direct connection to you.`}
    <br />
    <br />
    {`PASSIVE DATA COLLECTION.`}
    <br />
    {`ReadyGOP, like other web-based services, automatically collects and
    stores information on our server logs from your browser when you use the
    Site. ReadyGOP may use a variety of methods to collect this information.
    The information that ReadyGOP collects with these automated methods may
    include, for example, your IP address, cookie information, a unique device
    or user ID, browser type, system type, the content and pages that you
    access on the Site, and the "referring URL" (i.e., the page from which you
    navigated to the Site). We may also use cookies on the Site to recognize
    your ReadyGOP ID, and to store references to your ReadyGOP ID and session
    validators on your hard drive. You may adjust your web browser software if
    you do not wish to receive cookies, but this may prevent you from taking
    advantage of some of the Site's features. ReadyGOP does not currently
    recognize "do not track" signals from your web browser. We may use
    passively-collected information to administer, operate, and improve the
    Site and our other services and systems, and to provide services and
    content that are tailored to you. If we directly combine any information
    gathered through passive means with Personal Information, we treat the
    combined information as Personal Information under this Privacy Policy.
    Otherwise, we use information collected by passive means in aggregated or
    other non-personally identifiable forms. Websites visited before visiting
    the Site might place Personal Information within the URL of your browser
    during a visit to that previous website. ReadyGOP may unintentionally
    collect that information as part of referring URL information that it
    collects.`}
    <br />
    <br />
    {`USE OF PERSONAL INFORMATION.`}
    <br />
    {`We may use Personal Information to operate, provide, improve, and maintain
    the Site; to develop new products and services; to prevent abusive and
    fraudulent use of the Site; to send you administrative messages, content,
    and other services and features in which we believe you may be interested;
    and for other administrative and internal business purposes.`}
    <br />
    <br />
    {`DISCLOSURE OF PERSONAL INFORMATION.`}
    <br />
    {`ReadyGOP is not in the business of renting or selling email addresses and
    other information, and does not share Personal Information with third
    parties except in the limited circumstances described in this Privacy
    Policy. ReadyGOP may provide Personal Information to third parties for
    their use in performing internal business functions (e.g., payment
    processing, maintenance, security, data analysis, or data hosting) on our
    behalf. ReadyGOP may disclose Personal Information if, in good faith, it
    is believed that doing so is required by a subpoena or other judicial or
    administrative order or otherwise required by law. Additionally, ReadyGOP
    may disclose Personal Information if, in good faith, we deem it
    appropriate or necessary to prevent violation of the ReadyGOP Terms and
    Conditions of Use or our other agreements; take precautions against
    liability; protect the rights, property, or safety of ReadyGOP, any
    individual, or the general public; maintain and protect the security and
    integrity of our services or infrastructure; protect ourselves and our
    services from fraudulent, abusive, or unlawful uses; investigate and
    defend ourselves against third-party claims or allegations; or assist
    government enforcement agencies.`}
    <br />
    <br />
    {`CHANGE OF OWNERSHIP.`}
    <br />
    {`In the event of a change in ownership, or a merger with, acquisition by,
    or sale of assets to, another entity, we reserve the right to transfer all
    of your Personal Information, including email addresses, to that entity.`}
    <br />
    <br />
    {`SECURITY.`}
    <br />
    {`We use industry standard security measures to protect against the loss,
    misuse, and alteration of Personal Information under our control. Although
    we make good faith efforts to maintain the security of such Personal
    Information, we cannot guarantee that it will remain free from
    unauthorized access, use, disclosure, or alteration. Further, we cannot
    guarantee that our security measures will prevent unauthorized persons
    from illegally accessing or obtaining this information. We do not assume
    any liability to third parties whose information you post or transmit in
    any form. Please keep your password secure. If we become aware of a
    security breach, we may attempt to notify you electronically so that you
    can take appropriate protective steps. By using the Site or providing
    Personal Information to us, you agree that we can communicate with you
    electronically regarding security, privacy, and administrative issues
    relating to your use of the Site.`}
    <br />
    <br />
    {`YOUR CALIFORNIA PRIVACY RIGHTS.`}
    <br />
    {`California Civil Code Section 1798.83 permits users that are residents of
    California to request certain information regarding our disclosures of
    personally identifiable information to third parties for such third
    parties' direct marketing purposes. If you are a California resident and
    would like to make such a request, please contact us using the contact
    information below.`}
    <br />
    <br />
    {`CHILDREN'S ONLINE PRIVACY PROTECTION ACT.`}
    <br />
    {`The Site is not for use by those under the age of 13. If you use the Site,
    you hereby represent and warrant that you are at least 13 years of age. In
    compliance with the Children's Online Privacy Protection Act, any
    information we receive from users we believe to be under the age of 13
    will be purged from our database.`}
    <br />
    <br />
    {`INTERNATIONAL USE.`}
    <br />
    {`The Site is hosted in the United States. If you use the Site from any
    other region with laws governing data collection and use that may differ
    from U.S. law, please note that by providing Personal Information under
    this Privacy Policy, you consent to the use of Personal Information in
    accordance with this Privacy Policy and the transfer of your Personal
    Information to the United States.`}
    <br />
    <br />
    {`CONTACTING ReadyGOP.`}
    <br />
    {`If you have questions about this Privacy Policy, you may contact us at
    www.readygop.com or in writing at ReadyGOP, 11551 Ash St. Suite 205,
    Leawood, KS 66211.`}
  </>
);

const TermsShow = () => {
  const selectedComponent = () => {
    if (isOptPage()) {
      return redirectToOptPages();
    } else {
      return termsContent;
    }
  };
  return selectedComponent();
};

export default TermsShow;
