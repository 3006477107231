import { Route } from "@onehq/framework";

import Dashboard from "./dashboard";

const BaseRoute = new Route({
  name: "Base",
  path: "",
  component: Dashboard
});

const DashboardRoute = new Route({
  name: "Dashboard",
  path: "dashboard",
  component: Dashboard
});

export { BaseRoute };
export default DashboardRoute;
