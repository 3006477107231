import { Button, Fieldset } from "@onehq/anton";
import styled from "styled-components";
import { theme } from "@onehq/style";

const { spacing8 } = theme.space;

// fieldset with the input's padding (8rem)
export const StyledFieldset = styled(Fieldset)`
  padding: ${spacing8};
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${spacing8} ${spacing8};
`;

export const NoPaddingButton = styled(Button)`
  padding: 0;
`;
