// react modules
import React from "react";

// third-party modules
import { Card, IconNames } from "@onehq/anton";
import { BaseResource, ResourceRoute } from "@onehq/framework";

// app modules
import { useGetOrganizationQuery } from "../../../generated/graphql";
import sections from "../OrganizationEdit/sections";

export declare interface OrganizationAnchorProps {
  id: string;
  route: ResourceRoute;
}

const OrganizationAnchor = ({ id }: OrganizationAnchorProps) => {
  const { data, loading } = useGetOrganizationQuery({
    fetchPolicy: "cache-and-network",
    variables: { id }
  });
  const organization = data?.organization;

  if (loading || !organization) return <BaseResource.Anchor skeleton />;

  const infoCard = [
    <Card.BasicInfo
      key={"organization-menu-anchor-info-1"}
      data={[
        { label: "Id", value: organization.id },
        {
          label: "Name",
          value: organization.name
        },
        {
          label: "Domain",
          value: organization.domain
        },
        {
          label: "TimeZone",
          value: organization.timeZone?.name
        }
      ]}
    />
  ];

  const menuAnchorProps = {
    cards: infoCard,
    editLinks: sections,
    name: `${organization.name}`,
    photoUrl: organization.logotypeUrl,
    subItem: "Organization",
    onEditPath: "general",
    onViewPath: "overview",
    defaultIcon: "settings" as IconNames
  };

  return <BaseResource.Anchor {...menuAnchorProps} />;
};

export default OrganizationAnchor;
