/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { Link } from "@onehq/framework";
import { BrandListFieldsFragment } from "../../generated/graphql";

export const BrandTableColumns = [
  {
    id: "name",
    header: "Name",
    accessorKey: "brandName"
  },
  {
    id: "clientName",
    header: "Client",
    accessorKey: "clientName",
    enableSorting: false
  },
  {
    id: "providerName",
    header: "Provider",
    accessorKey: "providerName",
    enableSorting: false
  },
  {
    id: "throughput",
    header: "Throughput",
    accessorKey: "throughput"
  },
  {
    id: "code",
    header: "Code",
    accessorKey: "code"
  }
];

export const brandTableDataFormatter = (
  data?: Array<BrandListFieldsFragment>
) => [
  ...(data?.map((item: BrandListFieldsFragment) => {
    const { id, client, provider, name, throughput, code } = item;
    return {
      brandName: <Link to={`/brands/${id}/overview`}>{name}</Link>,
      clientName: client?.name,
      providerName: provider,
      throughput,
      code
    };
  }) || [])
];
