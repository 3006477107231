/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React from "react";

// third-party modules
import { badgeCell, BadgeTheme, CellDataType, Table } from "@onehq/anton";

// app modules
import { TeamListFieldsFragment } from "../../generated/graphql";
import {
  addSpacesBetweenWords,
  formatDate,
  formatNumber,
  teamStatusBadgeColor
} from "../../utils";
import { Link } from "@onehq/framework";
import { BaseTableProps } from "../../types";

export type TeamTableProps = BaseTableProps<TeamListFieldsFragment>;

export const TeamTableColumns = [
  {
    id: "name",
    header: "Name",
    accessorKey: "name"
  },
  {
    id: "teamStatusId",
    header: "Status",
    accessorKey: "teamStatus"
  },
  {
    id: "membersCount",
    header: "Members",
    accessorKey: "membersCount",
    dataType: "number" as CellDataType,
    enableSorting: false
  },
  {
    id: "createdAt",
    header: "Created At",
    accessorKey: "createdAt"
  },
  {
    id: "updatedAt",
    header: "Updated At",
    accessorKey: "updatedAt"
  }
];

export const teamTableDataFormatter = (
  data?: Array<TeamListFieldsFragment>
) => [
  ...(data?.map((item: TeamListFieldsFragment) => {
    const { id, name, teamStatus, membersCount, createdAt, updatedAt } = item;

    return {
      name: <Link to={`/teams/${id}/overview`}>{name}</Link>,
      teamStatus: badgeCell({
        value: addSpacesBetweenWords(teamStatus),
        color: teamStatusBadgeColor(teamStatus) as BadgeTheme
      }),
      membersCount: formatNumber(membersCount),
      createdAt: formatDate(createdAt),
      updatedAt: formatDate(updatedAt)
    };
  }) || [])
];

const TeamTable = ({ data = [], ...props }: TeamTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={TeamTableColumns}
      data={teamTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default TeamTable;
