import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Fieldset as DefaultFieldset,
  FileField,
  MessagePreview,
  Message as MsgFormat,
  useWatchContext,
  SelectField,
  RichTextField
} from "@onehq/anton";
import {
  useGetProjectTextMimeTypeOptionsQuery,
  MediaPosition,
  ProjectStatus,
  useGetListQuery,
  useGetProjectOptOutLanguageOptionsQuery,
  useGetShortenedLinksListQuery
} from "../../generated/graphql";
import { addSpacesBetweenWords } from "../../utils";
import { htmlToString } from "../../components/Project/utils";

export const baseUrl = process.env.REACT_APP_BACKEND_BASE_END_POINT || "";

const Root = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  & > * {
    width: calc(50% - 10px);
  }
`;

const Divider = styled.div`
  position: relative;

  & > * {
    width: 100%;
  }

  & > :not(:first-child) {
    margin-top: 12px;
  }
`;

const Fieldset = styled(DefaultFieldset)`
  & * {
    padding-bottom: 0;
  }

  & :last-child {
    margin-bottom: 0;
  }
`;

interface MessageProps {
  listId?: string;
  showLegends?: boolean;
}

const Message = ({ listId, showLegends }: MessageProps) => {
  const text = useWatchContext("message");
  const mediaUrl = useWatchContext("mediaUrl");
  const filename = useWatchContext("mediaFilename");
  const mediaPosition = useWatchContext("mediaPosition");
  const projectCampaigns = useWatchContext("projectCampaigns");
  const status = useWatchContext("projectStatus");

  const [mediaBefore, setMediaBefore] = useState(false);

  const { data: projectOptOutLanguageOptions } =
    useGetProjectOptOutLanguageOptionsQuery();
  const messageOptions = useMemo(
    () =>
      projectOptOutLanguageOptions?.options?.nodes?.map(node => node?.name) ||
      [],
    [projectOptOutLanguageOptions]
  );

  const { data: shortenedLinksOptions } = useGetShortenedLinksListQuery();
  const messageShortenedLinksOptions = useMemo(
    () =>
      shortenedLinksOptions?.shortenedLinks?.nodes?.map(
        node => node?.customDomain.domain
      ) || [],
    [shortenedLinksOptions]
  );

  const { data: list } = useGetListQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: listId || "" }
  });
  const headerOptions = useMemo(() => {
    const sheets = list?.list?.sheets;
    if (sheets) {
      return [
        ...new Set(
          sheets
            .flatMap(sheet => sheet.headerRow)
            .map(sheet => `{{${sheet}}}`) as Iterable<any>
        )
      ];
    }
    return [];
  }, [list]);

  useEffect(() => {
    setMediaBefore(mediaPosition === MediaPosition.Before);
  }, [mediaPosition]);

  const mediaPositionOptions =
    Object.keys(MediaPosition).map(item => ({
      label: addSpacesBetweenWords(item),
      value: item
    })) || [];

  const { data: textMimeTypes } = useGetProjectTextMimeTypeOptionsQuery();
  const textMimeTypeOptions = useMemo(() => {
    return textMimeTypes?.options.nodes?.map(item => item?.name) || [];
  }, [textMimeTypes]);

  const messages: MsgFormat[] = useMemo(() => {
    const content: MsgFormat[] = [
      { format: "text", text: "Hey 👋", type: "received" }
    ];
    if (text) {
      content.push({
        format: "text",
        // necessary because the rich text field library works with html underneath
        text: htmlToString(text as string) || "",
        type: "sent"
      });
    }
    if (mediaUrl) {
      const img: MsgFormat = {
        format: "attachment",
        mediaURL: baseUrl + mediaUrl,
        alt: filename || "attachment",
        type: "sent"
      };
      content.splice(mediaBefore ? 1 : text ? 2 : 1, 0, img);
    }
    return content;
  }, [text, mediaUrl, filename, mediaBefore]);

  const richTextCustomButtons: any = useMemo(() => {
    return [
      {
        title: "Shortened Links",
        items: messageShortenedLinksOptions,
        iconName: "link2",
        noSpacing: true
      },
      {
        title: "List Headers",
        items: headerOptions,
        name: "{{}}",
        noSpacing: true
      }
    ];
  }, [messageShortenedLinksOptions, headerOptions]);

  const Editor = useMemo(() => {
    return (
      <RichTextField
        name="message"
        label="Body"
        resize="vertical"
        required
        plainText
        placeholders={messageOptions}
        helpText="You can add an OPT-OUT option typing “/” here"
        placeholderTitle={"Add OPT-OUT here"}
        customButtons={richTextCustomButtons}
      />
    );
  }, [messageOptions, richTextCustomButtons]);

  return (
    <Root>
      <Divider>
        {showLegends && <Fieldset legend="Settings" />}
        {Editor}
        <FileField
          name="media"
          accept={textMimeTypeOptions}
          placeholder="No files detected"
          disabled={status === ProjectStatus.Complete}
        />
        {filename && (
          /* @ts-ignore */
          <SelectField
            label="Media Position"
            name="mediaPosition"
            options={mediaPositionOptions}
            noBlankOption
            disabled={
              status === ProjectStatus.Complete || projectCampaigns?.length
            }
          />
        )}
      </Divider>
      <div>
        {showLegends && <Fieldset legend="Preview" />}
        <MessagePreview content={messages} type="sms" />
      </div>
    </Root>
  );
};

export default Message;
