import React from "react";

import { Fieldset, SelectField, TextField } from "@onehq/anton";

import useTimeZones from "../../../../graphql/option/useTimeZones";

const OrganizationGeneralForm = () => {
  const timeZoneOptions = useTimeZones();

  return (
    <Fieldset>
      <TextField name="name" required span={8} />
      <TextField name="domain" required span={8} />
      {/* @ts-ignore */}
      <SelectField
        label="Time Zone"
        name="timeZoneId"
        options={timeZoneOptions}
        required
      />
    </Fieldset>
  );
};

export default OrganizationGeneralForm;
