import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorResponse } from "../../types";
import Userfront from "@userfront/core";
import { Authentication, Alert, ThemeProvider } from "@onehq/anton";
import Logo from "../../assets/imgs/authenticationLogo.png";
import { PageContainer, AlertWrapper } from "../../components/authStyles";

interface ResetPasswordFormAttributes {
  email: string;
}

const AuthRecoverPass = Authentication.PasswordRecovery;

const PasswordRecoveryPage = () => {
  const [error, setError] = useState<String>("");
  const navigateTo = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  Userfront.redirectIfLoggedIn({ redirect: "/" });

  const handleSubmit = (attr: ResetPasswordFormAttributes) => {
    Userfront.sendResetLink(attr.email)
      .then((res: Userfront.LinkResponse) => {
        console.log(res);
        // link to reset password
        let resUrl = res.result && res.result.url ? res.result.url : "";
        resUrl = resUrl.substring(resUrl.search("reset?"));
        navigateTo(`../${resUrl}`);
        // setSuccess(true);
      })
      .catch((error: ErrorResponse) => {
        console.log(error);
        setError(error.message ? error.message : "");
        setTimeout(function () {
          setError("");
        }, 3000);
      });
  };

  return (
    <ThemeProvider>
      <PageContainer authentication>
        <AuthRecoverPass
          // @ts-ignore
          handleSubmit={(props: ResetPasswordFormAttributes) =>
            handleSubmit(props)
          }
          logoImg={Logo}
          logoStyle={{ height: "100 px" }}
          headerTitle={"Recover password"}
          loginLink={"/login"}
          // showSuccessMessage={success}
        />
        <AlertWrapper>
          {error && <Alert title="error" message={error} variant="warning" />}
        </AlertWrapper>
      </PageContainer>
    </ThemeProvider>
  );
};

export default PasswordRecoveryPage;
