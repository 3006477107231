// react modules
import React, { useEffect, useRef, useState } from "react";

// third-party modules
import { ButtonGroup, FormBuilder } from "@onehq/anton";
import { ADD, REMOVE, useDispatchGrowlContext } from "@onehq/framework";
import { theme } from "@onehq/style";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import _ from "lodash";

// app modules
import TextFormFields from "./TextFormFields";
import { GenericFormProps } from "../../../types";
import {
  BaseTextFieldsFragment,
  ProjectFieldsFragment,
  TextInput,
  TextStatus,
  TextStatusGroup,
  TextType
} from "../../../generated/graphql";
import { formatPhone } from "../../../utils";
import FloatingForm from "../../../components/Form/FloatingForm";

export const textFormOmitValues = [
  "renderedMessage",
  "project",
  "texter",
  "fromPhone",
  "toPhone",
  "optOut",
  "__typename"
];

const { spacing8 } = theme.space;

export const StyledButtonGroup = styled(ButtonGroup)`
  padding-top: ${spacing8};
  display: contents;
`;

interface TextInputWithPhones extends TextInput {
  toPhone?: BaseTextFieldsFragment["toPhone"] | null;
  fromPhone?: BaseTextFieldsFragment["fromPhone"] | null;
}
export interface TextFormProps extends GenericFormProps<TextInputWithPhones> {
  resourceToFill?: {
    type: String;
    id: String;
  };
  additionalValues?: {
    // additional values not sent in form
    projectName?: string;
    texterName?: string;
    fromPhoneNumber?: string;
    toPhoneNumber?: string;
  };
}

interface TeamFormNavigationProps {
  projectId?: string;
  projectName?: string;
  textStatus?: TextStatusGroup;
  texterId?: string;
  texterName?: string;
  fromPhoneId?: string;
  isSendText?: boolean;
  body?: string;
  clientPhones?: ProjectFieldsFragment["client"]["phones"];
}

type PhoneToAdd = "toPhoneId" | "fromPhoneId";

const TextForm = (props: TextFormProps) => {
  // hooks
  const alert = useDispatchGrowlContext();
  const location = useLocation();

  // variables/constants
  const { additionalValues } = props;
  const values = props.values;
  const isEdit = !!(values && values.id);
  const refForm = useRef<HTMLFormElement>(null);
  const navigationProps = location.state as TeamFormNavigationProps;
  const projectId = navigationProps?.projectId;
  const projectName = navigationProps?.projectName;
  const textStatus = navigationProps?.textStatus;
  const texterId = navigationProps?.texterId;
  const texterName = navigationProps?.texterName;
  const fromPhoneId = navigationProps?.fromPhoneId;
  const isSendText = navigationProps?.isSendText;
  const body = navigationProps?.body;

  const initialValues = {
    ...values,
    projectId: {
      label: projectName || additionalValues?.projectName,
      value: projectId || values.projectId
    },
    texterId: {
      label: texterName || additionalValues?.texterName,
      value: texterId || values.texterId
    },
    toPhoneId: {
      label: formatPhone(
        (values.toPhone?.number as string) ||
          additionalValues?.toPhoneNumber ||
          ""
      ),
      value: values.toPhoneId
    },
    fromPhoneId: {
      label: formatPhone(
        (values.fromPhone?.number as string) ||
          additionalValues?.fromPhoneNumber ||
          ""
      ),
      value: fromPhoneId || values.fromPhoneId
    },
    textStatus: textStatus || values.textStatus,
    body: body || values.body,
    isSendText
  };

  // states
  const [showPhoneForm, setShowPhoneForm] = useState(false);
  const [saveAndSend, setSaveAndSend] = useState<boolean>(false);
  const [phoneToAdd, setPhoneToAdd] = useState<PhoneToAdd>("toPhoneId");
  const [submitForm, setSubmitForm] = useState(false);
  const [searchFieldInputs, setSearchFieldInputs] = useState({
    fromPhoneId: values.fromPhoneId,
    toPhoneId: values.toPhoneId
  });
  const [idForAlert] = useState(Math.floor(Math.random() * 10000 + 1));

  // use effects
  useEffect(() => {
    setSearchFieldInputs({
      fromPhoneId: values.fromPhoneId,
      toPhoneId: values.toPhoneId
    });
  }, [values.fromPhoneId, values.toPhoneId]);

  useEffect(() => {
    if (saveAndSend) onSubmit();
  }, [saveAndSend]);

  // functions
  const onSubmit = () => {
    if (refForm && refForm.current) {
      refForm.current.submit();
    }
  };

  const onAddNewPhone = (phone: PhoneToAdd) => {
    setPhoneToAdd(phone);
    setShowPhoneForm(true);
  };
  return (
    <>
      <FormBuilder
        ref={refForm}
        autosave={isEdit}
        values={{ ...initialValues }}
        type="dialogForm"
        // prettier-ignore
        // eslint-disable-next-line @typescript-eslint/require-await
        onSubmit={async ({ ...newAttr }) => {
          const attr = _.omit(newAttr, ['toPhone', 'fromPhone']) as any;
          attr.textStatus = TextStatus.Ready;
          attr.isSendText = saveAndSend;
          alert({
            type: ADD,
            payload: {
              id: idForAlert,
              title: isEdit ? "Saving text..." : "Creating text...",
              variant: "progress",
              indeterminate: true
            }
          });
          // editing text
          if (isEdit) {
            props.mutation({ variables: { id: attr.id, attributes: attr } }).then(() => {
              alert({
                type: REMOVE,
                payload: {
                  id: idForAlert,
                  title: "",
                  variant: "success"
                }
              });
            });
          } else { // creating text
            attr.textType = TextType.Manual;
            props.mutation({ variables: { attributes: attr } }).then(() => {
              alert({
                type: REMOVE,
                payload: {
                  id: idForAlert,
                  title: "",
                  variant: "success"
                }
              });
            });
          }
          setSaveAndSend(false);
        }}
      >
        <TextFormFields
          {...props}
          values={values}
          isEdit={isEdit}
          submitForm={submitForm}
          setSubmitForm={setSubmitForm}
          searchFieldInputs={searchFieldInputs}
          onSubmit={onSubmit}
          setSaveAndSend={setSaveAndSend}
          onAddNewPhone={onAddNewPhone}
        />
      </FormBuilder>

      <FloatingForm
        isEdit={isEdit}
        phoneToAdd={phoneToAdd}
        searchFieldInputs={searchFieldInputs}
        setSearchFieldInputs={setSearchFieldInputs}
        setSubmitForm={setSubmitForm}
        open={showPhoneForm}
        onClose={() => setShowPhoneForm(false)}
        variant="Phone"
      />
    </>
  );
};

export default TextForm;
