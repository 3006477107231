import React from "react";
import { Route } from "@onehq/framework";
import OptInPrivacy from "./Template1/OptInPrivacy";
import OptInTerms from "./Template1/OptInTerms";
import OptInPage from "./Template1/OptIn";

export interface OptInLegal {
  organizationName: string;
  organizationWeb: string;
  organizationUrl: string;
  organizationEmail: string;
  organizationPhone: string;
}

export interface OptInForm {
  clientName: string;
  logo: string;
}

export interface OptInContentItem {
  appleIcon: string;
  icon: string;
  form: OptInForm;
  legal: OptInLegal;
  urlOrigin: string;
}

export interface OptInContent {
  [key: string]: OptInContentItem;
}

const createFormsRoutes = () => {
  return new Route({
    name: `opt-in`,
    path: `:slug/opt-in`,
    component: () => <OptInPage />
  });
};

const createPrivacyRoutes = () => {
  return new Route({
    name: `privacy`,
    path: `:slug/opt-in/privacy`,
    component: () => <OptInPrivacy />
  });
};

const createTermsRoutes = () => {
  return new Route({
    name: `terms`,
    path: `:slug/opt-in/terms`,
    component: () => <OptInTerms />
  });
};

const optInPagesRoutes = createFormsRoutes();
const optInPrivacyRoutes = createPrivacyRoutes();
const optInTermsRoutes = createTermsRoutes();

export { optInPagesRoutes, optInPrivacyRoutes, optInTermsRoutes };
