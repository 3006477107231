import React from "react";
import { CellContext } from "@tanstack/react-table";
import { NoPaddingButton } from "./index.styles";

// renders a inline button without padding as a custom cell for the data grid
// the action property expects a () => void and triggers on button click
export const buttonActionCell =
  (label: string) =>
  ({ getValue }: CellContext<any, () => void>) => {
    const onClick = getValue();
    return onClick ? (
      <NoPaddingButton variant="inline" onClick={onClick}>
        {label}
      </NoPaddingButton>
    ) : (
      <></>
    );
  };
