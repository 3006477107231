import React from "react";
import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";
import ListPage from "../../components/pages/ListPage";
import { TEXTS_PATH } from "../../constants";
import TextMenuAnchor from "./TextAnchor";
import DataOverview from "../../components/pages/DataOverview";
import TextNew from "./TextNew";
import TextEdit from "./TextEdit";

const TextsRoute = new ResourceRoute({
  name: "Text",
  path: TEXTS_PATH,
  AnchorComponent: TextMenuAnchor,
  IndexComponent: () => <ListPage variant="Texts" />,
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "Text",
      IndexComponent: () => <DataOverview />
    })
  ],
  NewComponent: TextNew,
  EditComponent: TextEdit
});

export { TextsRoute };
