import styled from "styled-components";
import { theme } from "@onehq/style";

export const PageContainer = styled.div<{ authentication?: boolean }>`
  height: 100vh;
  width: 100%;
  background-color: ${theme.color.neutral95};
  overflow-y: auto;
  display: ${props => (props.authentication ? "grid" : "flex")};
  justify-content: center;
  align-items: center;
  color: ${theme.color.neutral40};
  margin-left: auto;
  margin-right: auto;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 2rem;
  text-align: center;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 0.8rem;
  width: 100%;
  padding: 0px 8px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 1.2rem 0rem;
  justify-content: space-between;
`;

export const CursorPointer = styled.div`
  cursor: pointer;
`;

export const AlertWrapper = styled.div`
  width: 350px;
  height: 10px;
  margin-top: 2rem;
  justify-self: center;
  text-align: -webkit-center;
`;
