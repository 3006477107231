import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PageWrapper,
  Header,
  ImageWrapper,
  ResponsiveImage,
  SubTitleHeader,
  Text,
  TextContainer,
  StyledH2,
  FooterText,
  Footer,
  Banner
} from "./OptInStyles";
import RGO_LOGO from "../../../assets/opts/logos/readygop_logo.png";
import { useGetClientBySlugQuery } from "../../../generated/graphql";
import { defautColor } from "./OptIn";

const OptInPrivacy = () => {
  const navigateTo = useNavigate();
  const { slug } = useParams();
  const { data } = useGetClientBySlugQuery({
    variables: { slug: slug || "" },
    onCompleted: data => {
      const client = data?.clients?.nodes?.[0];
      if (!client) {
        navigateTo("/");
      }
    }
  });

  const client = data?.clients?.nodes?.[0];
  const mediaUrl = client?.mediaUrl
    ? (process.env.REACT_APP_BACKEND_BASE_END_POINT || "") + client?.mediaUrl
    : "";
  const clientName = client?.name;
  const organizationEmail = client?.email;
  const clientPhone = client?.phone;
  const color = client?.color || defautColor;
  const slogan = client?.slogan;

  useEffect(() => {
    const tabTitle = (client?.name || "") + " - Privacy";
    let link: HTMLLinkElement | null =
      document.querySelector('link[rel="icon"]');
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = mediaUrl || RGO_LOGO;
    document.title = tabTitle;
  }, [client?.name, mediaUrl]);

  return (
    <PageWrapper>
      <Header color={color}>Privacy Policy</Header>
      <Banner>
        <ImageWrapper>
          <ResponsiveImage src={mediaUrl || ""} />
        </ImageWrapper>
        <SubTitleHeader color={color}>{slogan}</SubTitleHeader>
      </Banner>
      <TextContainer>
        <Text>
          This Privacy Policy describes how {clientName} (&quot;we,&quot;
          &quot;our,&quot; or &quot;us&quot;) collects, uses, and discloses
          information that we obtain about visitors to our website and users of
          our services.
        </Text>
      </TextContainer>
      <TextContainer>
        <StyledH2 color={color}>Information We Collect</StyledH2>
        <Text>
          We may collect personal information about you, such as your name,
          email address, postal address, phone number, and other similar contact
          data when you provide it to us voluntarily. We may also collect
          information about your use of our website and services through cookies
          and similar tracking technologies.
        </Text>
        <StyledH2 color={color}>How We Use Your Information</StyledH2>
        <Text>
          We may use the information we collect about you for various purposes,
          including to:
        </Text>
        <ul>
          <li>Provide, operate, and maintain our website and services</li>
          <li>
            Communicate with you about our products, services, and promotions
          </li>
          <li>Personalize and improve our website and services</li>
          <li>Respond to your inquiries and provide customer support</li>
          <li>Comply with legal and regulatory requirements</li>
        </ul>
        <StyledH2 color={color}>Sharing of Your Information</StyledH2>
        <Text>
          No mobile information will be shared with third parties or affiliates
          for marketing or promotional purposes. All other categories of data
          exclude text messaging originator opt-in data and consent. This
          information will not be shared with any third parties.
        </Text>
        <StyledH2 color={color}>Your Choices</StyledH2>
        <Text>
          You may choose not to provide certain personal information, but this
          may limit your ability to use certain features of our website or
          services. You can also opt-out of receiving promotional communications
          from us by following the instructions provided in those
          communications.
        </Text>
        <StyledH2 color={color}>Security</StyledH2>
        <Text>
          We take reasonable measures to protect the information we collect
          about you from unauthorized access, use, and disclosure.
        </Text>
        <StyledH2 color={color}>Changes to This Privacy Policy</StyledH2>
        <Text>
          We may update this Privacy Policy from time to time. The &quot;Last
          Updated&quot; date at the top of this page indicates when this Privacy
          Policy was last revised. We encourage you to review this Privacy
          Policy periodically.
        </Text>
        <StyledH2 color={color}>Contact Us</StyledH2>
        <Text>
          If you have any questions about this Privacy Policy, please contact us
          at {clientPhone} or {organizationEmail}.
        </Text>
      </TextContainer>
      <Footer color={color}>
        <FooterText>© 2024 All rights reserved.</FooterText>
        <FooterText>{client?.email}</FooterText>
      </Footer>
    </PageWrapper>
  );
};

export default OptInPrivacy;
