import React from "react";
import { FormSection } from "@onehq/framework";

import SendingScheduleForm from "./SendingScheduleForm";
import ProjectTexterForm from "./ProjectTexterForm";
import ProjectFormFields from "./ProjectFormFields";
import ProjectCampaignsForm from "./ProjectCampaignsForm";
import ListFiltersForm from "../../../components/ListFilter/ListFiltersForm";

export { projectFormOmitValues } from "./ProjectFormFields";

const sections = [
  new FormSection("Basic Info", ProjectFormFields),
  new FormSection("Sending Info", ProjectCampaignsForm),
  new FormSection("Schedule", SendingScheduleForm),
  new FormSection("Texters", ProjectTexterForm),
  new FormSection("Filters", () => <ListFiltersForm resource="Project" />)
];

export default sections;
