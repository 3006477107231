// react modules
import React, { useMemo } from "react";

// third-party modules
import { DateField, Fieldset, SelectField, TextField } from "@onehq/anton";

// app modules
import { useGetCustomDomainsListQuery } from "../../../generated/graphql";

export const ShortenedLinkGeneralFormOmitValues = [
  "__typename",
  "customDomain",
  "organizationId"
];

const ShortenedLinkGeneralForm = () => {
  const { data: domainsData } = useGetCustomDomainsListQuery({
    fetchPolicy: "cache-and-network"
  });

  const domainsOptions = useMemo(() => {
    const domains = domainsData?.customDomains.nodes || [];
    return [
      {
        label: "Registered",
        options: domains
          .filter(domain => {
            return domain?.organizationId !== null;
          })
          .map(domain => ({
            label: domain?.domain,
            value: domain?.id
          }))
      },
      {
        label: "Unregistered",
        options: domains
          .filter(domain => {
            return domain?.organizationId === null;
          })
          .map(domain => ({
            label: domain?.domain,
            value: domain?.id
          }))
      }
    ];
  }, [domainsData]);

  return (
    <>
      <Fieldset>
        <TextField
          label="Original Link"
          name="originalLink"
          required
          span={6}
        />
        {/* @ts-ignore */}
        <SelectField
          label="Domain"
          name="customDomainId"
          options={domainsOptions}
          required
          span={6}
        />
        <TextField label="Short Code" name="shortCode" span={3} />
        <DateField label="Start At Date" name="startAt" span={4} />
        <DateField label="End At Date" name="endAt" span={4} />
      </Fieldset>
    </>
  );
};

export default ShortenedLinkGeneralForm;
