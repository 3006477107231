// react modules
import React from "react";

// third-party modules
import { Fieldset, NestedForm, TextField } from "@onehq/anton";

const DomainFields = () => {
  return (
    <Fieldset>
      <TextField label="Domain" name="domain" collapseLabel />
    </Fieldset>
  );
};

const CustomDomainsForm = () => {
  return (
    <Fieldset>
      <NestedForm
        name="customDomainsAttributes"
        component={DomainFields}
        condensed
        removable
        removeKey="X"
      />
    </Fieldset>
  );
};

export default CustomDomainsForm;
