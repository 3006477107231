import { useEffect } from "react";

export const UseDocumentHead = ({ title, favicon, appleIcon }) => {
  useEffect(() => {
    document.title = title;
    // Update favicon for browsers including Safari
    const changeFavicon = (linkType: string) => {
      const link = document.querySelector(`link[rel~='${linkType}']`);

      if (link instanceof HTMLLinkElement) {
        document.head.removeChild(link); // Remove the existing link to force refresh
      }
      const newLink = document.createElement("link");
      newLink.rel = linkType;
      newLink.href = favicon;
      newLink.type = "image/png";
      document.head.appendChild(newLink);
    };

    changeFavicon("icon");
    changeFavicon("shortcut icon"); // For some browsers that require this

    // Update Apple Touch Icon for Safari on iOS
    const changeAppleIcon = () => {
      document
        .querySelectorAll("link[rel='apple-touch-icon']")
        .forEach(link => {
          document.head.removeChild(link);
        });
      const newLink = document.createElement("link");
      newLink.rel = "apple-touch-icon";
      // newLink.sizes = sizes;
      newLink.href = appleIcon;
      document.head.appendChild(newLink);
    };
    // Define sizes as needed or use multiple calls for different sizes
    changeAppleIcon(); // Common size for Apple devices
  }, [title, favicon, appleIcon]); // This effect will re-run when any of these values change
};
