import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ADD,
  BaseResource,
  Link,
  ResourceRoute,
  useDispatchGrowlContext
} from "@onehq/framework";
import { useLazyQuery } from "@apollo/client";
import {
  ActionItemProps,
  Card,
  ConfirmationModal,
  Badge,
  IconNames
} from "@onehq/anton";
import { useGoogleLogin } from "@react-oauth/google";

import {
  useDestroyListMutation,
  DestroyListMutation,
  LoadListDocument,
  useUpdateUserRefreshTokenMutation,
  useCustomCurrentUserQuery,
  useGetListQuery,
  ToggleFavoriteMutation,
  useToggleFavoriteMutation,
  useIsFavoriteQuery,
  ListStatus,
  BaseListFieldsFragment
} from "../../../generated/graphql";
import listStatusColor from "../../../utils/listStatusColor";
import { formatDate } from "../../../utils/helperFunctions";
import { LISTS_PATH } from "../../../constants";
import sections from "../sections";

interface ListAnchorProps {
  id: string;
  route: ResourceRoute;
}
const ListAnchor = ({ id }: ListAnchorProps) => {
  // alert
  const alert = useDispatchGrowlContext();

  // navigation props
  const navigateTo = useNavigate();
  const location = useLocation();
  const resource = location.state as any;

  // fetch list data
  const listQuery = useGetListQuery({
    fetchPolicy: "cache-and-network",
    context: {
      headers: {
        isView: "true",
        resource: "List"
      }
    },
    variables: { id }
  });
  const values = { ...listQuery?.data?.list } as BaseListFieldsFragment;
  let timerId: string | number | NodeJS.Timeout | undefined;

  // fetch current user data
  const { data: currentUser, ...CustomCurrentUserQuery } =
    useCustomCurrentUserQuery({
      fetchPolicy: "cache-and-network"
    });

  const { data: isFavoriteData } = useIsFavoriteQuery({
    fetchPolicy: "no-cache",
    variables: { listId: id }
  });

  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  let timerActive = false;

  useEffect(() => {
    setIsFavorite(isFavoriteData?.isFavorite || false);
  }, [isFavoriteData]);

  const [toggleFavorite] = useToggleFavoriteMutation({
    onCompleted: (response: ToggleFavoriteMutation) => {
      setIsFavorite(response.toggleFavorite?.isFavorite || false);
    }
  });

  // modal
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleRefetch = () => {
    listQuery.refetch().then(
      response => {
        const listStatus = response.data.list?.listStatusName;
        if (!timerActive) {
          timerActive = true;
          timerId = setInterval(handleRefetch, 5000);
        } else if (listStatus !== ListStatus.Loading && timerActive) {
          clearInterval(timerId);
          timerActive = false;
        }
      },
      error => console.log(error)
    );
    void CustomCurrentUserQuery.refetch();
  };

  useEffect(() => {
    if (resource?.loadList) {
      callLoadList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [destroyListMutation] = useDestroyListMutation({
    onCompleted: (response: DestroyListMutation) => {
      const errors = response?.destroyList?.errors as
        | { [key: string]: any }
        | undefined;
      if (!errors || (errors && Object.keys(errors).length === 0)) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: response?.destroyList?.message,
            variant: "success"
          }
        });
        navigateTo(`/${LISTS_PATH}`);
      }
    },
    onError: err => {
      alert({
        type: ADD,
        payload: {
          title: "Failed",
          message: err.message,
          variant: "error"
        }
      });
      navigateTo(`/${LISTS_PATH}`);
    }
  });

  const callLoadList = () => {
    CustomCurrentUserQuery.refetch().then(
      userdata => {
        if (userdata.data.currentUser.hasRefreshToken) {
          void loadListQuery();
        } else {
          alert({
            type: ADD,
            payload: {
              title: "Missing Authorization",
              message: "Login to your Google Account to continue",
              variant: "error"
            }
          });
          login();
        }
      },
      err => {
        console.log("error refetching user data: ", err);
      }
    );
  };

  const [loadList] = useLazyQuery(LoadListDocument);
  const loadListQuery = () => {
    const idForAlert = Math.floor(Math.random() * 10000 + 1);
    void loadList({
      fetchPolicy: "no-cache",
      variables: { listId: id, userId: currentUser?.currentUser.id },
      onCompleted: () => {
        alert({
          type: ADD,
          payload: {
            id: idForAlert,
            title: "Loading Lists",
            variant: "success"
          }
        });
        if (handleRefetch) {
          handleRefetch();
        }
      },
      onError: err => {
        alert({
          type: ADD,
          payload: {
            title: "Failed",
            message: err.message,
            variant: "error"
          }
        });
        console.error(err); // the error if that is the case
      }
    });
  };

  const [updateUserRefreshToken] = useUpdateUserRefreshTokenMutation();
  const updateUserRefreshTokenMutation = (authcode: string) =>
    updateUserRefreshToken({
      fetchPolicy: "no-cache",
      variables: {
        userId: currentUser?.currentUser.id || "",
        authToken: authcode
      },
      // TODO: remove
      // @ts-ignore
      onCompleted: () => {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: "Google Authorization saved",
            variant: "success"
          }
        });
      },
      onError: err => {
        alert({
          type: ADD,
          payload: {
            title: "Failed",
            message: err.message,
            variant: "error"
          }
        });
        console.error(err); // the error if that is the case
      }
    });

  // https://www.npmjs.com/package/@react-oauth/google
  const login = useGoogleLogin({
    onSuccess: googleauth => {
      void updateUserRefreshTokenMutation(googleauth.code);
      navigateTo(`/${LISTS_PATH}`);
    },
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/drive",
    onError: () => {
      alert({
        type: ADD,
        payload: {
          title: "Missing Authorization",
          message: "Google Authorization failed",
          variant: "error"
        }
      });
    }
  });

  const deleteList = async () => {
    await destroyListMutation({ variables: { id } });
  };

  const kebabMenuItems = [
    {
      name: "Delete",
      icon: "trash2",
      color: "wine50",
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick: () => handleShow()
    },
    {
      name: "Load List",
      icon: "loader",
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick: () => callLoadList()
    }
  ] as ActionItemProps[];

  const GoogleAccountBadge = () => (
    <Card.Badges>
      <Badge color={currentUser?.currentUser.hasRefreshToken ? "green" : "red"}>
        {currentUser?.currentUser.hasRefreshToken
          ? "Signed In with Google"
          : "Sign In with Google"}
      </Badge>
    </Card.Badges>
  );

  const infoCard = (
    <Card.BasicInfo
      data={[
        {
          label: "Spreadsheet",
          value: (
            <Link
              external
              target={"_blank"}
              href={`https://docs.google.com/spreadsheets/d/${values.googleSheetKey}`}
            >
              Go to spreadsheet
            </Link>
          )
        },
        {
          label: "Drive Email",
          value: currentUser?.currentUser.googleEmailAddress
        },
        { label: "Last Loaded At", value: values.lastLoadedAt },
        {
          label: "Created At",
          value: formatDate(values.createdAt)
        },
        {
          label: "Updated At",
          value: formatDate(values.updatedAt)
        }
      ]}
    />
  );

  const badgesCard = (
    <Card.Badges>
      <GoogleAccountBadge />
    </Card.Badges>
  );

  const menuAnchorProps = {
    editLinks: sections,
    cards: [infoCard, badgesCard],
    kebabMenuItems,
    name: values.name,
    subItem: "List",
    skeleton: listQuery.loading,
    onEditPath: "basic-info",
    onViewPath: "overview",
    type: values.listStatusName,
    statusColor: listStatusColor(values.listStatus),
    onFavoriteToggle: () => toggleFavorite({ variables: { listId: id } }),
    favorite: isFavorite,
    defaultIcon: "list" as IconNames
  };

  return (
    <>
      <BaseResource.Anchor {...menuAnchorProps} />
      <ConfirmationModal
        message="Are you sure you want to delete this list?"
        title="Delete List?"
        confirmLabel="Delete"
        confirmIcon="trash2"
        open={showModal}
        handleClose={handleClose}
        onConfirm={deleteList}
      />
    </>
  );
};

export default ListAnchor;
