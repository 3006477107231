import { DotColor } from "@onehq/anton";
import { ClientStatus } from "../generated/graphql";

export function clientStatus(status: ClientStatus): DotColor {
  switch (status) {
    case ClientStatus.Active:
      return "pea60" as DotColor;
    case ClientStatus.Inactive:
      return "iris50" as DotColor;
    case ClientStatus.Prospect:
      return "gold60" as DotColor;
    default:
      return "wine50" as DotColor;
  }
}

export function clientStatusBadgeColor(status: ClientStatus): DotColor {
  switch (status) {
    case ClientStatus.Active:
      return "green" as DotColor;
    case ClientStatus.Inactive:
      return "purple" as DotColor;
    case ClientStatus.Prospect:
      return "yellow" as DotColor;
    default:
      return "red" as DotColor;
  }
}
