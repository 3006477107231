// maybe do this in framework form
import React, { ReactNode, useState } from "react";
import { ConfirmationModal } from "@onehq/anton";
import { isFunction } from "@onehq/helpers";
import { useNavigate } from "react-router-dom";

interface DiscardFormModalProps {
  children?: ReactNode | ((onClick: () => void) => ReactNode);
  onDiscard?: () => void;
  resource?: string;
}

export const DiscardFormModal = ({
  children,
  onDiscard,
  resource
}: DiscardFormModalProps) => {
  const navigateTo = useNavigate();
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDiscard = () => {
    setOpen(false);
    onDiscard ? onDiscard() : navigateTo(-1);
  };

  return (
    <>
      {isFunction(children) ? (
        children(onClick)
      ) : (
        <div onClick={onClick}>{children}</div>
      )}

      <ConfirmationModal
        message="All the filled data will be lost"
        title={`Discard New ${resource || "Resource"}?`}
        confirmLabel="Discard"
        cancelLabel="Cancel"
        open={open}
        handleClose={handleClose}
        onConfirm={handleDiscard}
      />
    </>
  );
};
