/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

// third-party modules
import { Work as WorkV3, WorkProps as WorkV3Props } from "@onehq/anton";

// app modules
import { QueryParams, WorkData, WorkProps } from "./types";
import { hasPreviousItemInPage, sortByName } from "./utils";
import {
  CLIENTS_PATH,
  LISTS_PATH,
  PHONES_PATH,
  PROJECTS_PATH,
  TEAMS_PATH,
  TEXTS_PATH,
  USERS_PATH,
  WORK_PATH
} from "../../constants";

type Method = WorkV3Props["communicationMethod"];

const Work = ({
  workList,
  currentWork,
  message,
  onBack,
  onNext,
  getWorkList,
  sendTexts,
  setWorkList,
  setCurrentWork,
  setVariant,
  setMessage,
  imageUrl,
  isMobile
}: WorkProps) => {
  const [communication, setCommunication] = useState<Method>();

  const location = useLocation();

  const onWorkClick = (work: WorkData) => {
    setCurrentWork(work);
  };

  const onWorkClose = (work: WorkData) => {
    const workIdx = workList.findIndex(w => w.id === work.id);
    workList.splice(workIdx, 1);
    setWorkList([...workList]);
  };

  // every time we check the list of work, we update the work list
  const onWorkListClick = () => {
    const queryParams: QueryParams = { fetchPolicy: "no-cache" };
    getWorkList(queryParams)
      .then(response => {
        const updWork: WorkData[] = response?.data?.workList?.nodes || [];

        // if we already have work loaded, we keep them
        // that is because we may have some data that aren't in the new list
        // e.g. the current item in the current page of the work
        const newWorkList = updWork.map(w => {
          const existingWork = workList.find(
            existingWork => existingWork.id === w.id
          );
          return existingWork || w;
        });

        sortByName(newWorkList);
        setWorkList(newWorkList);

        // if the currentWork is gone, just assign the first one in the list
        const current = newWorkList.find(w => w.id === currentWork?.id);
        if (!current && newWorkList.length > 0) {
          setCurrentWork({ ...newWorkList[0] });
        } else if (newWorkList.length === 0) {
          setCurrentWork(undefined);
        }
      })
      .catch((err: any) => console.error(err));
  };

  const onTextChange = (value: string) => {
    setMessage(value);
  };

  const onSend = () => {
    const currItemIdx = currentWork?.currItemIdx;
    const currentItem = currentWork?.currentItem;
    if (!currentWork || !currentItem || currItemIdx === undefined) return;

    void sendTexts({
      fetchPolicy: "no-cache",
      variables: {
        projectId: currentWork.projectId,
        textIds: [currentItem.text.id],
        message
      }
    });
    onNext();
  };

  // change the communication type.
  // you can't switch between communication methods directly,
  // you have to hide the current method and then choose another one.
  const toggleCommunication = (type: Method) => {
    setCommunication(communication ? undefined : type);
    // reset message when changing communication methods
    // messaging uses plain text and call & mail uses html
    setMessage(message || "");
  };

  if (!currentWork) return <></>;
  // if pathname is none of these, dont show work
  if (!isValidPath(location.pathname)) return <></>;
  return (
    <WorkV3
      workList={workList}
      currentWork={currentWork}
      communicationMethod={communication}
      communicationText={message}
      onTextChange={onTextChange}
      placeholders={[]}
      onSave={onSend}
      onWorkClick={onWorkClick}
      onWorkClose={onWorkClose}
      onWorkListClick={onWorkListClick}
      onBack={onBack}
      onNext={onNext}
      hasPrevious={hasPreviousItemInPage(currentWork.currItemIdx)}
      hasNext
      onCallToggle={() => toggleCommunication("call")}
      onMuteToggle={() => console.log("onMuteToggle")}
      onSilenceToggle={() => console.log("onSilenceToggle")}
      onMessageToggle={() => toggleCommunication("message")}
      onMailToggle={() => toggleCommunication("email")}
      onSkip={onNext}
      onSpeedToggle={() => setVariant("speed")}
      disabledCall
      disabledMail
      imageUrl={imageUrl}
      mobile={isMobile}
    />
  );
};

const isValidPath = (currentPath: string) => {
  return [
    LISTS_PATH,
    TEXTS_PATH,
    PROJECTS_PATH,
    USERS_PATH,
    CLIENTS_PATH,
    PHONES_PATH,
    TEAMS_PATH,
    WORK_PATH
  ].find(path => currentPath.includes(path));
};

export default Work;
