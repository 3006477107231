import styled from "styled-components";
import { theme } from "@onehq/style";
import { ButtonGroup } from "@onehq/anton";

const StyledButtonGroup = styled(ButtonGroup)`
  padding-top: ${theme.space.spacing8};
  gap: 2rem;
`;

export default StyledButtonGroup;
