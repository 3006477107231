/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from "react";
import WorkComponent from "./Work";
import SpeedWork from "./SpeedWork";
import {
  QueryParams,
  WhichPage,
  WorkData,
  WorkItemType,
  WorkVariant
} from "./types";
import {
  GetWorkItemsDocument,
  GetWorkListDocument,
  GetWorkProgressDocument,
  SendTextDocument,
  useCustomCurrentUserQuery,
  WorkItemEdge
} from "../../generated/graphql";
import {
  formatItems,
  formatWorkList,
  hasNextItemInPage,
  hasPreviousItemInPage,
  sortByName
} from "./utils";
import { useLazyQuery } from "@apollo/client";
import { Progress } from "@onehq/anton";
import { useNavigate } from "react-router-dom";
import { WindowSizeContext } from "../../routes";
import { baseUrl } from "../../pages/stepper/message";

const Work = () => {
  const [workList, setWorkList] = useState<WorkData[]>([]);
  const [currentWork, setCurrentWork] = useState<WorkData>();
  const [variant, setVariant] = useState<WorkVariant>("default");
  const [message, setMessage] = useState("");

  const [getWorkList] = useLazyQuery(GetWorkListDocument);
  const [getWorkItems] = useLazyQuery(GetWorkItemsDocument);
  const [getProgress] = useLazyQuery(GetWorkProgressDocument);
  const [sendTexts] = useLazyQuery(SendTextDocument);

  const windowObserver = useContext(WindowSizeContext);
  const navigateTo = useNavigate();

  const { data: currentUser } = useCustomCurrentUserQuery();
  const user = useMemo(() => currentUser?.currentUser, [currentUser]);

  // currentUser work list as the work data list
  const workDataList = useMemo(() => {
    return formatWorkList(user?.work || []);
  }, [user]);

  useEffect(() => {
    // this check is here in order to only load data the first time we load the user's work
    // this way we can ignore all the updates in the work.currentItemId (triggers refetch of data)
    if (!currentWork && workDataList.length > 0) {
      sortByName(workDataList);
      setWorkList(workDataList);
      setCurrentWork({ ...workDataList[0] });
    }
  }, [workDataList]);

  useEffect(() => {
    if (currentWork && !currentWork.paginationInfo.loading) {
      // when loading the work, it's list of pages is empty at the start
      if (currentWork.status === "ready" && loadItemsPage) {
        loadItemsPage(currentWork, "current");
      }
      setMessage(currentWork.generalMessage || "");

      const currWorkIndex = workList.findIndex(w => w.id === currentWork.id);
      workList[currWorkIndex] = currentWork;
      setWorkList([...workList]);
    }
  }, [currentWork]);

  const onNext = () => {
    const currItemIdx = currentWork?.currItemIdx;
    if (currentWork && currItemIdx !== undefined) {
      if (hasNextItemInPage(currentWork, currItemIdx)) {
        setCurrentItemInPage(currItemIdx + 1);
      } else if (loadItemsPage) {
        loadItemsPage(currentWork, "after");
      }
    }
  };

  const onBack = () => {
    if (currentWork && currentWork.currItemIdx !== undefined) {
      if (hasPreviousItemInPage(currentWork.currItemIdx)) {
        setCurrentItemInPage(currentWork.currItemIdx - 1);
      } else if (loadItemsPage) {
        loadItemsPage(currentWork, "before");
      }
    }
  };

  const setCurrentItemInPage = (newCurrentItemIndex: number) => {
    if (currentWork) {
      currentWork.currItemIdx = newCurrentItemIndex;
      currentWork.currentItem = currentWork.items[newCurrentItemIndex];
      if (variant === "speed") currentWork.progress.processed++;
      setCurrentWork({ ...currentWork });

      if (variant === "default") {
        const textId = currentWork.currentItem.text.id;
        navigateTo(`/texts/${textId}/overview`, { replace: true });
      }
    }
  };

  // "before" and "after": they are called when the user changes the current item (and page)
  // "current": it's called only when loading a project. dont change anything, just load
  const loadItemsPage = (work: WorkData, whichPage: WhichPage) => {
    const queryParams: QueryParams = {
      fetchPolicy: "no-cache",
      variables: { workId: work.id }
    };

    setCurrentWork({
      ...work,
      paginationInfo: { ...work.paginationInfo, loading: true }
    });

    getWorkItems(queryParams)
      .then(response => {
        const { cursors, pageInfo, edges, totalCount } =
          response.data.workItems;

        const workItems = edges?.map(
          (e: WorkItemEdge) => e.node
        ) as WorkItemType[];
        work.items = formatItems(workItems || [], work.generalMessage);

        // update/refresh the pagination data
        work.paginationInfo = {
          loading: false,
          pages: cursors || [],
          page: pageInfo.page,
          pageIndex: pageInfo.pageIndex,
          hasNext: pageInfo.hasNextPage,
          hasPrevious: pageInfo.hasPreviousPage,
          total: totalCount
        };
        work.status = `load-${whichPage === "before" ? "previous" : "next"}`;

        // this is multiclick specific.
        // if we request a next page but there are no more items,
        // the response keep saying that there is still a previous page.
        // we don't want to allow going anywhere when there are no more pages.
        const noMorePages = workItems.length === 0;

        if (noMorePages) {
          setCurrentWork(old => {
            return {
              ...old,
              ...work,
              currItemIdx: undefined,
              currentItem: undefined,
              progress: {
                processed: work.progress.total,
                total: work.progress.total,
                errors: work.progress.errors
              }
            };
          });
        } else if (whichPage === "current") {
          // "current" is used only when loading the initial data
          const project = workItems[0].text?.project;
          const { id = "", mediaUrl, message } = project || {};
          if (!work.generalMessage) work.generalMessage = message;
          setCurrentWork(old => ({
            ...old,
            ...work,
            currItemIdx: 0,
            currentItem: work.items[0],
            projectId: id,
            mediaUrl: mediaUrl || ""
          }));
        } else {
          const newIdex =
            work.status === "load-next" ? 0 : work.items.length - 1;
          work.currItemIdx = newIdex;
          work.currentItem = work.items[newIdex];
          setCurrentWork(old => ({ ...old, ...work }));
        }
        updateWorkProgress();
      })
      .catch((err: any) => console.error(err));
  };

  const updateWorkProgress = () => {
    if (!currentWork) return;
    const queryParams: QueryParams = {
      fetchPolicy: "no-cache",
      variables: {
        workId: currentWork.id
      }
    };

    getProgress(queryParams)
      .then(response => {
        const progress = response.data.getWorkProgress.progress as Progress;
        setCurrentWork(work => (work ? { ...work, progress } : undefined));
      })
      .catch((err: any) => console.error(err));
  };

  const Child = useMemo(() => {
    if (variant === "default") return WorkComponent;
    else return SpeedWork;
  }, [variant]);

  const imageUrl = currentWork?.mediaUrl && baseUrl + currentWork.mediaUrl;

  return (
    <Child
      workList={workList}
      currentWork={currentWork}
      message={message}
      imageUrl={imageUrl}
      isMobile={windowObserver.isMobile}
      onBack={onBack}
      onNext={onNext}
      getWorkList={getWorkList}
      sendTexts={sendTexts}
      setWorkList={setWorkList}
      setCurrentWork={setCurrentWork}
      setVariant={setVariant}
      setMessage={setMessage}
    />
  );
};

export default Work;
