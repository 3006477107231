/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useMemo } from "react";

// third-party modules
import {
  DateField,
  Fieldset,
  SearchField,
  TimeField,
  useWatchContext
} from "@onehq/anton";

// app modules
import useTimeZones from "../../../graphql/option/useTimeZones";
import { ProjectStatus } from "../../../generated/graphql";

export interface SendingFromFormProps {
  hideLegend?: boolean;
}

const SendingScheduleForm = ({ hideLegend = true }: SendingFromFormProps) => {
  const timeZoneData = useWatchContext("timeZoneData");
  const timeZones = useTimeZones();
  const timeZoneOptions = useMemo(() => {
    return timeZones.map(timeZone => {
      return {
        label: timeZone?.label,
        value: {
          id: timeZone?.id || "",
          pgTimeZoneName: timeZone?.pgTimezoneName || ""
        }
      };
    });
  }, [timeZones]);

  const projectStatus = useWatchContext("projectStatus");
  const isComplete = useMemo(() => {
    return projectStatus === ProjectStatus.Complete;
  }, [projectStatus]);

  return (
    <Fieldset legend={!hideLegend && "Sending Schedule"}>
      <Fieldset>
        {/* @ts-ignore */}
        <SearchField
          label="Time Zone"
          name="timeZoneData"
          defaultOptions={timeZoneOptions}
          span={9}
          disabled={isComplete}
        />
        <DateField
          span={5}
          label="Send Date"
          name="startAtDate"
          disabled={isComplete || !timeZoneData}
        />
        <TimeField
          span={5}
          label="Start at Time"
          name="startAtTime"
          disabled={isComplete || !timeZoneData}
        />
        <TimeField
          span={5}
          label="End at Time"
          name="endAtTime"
          disabled={isComplete || !timeZoneData}
        />
      </Fieldset>
    </Fieldset>
  );
};

export default SendingScheduleForm;
