import React, { useState } from "react";
import { Authentication, Alert, ThemeProvider } from "@onehq/anton";
import { ErrorResponse } from "../../types";
import Userfront from "@userfront/core";
import Logo from "../../assets/imgs/authenticationLogo.png";
import { PageContainer, AlertWrapper } from "../../components/authStyles";

interface UpdatePasswordFormAttributes {
  password: string;
  passwordCheck: string;
}

const AuthUpdatePass = Authentication.UpdatePassword;

const UpdatePasswordPage = () => {
  const [error, setError] = useState<String>("");
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit = (attr: UpdatePasswordFormAttributes) => {
    if (attr.password !== attr.passwordCheck) {
      setError("Password must match");
      setTimeout(function () {
        setError("");
      }, 3000);
    } else {
      Userfront.resetPassword({
        password: attr.password
      })
        .then(res => {
          console.log(res);
          setSuccess(true);
        })
        .catch((error: ErrorResponse) => {
          setError(error.message ? error.message : "");
          setTimeout(function () {
            setError("");
          }, 3000);
        });
    }
  };

  return (
    <ThemeProvider>
      <PageContainer authentication>
        <AuthUpdatePass
          // @ts-ignore
          handleSubmit={(props: UpdatePasswordFormAttributes) =>
            handleSubmit(props)
          }
          logoImg={Logo}
          logoStyle={{ height: "100 px" }}
          headerTitle={"Update password"}
          submitText={
            success ? "Your password has been successfully updated" : "Submit"
          }
        />
        <AlertWrapper>
          {error && <Alert title="error" message={error} variant="warning" />}
        </AlertWrapper>
      </PageContainer>
    </ThemeProvider>
  );
};

export default UpdatePasswordPage;
