/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { Table } from "@onehq/anton";
import { Link } from "@onehq/framework";
import { CampaignListFieldsFragment } from "../../generated/graphql";
import { BaseTableProps } from "../../types";

export const CampaignTableColumns = [
  {
    id: "name",
    header: "Name",
    accessorKey: "name"
  },
  {
    id: "brandId",
    header: "Brand",
    accessorKey: "brandName"
  },
  {
    id: "provider",
    header: "Provider",
    accessorKey: "providerName",
    enableSorting: false
  },
  {
    id: "code",
    header: "Code",
    accessorKey: "code"
  },
  {
    id: "campaignTypeId",
    header: "Type",
    accessorKey: "type"
  }
];

export const campaignTableDataFormatter = (
  data?: Array<CampaignListFieldsFragment & { organizationId: number }>
) => [
  ...(data?.map(
    (item: CampaignListFieldsFragment & { organizationId: number }) => {
      const { id, name, brand, code, campaignType, organizationId } = item;
      return {
        name: organizationId ? (
          <Link to={`/campaigns/${id}/overview`}>{name}</Link>
        ) : (
          name
        ),
        brandName: brand.name,
        providerName: brand.provider,
        code,
        type: campaignType
      };
    }
  ) || [])
];

export type CampaignTableProps = BaseTableProps<
  CampaignListFieldsFragment & { organizationId: number }
>;

const CampaignTable = ({ data = [], ...props }: CampaignTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={CampaignTableColumns}
      data={campaignTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default CampaignTable;
