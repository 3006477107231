import React from "react";
import {} from "../../types";
import Userfront from "@userfront/core";
import { ADD, useDispatchGrowlContext } from "@onehq/framework";
import { useInactivateUserSessionsQuery } from "../../generated/graphql";

const LogoutPage = () => {
  const alert = useDispatchGrowlContext();

  const { error } = useInactivateUserSessionsQuery();

  if (error?.message) {
    alert({
      type: ADD,
      payload: {
        title: "Failed",
        message: error.message,
        variant: "error"
      }
    });
  }

  Userfront.logout({ redirect: "/" })
    .then(() => {
      console.log("logged out");
    })
    .catch(error => {
      console.log(error);
    });

  return <></>;
};

export default LogoutPage;
