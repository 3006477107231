import React, { useState, useEffect } from "react";
import { DocumentNode, useApolloClient } from "@apollo/client";
import { ADD, REMOVE, useDispatchGrowlContext } from "@onehq/framework";
import { CSVLink } from "react-csv";
import { ButtonProps } from "@onehq/anton";
import { toInteger } from "lodash";

interface Variables {
  [key: string]: any;
}

export interface GenerateCsvProps {
  apolloDocument: DocumentNode;
  filename: string;
  variables?: Variables;
  disable?: boolean;
}

export const useGenerateCsv = ({
  apolloDocument,
  filename,
  variables,
  disable
}: GenerateCsvProps) => {
  const [csvData, setCsvData] = useState<any>("");
  const [alertId] = useState(toInteger(Math.random() * 1000));
  const alert = useDispatchGrowlContext();

  useEffect(() => {
    if (csvData.length > 0) {
      document.getElementById(`csvLinkGenerator-${filename}`)?.click();
      setCsvData("");
    }
    // eslint-disable-next-line
  }, [csvData]);

  // client solution for apollo graphql useLazyQuery re-render bug
  // https://github.com/apollographql/apollo-client/issues/5912
  const client = useApolloClient();
  const onClickLinkCsv = (newVariables?: Variables) => {
    alert({
      type: ADD,
      payload: {
        id: alertId,
        title: "Generating CSV...",
        variant: "progress",
        indeterminate: true
      }
    });
    const finalVariables =
      variables || newVariables ? { ...variables, ...newVariables } : undefined;
    client
      .query({
        query: apolloDocument,
        fetchPolicy: "no-cache",
        context: { headers: { csv: true } },
        variables: finalVariables
      })
      .then((response: any) => {
        const json = response.data.csv as string;
        if (json) {
          const rows = JSON.parse(json);
          setCsvData(rows);
          alert({
            type: ADD,
            payload: {
              title: rows.length === 0 ? "No data" : "CSV generated",
              variant: "success"
            }
          });
        } else {
          alert({
            type: ADD,
            payload: {
              title: "CSV failed",
              message: response.project.error,
              variant: "error"
            }
          });
        }
      })
      .catch(err => console.log(err))
      .finally(() => {
        alert({
          type: REMOVE,
          payload: { id: alertId, title: "", variant: "success" }
        });
      });
  };

  const csvButtonProps: ButtonProps<any> = {
    disabled: disable || false,
    variant: "secondary",
    icon: "externalLink",
    iconPosition: "leading",
    onClick: onClickLinkCsv
  };

  return {
    csvButtonProps,
    csvLink: (
      <CSVLink
        data={csvData}
        id={`csvLinkGenerator-${filename}`}
        filename={`${filename}.csv`}
        target="_blank"
        style={{ visibility: "hidden" }}
      />
    )
  };
};
