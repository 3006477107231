import { Moment } from "moment";
import momenttz from "moment-timezone";
import { convertFromHTMLToString } from "@onehq/anton";
import { ProjectFieldsFragment } from "../../generated/graphql";

// interfaces
// when we added the "_omitSubmit" option to the normalize return,
// we added some scenarios that could omit the submit
// e.g. omit submit if field "name" is not null but "lastname" is null
// but what if those are irrelevant values that has no meaning alone?
// and what if we change something else like "birthday"?
// we could like to continue with the submit and ignore the changes in "name",
// in order to continue with the autosave
// one way to achieve that is to check if one section has an omit submit scenario
// and then check if another section has changes,
// then ignore the section with omit-submit and continue with the change of the other sections
// let's start defining the sections of the project
export type ProjectSection =
  | "Basic Info"
  | "Message"
  | "Sending From"
  | "Sending Schedule"
  | "Texters";

export type TimeZone = ProjectFieldsFragment["timeZone"];
export type TimeZoneInput = { id: string; pgTimeZoneName: string };

// helper functions
// necessary because the rich text field library works with html underneath
// also add spaces to empty lines (fix for iPhone 14 Pro Max omitting empty lines)
export function htmlToString(message: string) {
  const plain = convertFromHTMLToString(message);
  // remove leading/trailing spaces
  let trimmed = plain.replaceAll(/^\s+|\s+$/g, "");
  // add spaces to empty lines (fix for iPhone 14 Pro Max omitting empty lines)
  const fix = (str: string) => str.replaceAll("\n\n", "\n       \n");
  // called many times bc this case: "\n\n\n" => "\n   \n\n"
  while (trimmed !== fix(trimmed)) trimmed = fix(trimmed);
  return trimmed;
}

// formats db date to datefield format
export function formatTime(
  datetime?: string | null,
  timeZone?: TimeZone | null
) {
  if (datetime && timeZone) {
    return momenttz(datetime).tz(timeZone?.pgTimezoneName);
  } else return undefined;
}

// formats datefield date to db format
export function normalizeDate(
  date?: string,
  time?: Moment,
  timeZone?: TimeZoneInput
) {
  if (date && time && timeZone) {
    const zone = timeZone.pgTimeZoneName;
    const moment = momenttz.tz(`${date} ${time.format("HH:mm:ss")}`, zone);
    return moment.utc();
  } else return null;
}
