// react modules
import React from "react";

// third-party modules
import _ from "lodash";
import { NestedResourceRoute, ResourceRoute } from "@onehq/framework";

// app modules
import {
  ShortenedLinkFieldsFragment,
  useCreateShortenedLinkMutation,
  useGetShortenedLinkQuery,
  useNewShortenedLinkQuery,
  useUpdateShortenedLinkMutation
} from "../../generated/graphql";
import sections, {
  ShortenedLinkGeneralFormOmitValues as formOmitValues
} from "./sections";
import DataOverview from "../../components/pages/DataOverview";
import ListPage from "../../components/pages/ListPage";
import ShortenedLinkAnchor from "./ShortenedLinkAnchor";
import { SHORTENED_LINK_PATH } from "../../constants/shortenedLinkConstants";

const ShortenedLinksRoute = new ResourceRoute({
  name: "ShortenedLink",
  path: SHORTENED_LINK_PATH,
  AnchorComponent: ShortenedLinkAnchor,
  IndexComponent: () => <ListPage variant="ShortenedLinks" />,
  formProps: {
    autosave: true,
    scrollable: true,
    sections,
    // @ts-ignore
    hydrate: [useNewShortenedLinkQuery, "newShortenedLink"],
    // @ts-ignore
    query: [useGetShortenedLinkQuery, "shortenedLink"],
    // @ts-ignore
    create: [useCreateShortenedLinkMutation, "createShortenedLink"],
    // @ts-ignore
    update: [useUpdateShortenedLinkMutation, "updateShortenedLink"],
    normalize: (shortenedLink: ShortenedLinkFieldsFragment) => {
      return {
        ..._.omit(shortenedLink, formOmitValues)
      };
    }
  },
  routes: [
    new NestedResourceRoute({
      name: "Overview",
      path: "overview",
      baseRoute: "ShortenedLink",
      IndexComponent: () => <DataOverview />
    })
  ]
});

export { ShortenedLinksRoute };
