import React, { useEffect, useState } from "react";
import { ErrorResponse } from "../../types";
import Userfront from "@userfront/core";
import { Alert, Authentication, ThemeProvider } from "@onehq/anton";
import Logo from "../../assets/imgs/authenticationLogo.png";
import { AlertWrapper, PageContainer } from "../../components/authStyles";
import { isOptPage, redirectToOptPages } from "../../utils/redirectToOptPages";

interface AuthFormAttributes {
  email: string;
  password: string;
}

const AuthLogin = Authentication.Login;

const LoginPage = () => {
  const [error, setError] = useState<String>("");

  // redirects to page if u are already logged in
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Userfront.redirectIfLoggedIn({ redirect: "/dashboard" });
  }, []);

  const handleSubmit = (attr: AuthFormAttributes) => {
    Userfront.login({
      method: "password",
      email: attr.email,
      password: attr.password
    }).catch((error: ErrorResponse) => {
      console.log(error);
      setError(error.message ? error.message : "");
      setTimeout(function () {
        setError("");
      }, 3000);
    });
  };

  const loginComponent = (
    <ThemeProvider>
      <PageContainer authentication>
        <AuthLogin
          // @ts-ignore
          handleSubmit={(props: AuthFormAttributes) => handleSubmit(props)}
          logoImg={Logo}
          logoStyle={{ height: "100px" }}
          passwordRecoveryLink={"/password-recovery"}
        />
        <AlertWrapper>
          {error && <Alert title="error" message={error} variant="warning" />}
        </AlertWrapper>
      </PageContainer>
    </ThemeProvider>
  );

  const selectedComponent = () => {
    if (isOptPage()) {
      return redirectToOptPages();
    } else {
      return loginComponent;
    }
  };
  return selectedComponent();
};

export default LoginPage;
