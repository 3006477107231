import { BadgeTheme, DotColor } from "@onehq/anton";
import { PhoneStatus } from "../generated/graphql";

export function phoneStatusColor(status: PhoneStatus): DotColor {
  switch (status) {
    case PhoneStatus.Active:
      return "pea60" as DotColor;
    case PhoneStatus.Blocked:
      return "wine40" as DotColor;
    case PhoneStatus.Burned:
      return "wine40" as DotColor;
    case PhoneStatus.Invalid:
      return "wine40" as DotColor;
    case PhoneStatus.Released:
      return "wine40" as DotColor;
    case PhoneStatus.Unconfirmed:
      return "wine40" as DotColor;
    default:
      return "iris60" as DotColor;
  }
}

export function phoneStatusBadgeColor(status: PhoneStatus): BadgeTheme {
  switch (status) {
    case PhoneStatus.Active:
      return "green" as BadgeTheme;
    case PhoneStatus.Blocked:
    case PhoneStatus.Burned:
    case PhoneStatus.Invalid:
    case PhoneStatus.Released:
    case PhoneStatus.Unconfirmed:
      return "red" as BadgeTheme;
    default:
      return "purple" as BadgeTheme;
  }
}
