import {
  ProjectQueryFilterFields,
  FilterOperation,
  GetProjectsListCsvDocument,
  TextQueryFilterFields,
  GetTextsListCsvDocument,
  UserQueryFilterFields,
  GetUsersListCsvDocument
} from "../generated/graphql";
import { BaseFilterTabsProps } from "../types";
import { PAGE_SIZE } from "../utils";

export const TEAMS_PATH = "teams";

export const TEAM_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  USERS: "Users",
  PROJECTS: "Projects",
  TEXTS: "Texts"
};

export const TEAM_VIEW_LINKS = [
  {
    id: TEAM_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    text: TEAM_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    name: TEAM_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    path: "/overview"
  },
  {
    id: TEAM_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    text: TEAM_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    name: TEAM_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    path: "/projects"
  },
  {
    id: TEAM_MENU_ANCHOR_VIEW_LINKS.USERS,
    text: TEAM_MENU_ANCHOR_VIEW_LINKS.USERS,
    name: TEAM_MENU_ANCHOR_VIEW_LINKS.USERS,
    path: "/users"
  },
  {
    id: TEAM_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    text: TEAM_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    name: TEAM_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    path: "/texts"
  }
];

type TeamFilterTabsProps = BaseFilterTabsProps & {
  projectsOnTeam?: string[];
};

export const teamTabFilters = ({
  id,
  projectsOnTeam = [],
  selectedTab
}: TeamFilterTabsProps) => {
  const filterOptions = {
    [TEAM_MENU_ANCHOR_VIEW_LINKS.PROJECTS]: {
      variables: {
        first: PAGE_SIZE,
        filters: projectsOnTeam.length
          ? [
              {
                field: ProjectQueryFilterFields.Id,
                operation: FilterOperation.In,
                arrayValues: projectsOnTeam
              }
            ]
          : []
      },
      listDocument: GetProjectsListCsvDocument
    },
    [TEAM_MENU_ANCHOR_VIEW_LINKS.TEXTS]: {
      variables: {
        first: PAGE_SIZE,
        filters: projectsOnTeam.length
          ? [
              {
                field: TextQueryFilterFields.ProjectId,
                operation: FilterOperation.In,
                arrayValues: projectsOnTeam
              }
            ]
          : []
      },
      listDocument: GetTextsListCsvDocument
    },
    [TEAM_MENU_ANCHOR_VIEW_LINKS.USERS]: {
      first: PAGE_SIZE,
      variables: {
        filters: [
          {
            field: UserQueryFilterFields.TeamId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetUsersListCsvDocument
    }
  };
  return filterOptions[selectedTab];
};
