import {
  TextQueryFilterFields,
  FilterOperation,
  GetTextsListCsvDocument
} from "../generated/graphql";
import { BaseFilterTabsProps } from "../types";
import { PAGE_SIZE } from "../utils";

export const PHONES_PATH = "phones";

export const PHONE_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  RECEIVED_TEXTS: "Received Texts",
  SENT_TEXTS: "Sent Texts"
};

export const phoneTabFilters = ({ id, selectedTab }: BaseFilterTabsProps) => {
  const filterOptions = {
    [PHONE_MENU_ANCHOR_VIEW_LINKS.RECEIVED_TEXTS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: TextQueryFilterFields.ToPhoneId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetTextsListCsvDocument
    },
    [PHONE_MENU_ANCHOR_VIEW_LINKS.SENT_TEXTS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: TextQueryFilterFields.FromPhoneId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetTextsListCsvDocument
    }
  };
  return filterOptions[selectedTab];
};
