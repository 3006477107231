import React, { useMemo } from "react";
import { SearchField, TextField, useWatchContext } from "@onehq/anton";
import { ListFilterType } from "../../generated/graphql";
import { stringsToOptions } from "../../utils";
import { BasicOption as Option } from "../../types";
import { FilterTypeGroup } from "./utils";

const { Carrier, State } = ListFilterType;

const listFilterOptions = stringsToOptions(Object.keys(ListFilterType), true);
const otherOptions = listFilterOptions.filter(f => {
  return f.value !== Carrier && f.value !== State;
});

export interface ListFilterFieldsProps {
  carriers: Option[];
  states: Option[];
}

const ListFilterFields = ({ carriers, states }: ListFilterFieldsProps) => {
  const type: FilterTypeGroup = useWatchContext("type");
  const subType: Option[] = useWatchContext("subType");

  const options = useMemo(() => {
    if (type === "Carrier") return carriers;
    else if (type === "State") {
      return states.filter(state => {
        return !subType.find(selected => state.label === selected.label);
      });
    } else return otherOptions;
  }, [type, subType, carriers, states]);

  return (
    <>
      <TextField label="List Filter Type" name="type" span={3} disabled />
      {/* @ts-ignore */}
      <SearchField
        name="subType"
        label="Values"
        placeholder={`Select an option`}
        defaultOptions={options}
        loadOptions={text => {
          return Promise.resolve(
            options.filter(o => {
              return o.value.toLowerCase().includes(text.toLowerCase());
            })
          );
        }}
        span={21}
        isMulti
      />
    </>
  );
};

export default ListFilterFields;
