import {
  FilterOperation,
  GetPhonesListCsvDocument,
  PhoneQueryFilterFields
} from "../generated/graphql";
import { BaseFilterTabsProps } from "../types";
import { PAGE_SIZE } from "../utils";

export const CAMPAIGNS_PATH = "campaigns";

export const CAMPAIGN_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  PHONES: "Phones"
};

export const CAMPAIGN_MENU_ANCHOR_EDIT_LINKS = {
  GENERAL: "General"
};

export const campaignTabFilters = ({
  id,
  selectedTab
}: BaseFilterTabsProps) => {
  const filterOptions = {
    [CAMPAIGN_MENU_ANCHOR_VIEW_LINKS.PHONES]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: PhoneQueryFilterFields.CampaignId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetPhonesListCsvDocument
    }
  };
  return filterOptions[selectedTab];
};
