import React, { useMemo, useRef, useState } from "react";
import {
  badgeCell,
  ConfirmationModal,
  FormBuilder as DefaultForm,
  NestedForm,
  PhoneInput,
  SearchInput,
  Table,
  useWatchContext
} from "@onehq/anton";
import {
  FilterOperation,
  PhoneQueryFilterFields,
  PhoneSort,
  PhoneStatus,
  useBuyNumberMutation,
  useCreatePhoneMutation,
  useGetPhonesListQuery,
  useReleaseNumberMutation,
  useSearchPhoneNumbersLazyQuery
} from "../../../generated/graphql";
import styled from "styled-components";
import {
  addErrorAlert,
  addSpacesBetweenWordsWithAcronyms,
  addSuccessAlert,
  formatPhone,
  phoneStatusBadgeColor
} from "../../../utils";
import {
  ADD,
  GrowlAlertDispatch,
  useDispatchGrowlContext
} from "@onehq/framework";
import { CellContext } from "@tanstack/react-table";
import { NoPaddingButton } from "../../clients/sections/index.styles";

const Wrapper = styled.div`
  padding: 0 ${props => props.theme.space.spacing8};

  & form {
    min-height: unset;
  }
`;
const FormBuilder = styled(DefaultForm)`
  & td > * {
    padding: 0;
  }

  & td input {
    background-color: transparent !important;
    border: none;
    padding: 0;
  }

  & tr:last-child > td:first-child {
    padding: 0;

    [class$="-control"] {
      border: none;
      background-color: transparent;
    }
  }
`;
const Note = styled.div`
  font-size: ${props => props.theme.font.sm.size};
  padding: ${props => props.theme.space.spacing2};
  padding-bottom: 0;
`;

interface ToRelease {
  id: string;
  number: string;
}
interface ToPurchase {
  number: number;
  type: "create" | "purchase";
}

const CampaignPhonesForm = () => {
  // provider number that requests confirmation to be released
  const [releasing, setReleasing] = useState<ToRelease>();
  const [purchasing, setPurchasing] = useState<ToPurchase>();

  const id = useWatchContext("id");
  const brand = useWatchContext("brand");

  const alert: GrowlAlertDispatch = useDispatchGrowlContext();
  const ref = useRef<HTMLFormElement>(null);

  const { data, loading, refetch } = useGetPhonesListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: [
        {
          field: PhoneQueryFilterFields.CampaignId,
          operation: FilterOperation.With,
          value: "true"
        },
        {
          field: PhoneQueryFilterFields.CampaignId,
          operation: FilterOperation.Equal,
          value: id
        }
      ],
      sortBy: PhoneSort.UpdatedAt
    }
  });
  const phones = useMemo(() => {
    return data?.phones.nodes || [];
  }, [data]);
  const tableValues = useMemo(() => {
    const values = phones.map(p => {
      if (!p) return {};
      const action = () => setReleasing({ id: p.id, number: p.number });
      const badge = badgeCell({
        value: addSpacesBetweenWordsWithAcronyms(p.phoneStatus),
        color: phoneStatusBadgeColor(p.phoneStatus)
      });
      return { number: formatPhone(p.number), status: badge, action };
    });
    // add last row for adding purchased phones
    if (id && values.length < 49) {
      const action = () => ref.current?.submit();
      return [...values, { number: "", action }];
    } else return [...values];
  }, [phones, id, ref]);

  const [createPhoneMutation] = useCreatePhoneMutation();
  const createPhone = useMemo(() => {
    return (number?: number) => {
      // should be three digits
      if (!number || number.toString().length !== 10) {
        alert({
          type: ADD,
          payload: {
            title: "Error creating phone number",
            message: "Invalid number. Should be a 10 digits number",
            variant: "error"
          }
        });
      } else {
        createPhoneMutation({
          variables: {
            attributes: {
              campaignId: id,
              number: number.toString(),
              phoneStatus: PhoneStatus.AwaitingApiVerify,
              phoneTypeId: `PhoneType::::${brand.provider}`
            }
          }
        })
          .then(response => {
            const errors = response.data?.createPhone?.errors;
            const hasErrors = errors && Object.keys(errors).length > 0;
            if (hasErrors) {
              Object.keys(errors).forEach(e => {
                addErrorAlert(
                  alert,
                  "Error buying number",
                  errors[e] as string
                );
              });
            } else {
              addSuccessAlert(
                alert,
                "All changes saved",
                "Phone created succesfully"
              );
              void refetch();
            }
          })
          .catch(error => console.log(error));
      }
    };
  }, [id, brand, createPhoneMutation, refetch, alert]);

  // release purchased number, for the table action
  const [releaseNumberMutation] = useReleaseNumberMutation({
    onCompleted: response => {
      const error = response.releaseNumber?.error as string;
      if (error) {
        addErrorAlert(alert, "Error releasing number", error);
      } else {
        const message = response.releaseNumber?.message as string;
        addSuccessAlert(alert, "Number released successfully", message);
        void refetch();
      }
    },
    onError: error => {
      addErrorAlert(alert, "Error releasing number", error.message);
    }
  });
  const releaseNumber = useMemo(() => {
    return (numberId: string) => {
      void releaseNumberMutation({ variables: { phoneId: numberId } });
    };
  }, [releaseNumberMutation]);

  // search numbers for the table search input
  const [searchProviderNumbersQuery] = useSearchPhoneNumbersLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  // buy a number for the campaign, passing the areacode
  const [buyNumberMutation] = useBuyNumberMutation();
  const buyNumber = useMemo(() => {
    return (number: number) => {
      buyNumberMutation({
        variables: { phoneNumber: number.toString(), campaignId: id }
      })
        .then(response => {
          const data = response.data?.buyNumber;
          const errors = data?.errors as string;
          if (errors) {
            addErrorAlert(alert, "Error buying number", errors);
          } else {
            const message = data?.message as string;
            addSuccessAlert(alert, "All changes saved", message);
            void refetch();
          }
        })
        .catch(error => console.log(error));
    };
  }, [id, buyNumberMutation, refetch, alert]);

  const onSubmit = useMemo(() => {
    return () => {
      if (purchasing) {
        const { number, type } = purchasing;
        const digits = number.toString().length;

        if (number && digits === 10) {
          if (type === "purchase") buyNumber(number);
          else if (type === "create") createPhone(number);
        } else addErrorAlert(alert, "Error", "Invalid phone number");
      } else addErrorAlert(alert, "Error", "Select a phone number first");

      return Promise.resolve();
    };
  }, [purchasing, buyNumber, createPhone, alert]);

  const PhoneFieldCell = useMemo(() => {
    return ({ row, getValue }: CellContext<any, any>) => {
      // all the rows, except the last, that is an extra empty row
      if (row.index < phones.length) {
        return <PhoneInput value={getValue()} disabled />;
      } else
        return (
          <>
            {/* @ts-ignore */}
            <SearchInput
              name="number-new"
              defaultOptions={[]}
              placeholder="Add/Buy number *"
              loadOptions={async (text: string) => {
                const number = text.replaceAll(/\(|\)|-|\s/g, "");
                const digits = number.toString().length;
                if (!number) return Promise.resolve([]);
                else if (digits === 10) {
                  return Promise.resolve([
                    {
                      label: formatPhone(number),
                      value: { number, type: "create" }
                    }
                  ]);
                } else if (digits === 3) {
                  const { data } = await searchProviderNumbersQuery({
                    variables: {
                      areaCode: number,
                      campaignId: id
                    }
                  });
                  const errors: string | null = data?.searchPhoneNumbers.errors;
                  if (errors) {
                    addErrorAlert(alert, "Error searching numbers", errors);
                    return Promise.resolve([]);
                  } else {
                    const phones = (data?.searchPhoneNumbers || []) as string[];
                    return phones.map(p => ({
                      label: formatPhone(p),
                      value: { number: p, type: "purchase" }
                    }));
                  }
                } else return Promise.resolve([]);
              }}
              onChange={(option: any) => {
                if (option?.value) {
                  setPurchasing(option.value as ToPurchase);
                } else setPurchasing(undefined);
              }}
            />
          </>
        );
    };
  }, [phones, id, searchProviderNumbersQuery, alert]);

  const ActionCell = useMemo(() => {
    return ({ row, getValue }: CellContext<any, any>) => {
      const onClick = getValue();
      if (row.index < phones.length) {
        return (
          <NoPaddingButton variant="inline" onClick={onClick}>
            Release
          </NoPaddingButton>
        );
      } else
        return (
          <NoPaddingButton variant="inline" onClick={onClick}>
            Create
          </NoPaddingButton>
        );
    };
  }, [phones]);

  const columns = useMemo(() => {
    return [
      {
        id: "number",
        header: "Phone Number",
        accessorKey: "number",
        cell: PhoneFieldCell,
        size: 175
      },
      {
        id: "phoneStatusId",
        header: "Status",
        accessorKey: "status",
        cell: ({ getValue }) => getValue()
      },
      {
        id: "action",
        header: "Action",
        accessorKey: "action",
        columnMaxWidth: 140,
        cell: ActionCell
      }
    ];
  }, [PhoneFieldCell, ActionCell]);

  const PhonesTableForm = useMemo(() => {
    return () => (
      <div>
        <Table
          variant="data-grid"
          columns={columns}
          data={tableValues}
          setData={() => null}
          skeleton={loading}
          addRows
        />
        <Note>
          * If want to buy a new number, type the areacode and select one number
        </Note>
        <Note>
          {
            "* If bought one and don't see it, type the whole number and select your number"
          }
        </Note>
      </div>
    );
  }, [tableValues, loading, columns]);

  return (
    <>
      <Wrapper>
        <FormBuilder values={{}} onSubmit={onSubmit} autosave={false} ref={ref}>
          <NestedForm
            name="phonesAttributes"
            component={PhonesTableForm}
            addable={false}
            hasOne
          />
        </FormBuilder>
      </Wrapper>
      <ConfirmationModal
        message={`Are you sure you want to release this number? ${releasing?.number}`}
        title="Release phone number?"
        confirmLabel="Release"
        confirmIcon="trash2"
        open={!!releasing}
        handleClose={() => setReleasing(undefined)}
        onConfirm={() => releaseNumber(releasing?.id || "")}
      />
    </>
  );
};

export default CampaignPhonesForm;
