import { ListFilterFieldsFragment } from "../../generated/graphql";
import { formatFilters, ListFilterResource } from "./utils";

export const FiltersTableColumns = [
  {
    header: "List Filter Type",
    accessorKey: "type",
    enableSorting: false
  },
  {
    header: "Values",
    accessorKey: "subType",
    enableSorting: false
  }
];

export function listFilterTableDataFormatter(
  data?: any,
  resource: ListFilterResource = "Organization"
) {
  const format = formatFilters((data as ListFilterFieldsFragment[]) || []);
  const clean =
    resource === "Project" ? format : format.filter(f => f.type === "Other");
  return clean.map(({ type, subType }) => {
    const subTypes = subType.length
      ? subType.map(t => t.label).join(", ")
      : "--";
    return { type, subType: subTypes };
  });
}
