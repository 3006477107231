// react modules
import React, { useEffect, useMemo, useState } from "react";

// third-party modules
import {
  badgeCell,
  CellInputType,
  ConfirmationModal,
  Fieldset,
  FormBuilder,
  NestedForm,
  PhoneInput,
  SearchInput,
  SelectInput,
  Table,
  TextInput,
  useWatchContext
} from "@onehq/anton";
import {
  ADD,
  GrowlAlertDispatch,
  REMOVE,
  useDispatchGrowlContext
} from "@onehq/framework";
import styled from "styled-components";

// app modules
import {
  FilterOperation,
  PhoneQueryFilterFields,
  PhoneType,
  useGetPhonesListQuery,
  useDestroyPhoneMutation,
  useReleaseNumberMutation,
  useSearchPhoneNumbersLazyQuery,
  useBuyNumberMutation,
  CampaignQueryFilterFields,
  useUpdateClientMutation,
  useCreatePhoneMutation,
  PhoneStatus,
  Provider,
  useGetCampaignsListQuery,
  GetCampaignQuery as Campaign
} from "../../../generated/graphql";
import { NoPaddingButton, TableContainer } from "./index.styles";
import {
  addErrorAlert,
  addSpacesBetweenWordsWithAcronyms,
  addSuccessAlert,
  formatPhone,
  PAGE_SIZE,
  phoneStatusBadgeColor
} from "../../../utils";
import { buttonActionCell } from "./utils";
import { DEFAULT_LIMIT } from "../../../constants";
import { CellContext } from "@tanstack/react-table";
import { campaignsToOptions } from "../../../components/Inputs/CampaignField";

// table columns
const CLIENT_NUMBERS_TABLE_COLUMNS = [
  {
    id: "id",
    header: "ID",
    accessorKey: "id",
    inputType: "textField" as CellInputType,
    size: 50,
    meta: {
      disabled: true,
      placeholder: " "
    }
  },
  {
    id: "number",
    header: "Phone Number",
    accessorKey: "number",
    inputType: "phoneField" as CellInputType
  },
  {
    id: "description",
    header: "Description",
    accessorKey: "description",
    inputType: "textField" as CellInputType,
    enableSorting: false
  },
  {
    id: "action",
    header: "Action",
    accessorKey: "action",
    enableSorting: false,
    columnMaxWidth: 140,
    cell: buttonActionCell("Delete")
  }
];

const Wrapper = styled.div`
  & td > * {
    padding: 0;
  }

  & td input {
    background-color: transparent !important;
    border: none;
    padding: 0;
  }

  & tr:last-child > td:not(:last-child) {
    padding: 0;

    [class$="-control"] {
      border: none;
      background-color: transparent;
    }
  }
`;
const Note = styled.div`
  font-size: ${props => props.theme.font.sm.size};
  padding: ${props => props.theme.space.spacing2};
  padding-bottom: 0;
`;

const Providers = Object.keys(Provider);
const PhoneProviderTypes = Object.keys(PhoneType).filter(p => {
  return Providers.includes(p);
});

interface DeletePhone {
  id?: string;
  number?: string;
  action: "delete" | "release";
}
interface NewPhone {
  number?: number;
  campaignId?: string;
  type?: "create" | "purchase";
}

const ClientNumbersForm = () => {
  // id watcher this is for know if its edition or creation
  const id = useWatchContext("id");

  // hook for get a function to create alerts
  const alert: GrowlAlertDispatch = useDispatchGrowlContext();

  // state variables
  // selected client number to delete or provider number to release
  const [selectedNumber, setSelectedNumber] = useState<DeletePhone>();
  const [newPhone, setNewPhone] = useState<NewPhone>({});

  // QUERIES
  // get phones query (on first render)
  const {
    data: clientPhonesData,
    loading: loadingClientPhones,
    refetch: refetchClientPhones
  } = useGetPhonesListQuery({
    variables: {
      first: PAGE_SIZE,
      filters: [
        {
          field: PhoneQueryFilterFields.ClientId,
          operation: FilterOperation.Equal,
          value: id
        },
        {
          field: PhoneQueryFilterFields.PhoneTypeId,
          operation: FilterOperation.Equal,
          value: `PhoneType::::${PhoneType.Client}`
        }
      ]
    }
  });

  const [clientPhones, setClientPhones] = useState<any>([]);

  useEffect(() => {
    setClientPhones(
      clientPhonesData?.phones.nodes?.map(p => ({
        id: p?.id,
        number: formatPhone(p?.number || ""),
        description: p?.description,
        action: () =>
          setSelectedNumber({
            id: p?.id,
            number: p?.number,
            action: "delete"
          })
      }))
    );
  }, [clientPhonesData]);

  const clientPhonesInitialValues = useMemo(
    () =>
      clientPhonesData?.phones.nodes?.reduce(
        (acc, p, index) => ({
          ...acc,
          ...{
            [`id-${index}`]: p?.id,
            [`number-${index}`]: formatPhone((p?.number as string) || ""),
            [`description-${index}`]: p?.description
          }
        }),
        {}
      ),
    [clientPhonesData]
  );

  // get phones for purchasedPhones
  const { data, loading, refetch } = useGetPhonesListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      first: PAGE_SIZE,
      filters: [
        {
          field: PhoneQueryFilterFields.ClientId,
          operation: FilterOperation.Equal,
          value: id
        },
        {
          field: PhoneQueryFilterFields.PhoneTypeId,
          operation: FilterOperation.In,
          arrayValues: PhoneProviderTypes.map(p => `PhoneType::::${p}`)
        }
      ]
    },
    onError: error => console.log(error)
  });
  const purchasedPhones = useMemo(() => {
    return data?.phones.nodes || [];
  }, [data]);

  // searchNumbers query for the table
  const [searchProviderNumbersQuery] = useSearchPhoneNumbersLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  const { data: campaignsData } = useGetCampaignsListQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      limit: DEFAULT_LIMIT,
      filters: [
        {
          field: CampaignQueryFilterFields.ClientId,
          operation: FilterOperation.Equal,
          value: id
        }
      ]
    }
  });
  const campaigns: Campaign["campaign"][] = useMemo(() => {
    const nodes = campaignsData?.campaigns.nodes || [];
    return nodes?.filter(c => !!c) as Campaign["campaign"][];
  }, [campaignsData]);
  const campaignOptions = useMemo(() => {
    return campaignsToOptions(campaigns, true, true);
  }, [campaigns]);

  // MUTATIONS
  // update client internal numbers
  const [updateClientMutation] = useUpdateClientMutation({
    onCompleted: response => {
      if (Object.keys(response?.updateClient?.errors || {}).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "Number saved successfully",
            variant: "success"
          }
        });
        // blanking inputs if creation is successful
        // ASK FOR HELP, how can i blank inputs
        void refetchClientPhones();
      } else {
        alert({
          type: ADD,
          payload: {
            title: "Error saving number",
            variant: "error"
          }
        });
      }
    },
    onError: err => {
      console.error(err.message);
      alert({
        type: ADD,
        payload: {
          title: "Error saving number",
          variant: "error"
        }
      });
    }
  });
  const transformData = (data: { [keyName: string]: string }) => {
    const result: { id: string; description: string; number: string }[] = [];

    for (let i = 0; i < (Object.keys(data).length - 1) / 3; i++) {
      // if (data[`number-${i}`])
      result.push({
        id: data[`id-${i}`],
        description: data[`description-${i}`],
        number: data[`number-${i}`].replace(/[-()\s_]/g, "")
      });
    }

    return result;
  };
  const updateClientNumbers = async (value: {
    clientNumbers: { [keyName: string]: string };
  }) => {
    const idForAlert = Math.floor(Math.random() * 10000 + 1);
    alert({
      type: ADD,
      payload: {
        id: idForAlert,
        title: "Saving Number",
        variant: "progress"
      }
    });
    await updateClientMutation({
      variables: {
        id,
        attributes: { phones: transformData(value.clientNumbers) }
      }
    }).then(() => {
      alert({
        type: REMOVE,
        payload: {
          id: idForAlert,
          title: "",
          variant: "success"
        }
      });
    });
  };

  // delete phone action for the table action
  const [destroyPhoneMutation] = useDestroyPhoneMutation({
    onCompleted: response => {
      if (Object.keys(response?.destroyPhone?.errors || {}).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "Number deleted successfully",
            variant: "success"
          }
        });
        void refetchClientPhones();
      } else {
        response?.destroyPhone?.errors &&
          addErrorAlert(
            alert,
            "Error, client not loaded",
            // eslint-disable-next-line
            Object.keys(response?.destroyPhone?.errors)
              .map(el =>
                el === "base"
                  ? response.destroyPhone?.errors[el]
                  : `${el}: ${response.destroyPhone?.errors[el]}`
              )
              .join(". ")
          );
      }
    },
    onError: err => {
      console.error(err.message);
      alert({
        type: ADD,
        payload: {
          title: "Error deleting number",
          variant: "error"
        }
      });
    }
  });
  const deleteNumber = async id => {
    const idForAlert = Math.floor(Math.random() * 10000 + 1);
    alert({
      type: ADD,
      payload: {
        id: idForAlert,
        title: "Deleting Number",
        variant: "progress"
      }
    });
    await destroyPhoneMutation({
      variables: {
        id
      }
    }).then(() => {
      alert({
        type: REMOVE,
        payload: {
          id: idForAlert,
          title: "",
          variant: "success"
        }
      });
    });
  };

  // manually create a new purchased provider phone
  const [createPhoneMutation] = useCreatePhoneMutation();
  const createPhone = useMemo(() => {
    return (number?: number, campaignId?: string) => {
      const campaign = campaigns.find(c => c?.id === campaignId);
      if (!campaign) {
        addErrorAlert(
          alert,
          "Error creating phone number",
          campaignId ? "Select a campaign first" : "Campaign not found"
        );
      } else if (!number || number.toString().length !== 10) {
        addErrorAlert(
          alert,
          "Error creating phone number",
          "Invalid number. Should be a 10 digits number"
        );
      } else {
        createPhoneMutation({
          variables: {
            attributes: {
              campaignId,
              number: number.toString(),
              phoneStatus: PhoneStatus.AwaitingApiVerify,
              phoneTypeId: `PhoneType::::${campaign.brand.provider}`
            }
          }
        })
          .then(response => {
            const errors = response.data?.createPhone?.errors;
            const hasErrors = errors && Object.keys(errors).length > 0;
            if (hasErrors) {
              Object.keys(errors).forEach(e => {
                addErrorAlert(
                  alert,
                  "Error buying number",
                  errors[e] as string
                );
              });
            } else {
              addSuccessAlert(
                alert,
                "All changes saved",
                "Phone created succesfully"
              );
              void refetch();
            }
          })
          .catch(error => console.log(error))
          .finally(() => setNewPhone({}));
      }
    };
  }, [campaigns, createPhoneMutation, refetch, alert]);

  // release purchased number, for the table action
  const [releaseNumberMutation] = useReleaseNumberMutation({
    onCompleted: response => {
      if (response.releaseNumber?.error) {
        alert({
          type: ADD,
          payload: {
            title: "Error releasing number",
            message: response.releaseNumber.error,
            variant: "error"
          }
        });
      } else {
        void refetch();
        alert({
          type: ADD,
          payload: {
            title: "Number released successfully",
            message: response.releaseNumber?.message || undefined,
            variant: "success"
          }
        });
      }
    },
    onError: error => {
      console.log(error);
      alert({
        type: ADD,
        payload: {
          title: "Error removing number",
          variant: "error"
        }
      });
    }
  });
  const releaseNumber = async (numberId: string) => {
    const idForAlert = Math.floor(Math.random() * 10000 + 1);
    alert({
      type: ADD,
      payload: {
        id: idForAlert,
        title: "Removing Number",
        variant: "progress"
      }
    });
    await releaseNumberMutation({
      variables: {
        phoneId: numberId
      }
    }).then(() => {
      alert({
        type: REMOVE,
        payload: {
          id: idForAlert,
          title: "",
          variant: "success"
        }
      });
    });
  };

  // buy provider number mutation for the table action
  const [buyNumberMutation] = useBuyNumberMutation({
    onCompleted: response => {
      if (response.buyNumber?.errors) {
        alert({
          type: ADD,
          payload: {
            title: "Error buying number",
            message: response.buyNumber.errors,
            variant: "error"
          }
        });
      } else {
        void refetch();
        // void searchNumbersOnSubmit(); shoud i refetch search numbers?
        alert({
          type: ADD,
          payload: {
            title: "Number bought successfully",
            message: response.buyNumber?.message || undefined,
            variant: "success"
          }
        });
      }
    },
    onError: error => {
      console.log(error);
      alert({
        type: ADD,
        payload: {
          title: "Error buying number",
          variant: "error"
        }
      });
    }
  });
  const buyNumber = useMemo(() => {
    return (phoneNumber: string, campaignId: string) => {
      const idForAlert = Math.floor(Math.random() * 10000 + 1);
      alert({
        type: ADD,
        payload: {
          id: idForAlert,
          title: "Buying Number",
          variant: "progress"
        }
      });
      buyNumberMutation({
        variables: {
          phoneNumber,
          campaignId
        }
      })
        .then(() => {
          alert({
            type: REMOVE,
            payload: {
              id: idForAlert,
              title: "",
              variant: "success"
            }
          });
        })
        .catch(e => console.log(e))
        .finally(() => setNewPhone({}));
    };
  }, [alert, buyNumberMutation]);

  // buy or create provider phone, based on type
  const createPurchasedPhone = useMemo(() => {
    return () => {
      const { campaignId, number, type } = newPhone;
      if (!campaignId) {
        addErrorAlert(alert, "Error", "Select a campaign first");
      } else if (!number || !type) {
        addErrorAlert(alert, "Error", "Select a phone number first");
      } else {
        const digits = number.toString().length;
        if (number && digits === 10) {
          if (type === "purchase") buyNumber(number.toString(), campaignId);
          else if (type === "create") createPhone(number, campaignId);
        } else addErrorAlert(alert, "Error", "Invalid phone number");
      }
      return Promise.resolve();
    };
  }, [newPhone, buyNumber, createPhone, alert]);

  const purchagesPhonesTableValues = useMemo(() => {
    const values = purchasedPhones.map(p => {
      if (p)
        return {
          campaignId: p.campaignId,
          number: p.number,
          status: badgeCell({
            value: addSpacesBetweenWordsWithAcronyms(p.phoneStatus),
            color: phoneStatusBadgeColor(p.phoneStatus)
          }),
          action: () =>
            setSelectedNumber({
              id: p?.id,
              number: p?.number,
              action: "release"
            })
        };
      else return {};
    });
    return [...values, { action: createPurchasedPhone }];
  }, [purchasedPhones, createPurchasedPhone]);

  const CampaignFieldCell = useMemo(() => {
    return ({ row, getValue }: CellContext<any, any>) => {
      // all the rows, except the last, that is an extra empty row
      if (row.index < purchasedPhones.length) {
        const name = campaignOptions.reduce((found, current) => {
          if (found) return found;
          return current.options.find(o => o.value === getValue())?.label;
        }, null);
        return <TextInput value={name} disabled />;
      } else {
        return (
          <>
            {/* @ts-ignore */}
            <SelectInput
              placeholder="Select Campaign"
              options={campaignOptions}
              // note that, if campaign changed, the number resets
              onChange={(option: any) => setNewPhone({ campaignId: option })}
              value={newPhone.campaignId}
            />
          </>
        );
      }
    };
  }, [campaignOptions, purchasedPhones, newPhone]);

  const PhoneFieldCell = useMemo(() => {
    return ({ row, getValue }: CellContext<any, any>) => {
      // all the rows, except the last, that is an extra empty row
      if (row.index < purchasedPhones.length) {
        return <PhoneInput value={getValue()} disabled />;
      } else {
        return (
          <>
            {/* @ts-ignore */}
            <SearchInput
              name="number-new"
              defaultOptions={[]}
              placeholder="Add/Buy number *"
              loadOptions={async (text: string) => {
                const number = text.replaceAll(/\(|\)|-|\s/g, "");
                const digits = number.toString().length;
                if (!number) return Promise.resolve([]);
                else if (digits === 10) {
                  return Promise.resolve([
                    {
                      label: formatPhone(number),
                      value: { number, type: "create" }
                    }
                  ]);
                } else if (digits === 3 && newPhone.campaignId) {
                  const { data } = await searchProviderNumbersQuery({
                    variables: {
                      areaCode: number,
                      campaignId: newPhone.campaignId
                    }
                  });
                  const errors: string | null = data?.searchPhoneNumbers.errors;
                  if (errors) {
                    addErrorAlert(alert, "Error searching numbers", errors);
                    return Promise.resolve([]);
                  } else {
                    const phones = (data?.searchPhoneNumbers || []) as string[];
                    return phones.map(p => ({
                      label: formatPhone(p),
                      value: { number: p, type: "purchase" }
                    }));
                  }
                } else return Promise.resolve([]);
              }}
              onChange={(option: any) => {
                if (option) setNewPhone(p => ({ ...p, ...option?.value }));
                else setNewPhone(p => ({ campaignId: p.campaignId }));
              }}
              value={
                newPhone.number
                  ? {
                      label: formatPhone(newPhone.number.toString()),
                      // borrow number & type from state
                      value: { ...newPhone }
                    }
                  : undefined
              }
            />
          </>
        );
      }
    };
  }, [purchasedPhones, newPhone, searchProviderNumbersQuery, alert]);

  const ActionCell = useMemo(() => {
    return ({ row, getValue }: CellContext<any, any>) => {
      const onClick = getValue();
      if (row.index < purchagesPhonesTableValues.length - 1) {
        return (
          <NoPaddingButton variant="inline" onClick={onClick}>
            Release
          </NoPaddingButton>
        );
      } else
        return (
          <NoPaddingButton variant="inline" onClick={onClick}>
            Create
          </NoPaddingButton>
        );
    };
  }, [purchagesPhonesTableValues]);

  const purchasedNumbersTableColumns = useMemo(() => {
    return [
      {
        id: "campaignId",
        header: "Campaign",
        accessorKey: "campaignId",
        cell: CampaignFieldCell
      },
      {
        id: "number",
        header: "Phone Number",
        accessorKey: "number",
        cell: PhoneFieldCell
      },
      {
        id: "status",
        header: "Status",
        accessorKey: "status",
        cell: ({ getValue }) => getValue()
      },
      {
        id: "action",
        header: "Action",
        accessorKey: "action",
        enableSorting: false,
        columnMaxWidth: 140,
        cell: ActionCell
      }
    ];
  }, [CampaignFieldCell, PhoneFieldCell, ActionCell]);

  // this form will not appear if client doesnt exist yet (its creation)
  if (!id) return null;

  const deleteAction = selectedNumber?.action === "delete";
  return (
    <>
      <Fieldset legend={"Internal Phone Numbers"}>
        <FormBuilder
          autosave
          values={{ clientNumbers: clientPhonesInitialValues }}
          onSubmit={updateClientNumbers}
        >
          <NestedForm
            name="clientNumbers"
            addable={false}
            hasOne // will always have just one table
            component={() => (
              <TableContainer>
                <Table
                  variant="data-grid"
                  addRows
                  columns={CLIENT_NUMBERS_TABLE_COLUMNS}
                  data={clientPhones || [{}]}
                  leftAligned
                  setData={setClientPhones}
                  skeleton={loadingClientPhones}
                />
              </TableContainer>
            )}
            condensed
          />
        </FormBuilder>
      </Fieldset>
      <Fieldset legend={"Purchased Numbers"}>
        <TableContainer>
          {/* this is a styled default table (values cant be modified) */}
          <Wrapper>
            <Table
              name="purchased numbers"
              leftAligned
              variant="data-grid"
              setData={() => null}
              skeleton={loading}
              data={purchagesPhonesTableValues || []}
              columns={purchasedNumbersTableColumns}
            />
          </Wrapper>
          <Note>
            * If want to buy a new number, type the areacode and select one
            number
          </Note>
          <Note>
            * If bought one and don&apos;t see it, type the whole number and
            select your number
          </Note>
        </TableContainer>
      </Fieldset>
      <ConfirmationModal
        message={`Are you sure you want to ${
          deleteAction ? "delete" : "release"
        } this number? ${formatPhone(selectedNumber?.number || "")}`}
        title={`${deleteAction ? "Delete client" : "Release provider"} number?`}
        confirmLabel={deleteAction ? "Delete" : "Release"}
        confirmIcon="trash2"
        open={!!selectedNumber}
        handleClose={() => setSelectedNumber(undefined)}
        onConfirm={() =>
          deleteAction
            ? deleteNumber(selectedNumber?.id || "")
            : releaseNumber(selectedNumber?.id || "")
        }
      />
    </>
  );
};

export default ClientNumbersForm;
