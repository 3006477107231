/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useEffect, useMemo, useState } from "react";

// third-party modules
import {
  Fieldset,
  NumberField,
  RichTextField,
  SearchField,
  SelectField,
  TextField,
  useGetValue,
  useSetFieldValue
} from "@onehq/anton";

// app modules
import {
  ClientQueryFilterFields,
  FilterOperation,
  ListQueryFilterFields,
  OptionFieldsFragment,
  ProjectStatus,
  ProjectStatusGroup,
  ProjectType,
  RestrictionOperation,
  useCustomCurrentUserQuery,
  useGetClientsListLazyQuery,
  useGetListsListLazyQuery,
  useGetProjectStatusOptionsQuery,
  useGetStatesQuery,
  UserType,
  useUsersByRoleLazyQuery
} from "../../../generated/graphql";
import { SelectFieldOptionType } from "../../../types";
import { addSpacesBetweenWords } from "../../../utils/helperFunctions";
import {
  formatClientList,
  formatListList,
  formatUserList,
  getGroupedSelectOptions,
  GroupedSelectOptions
} from "../../../utils/options";
import { DEFAULT_LIMIT } from "../../../constants";
import FloatingForm from "../../../components/Form/FloatingForm";
import { isAllowed } from "../../../utils";
import MessageForm from "../../stepper/message";

export const projectFormOmitValues = [
  "createdAt",
  "updatedAt",
  "timeZone",
  "projectTeamsCount",
  "projectTextersCount",
  "projectStatusGroup",
  "lastLoadedAt",
  "listStatus",
  "listStatusName",
  "listFiltersAttributes",
  "projectsCount",
  "rowsCount",
  "sheetsCount",
  "__typename",
  "endAtDate",
  "endAtTime",
  "startAtDate",
  "startAtTime",
  "timeZoneData",
  "media",
  "projectTeams",
  "projectTeamsAttributes",
  "projectTexters",
  "projectTextersAttributes",
  "projectCampaignsAttributes",
  "mediaUrl",
  "mediaFilename",
  "mediaFiletype",
  "mediaSize",
  "client",
  "list",
  "externalPhone",
  "phone",
  "manager",
  "serviceProviderGroup",
  "senderType",
  "textSummary",
  "phoneId",
  "defaultAreaCode",
  "isRecipientsAreaCode",
  "isMostCommonAreaCode",
  "providersPerMediaPosition",
  "projectPhonesAttributes",
  "previous",
  "campaign",
  "checkColumn",
  "mostUsedLinkedPhones",
  "state"
];

export interface ProjectTexterInput {
  userId: string;
  userName: string;
  id: string | null;
  quota: number | null;
}

interface ProjectFormFieldsProps {
  showOnlyBasicInfo?: boolean;
  hideLegend?: boolean;
}

const ProjectFormFields = ({
  hideLegend = true,
  showOnlyBasicInfo
}: ProjectFormFieldsProps) => {
  // hooks
  const getValue = useGetValue();
  // queries
  /* ### fetch current user and data for inputs ### */
  const { data: currentUser } = useCustomCurrentUserQuery();
  const { data: projectStatusList } = useGetProjectStatusOptionsQuery(); // project status from api
  const { data: stateOptions } = useGetStatesQuery(); // project status from api

  // lazy queries
  const [clientsQuery] = useGetClientsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const [listsQuery] = useGetListsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const [managersQuery] = useUsersByRoleLazyQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      userType: UserType.Client
    }
  });
  /* ###### */

  // constants
  const projectStatusValue: string = getValue("projectStatus");
  const listId = getValue("listId");

  /* ### state variables for inputs ### */
  const [projectStatusOptions, setProjectStatusOptions] = useState<
    Array<GroupedSelectOptions>
  >([]);

  const [defaultClientOptions, setDefaultClientOptions] = useState<
    SelectFieldOptionType[]
  >([]);

  const [defaultListOptions, setDefaultListOptions] = useState<
    SelectFieldOptionType[]
  >([]);

  const [defaultManagerOptions, setDefaultManagerOptions] = useState<
    SelectFieldOptionType[]
  >([]);

  const projectTypeOptions =
    Object.keys(ProjectType).map(item => ({
      label: addSpacesBetweenWords(item),
      value: item
    })) || [];
  /* ###### */

  // use effects
  // fetch project status options
  useEffect(() => {
    let projectStatusListAux: OptionFieldsFragment[] =
      (projectStatusList?.options.nodes as OptionFieldsFragment[]) || [];

    const validProjectStatuses =
      !![
        ProjectStatus.Approved.toString(),
        ProjectStatus.Submitted.toString()
      ].includes(projectStatusValue) &&
      currentUser?.currentUser.currentRole.name === UserType.Client
        ? [ProjectStatus.Approved, ProjectStatus.Submitted]
        : projectStatusListAux.map(p => p.name);

    projectStatusListAux = projectStatusListAux.filter(item =>
      // [values?.projectStatus, ...validProjectStatuses].includes(item?.name)
      [...validProjectStatuses].includes(item?.name)
    );
    const projectStatusOptionsAux =
      projectStatusListAux &&
      getGroupedSelectOptions(projectStatusListAux, ProjectStatusGroup);

    setProjectStatusOptions(projectStatusOptionsAux || []);
  }, [projectStatusValue, projectStatusList]);

  useEffect(() => {
    if (defaultManagerOptions.length > 0) return;
    if (currentUser?.currentUser.currentRole.name === UserType.Client) {
      managersQuery({})
        // TODO: remove
        // @ts-ignore
        .then(response => {
          const usersData = response.data?.usersByRole?.nodes || [];
          if (usersData.length > 0) {
            setDefaultManagerOptions(formatUserList(usersData));
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [currentUser, defaultManagerOptions, managersQuery]);

  useEffect(() => {
    clientsQuery({
      variables: {
        limit: DEFAULT_LIMIT
      }
    })
      // TODO: remove
      // @ts-ignore
      .then(response => {
        const clientsData = response.data?.clients?.nodes || [];
        setDefaultClientOptions(formatClientList(clientsData));
      })
      .catch(err => {
        console.log(err);
      });
    listsQuery({
      variables: {
        limit: DEFAULT_LIMIT
      }
    })
      // TODO: remove
      // @ts-ignore
      .then(response => {
        const listsData = response.data?.lists?.nodes || [];
        setDefaultListOptions(formatListList(listsData));
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  /* ###### */

  /* ########## Logic for Floating form ########## */
  // hooks
  const setFieldValue = useSetFieldValue();

  // state variables
  const [showFloatingForm, setShowFloatingForm] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);
  const [searchFieldInputs, setSearchFieldInputs] = useState({
    clientId: null,
    listId: null
  });
  const [variant, setVariant] = useState<"Client" | "List">("Client");

  // sets client in the form when creation is done
  useEffect(() => {
    if (submitForm && searchFieldInputs.clientId) {
      setFieldValue("searchClientId", searchFieldInputs.clientId);
      setSubmitForm(false);
    }
  }, [searchFieldInputs.clientId]);

  // sets list in the form when creation is done
  useEffect(() => {
    if (submitForm && searchFieldInputs.listId) {
      setFieldValue("searchListId", searchFieldInputs.listId);
      setSubmitForm(false);
    }
  }, [searchFieldInputs.listId]);

  // onAddNew events for searchFiels
  const onAddNewClient = () => {
    setVariant("Client");
    setShowFloatingForm(true);
  };
  const onAddNewList = () => {
    setVariant("List");
    setShowFloatingForm(true);
  };

  // can this user...
  const canCreateClients = isAllowed(
    currentUser,
    "Client",
    RestrictionOperation.Create
  );
  const canCreateLists = isAllowed(
    currentUser,
    "List",
    RestrictionOperation.Create
  );
  /* #################### */

  const ClientSelect = useMemo(() => {
    return (
      /* @ts-ignore */
      <SearchField
        label="Client"
        name="clientId"
        defaultOptions={defaultClientOptions}
        required
        loadOptions={(text: string) => {
          return clientsQuery({
            variables: {
              filters: [
                {
                  field: ClientQueryFilterFields.Name,
                  operation: FilterOperation.Like,
                  value: text
                }
              ]
            }
            // TODO: remove
          }).then(response =>
            formatClientList(response.data?.clients?.nodes || [])
          );
        }}
        onAddNew={canCreateClients ? () => onAddNewClient() : undefined}
        disabled={isComplete()}
      />
    );
  }, [defaultClientOptions, clientsQuery]);

  const ManagerSelect = useMemo(() => {
    return (
      /* @ts-ignore */
      <SearchField
        label="Manager"
        name="managerId"
        defaultOptions={defaultManagerOptions}
        required
        loadOptions={(text: string) =>
          managersQuery({
            variables: {
              name: text
            }
            // TODO: remove
            // @ts-ignore
          }).then(response =>
            formatUserList(response.data?.usersByRole.nodes || [])
          )
        }
        disabled={isComplete()}
      />
    );
  }, [defaultManagerOptions, managersQuery]);

  function checkProjectStatus() {
    return (
      projectStatusOptions?.[0]?.options?.[0]?.groupId !==
      ProjectStatusGroup.Setup
    );
  }

  function isComplete() {
    return projectStatusValue === ProjectStatus.Complete;
  }

  return (
    <>
      <Fieldset legend={!hideLegend && "Basic Info"}>
        <TextField
          label="Name"
          name="name"
          required
          disabled={isComplete()}
          span={8}
        />
        {/* @ts-ignore */}
        <SelectField
          label="Project Type"
          name="projectType"
          options={projectTypeOptions}
          required
          disabled={isComplete()}
          span={5}
        />
        {!showOnlyBasicInfo && (
          /* @ts-ignore */
          <SelectField
            label="Project Status"
            name="projectStatus"
            options={projectStatusOptions}
            disabled={checkProjectStatus()}
            required
            span={5}
          />
        )}
        <NumberField
          label="Number"
          name="number"
          disabled={isComplete()}
          span={2}
        />
        {/* @ts-ignore */}
        <SelectField
          label="State"
          name="stateId"
          options={stateOptions?.states.nodes}
          disabled={isComplete()}
          span={4}
        />
        {showOnlyBasicInfo ? (
          <>
            {ClientSelect}
            {ManagerSelect}
          </>
        ) : (
          <Fieldset>
            {ClientSelect}
            {/* @ts-ignore */}
            <SearchField
              label="List"
              name="listId"
              defaultOptions={defaultListOptions}
              disabled={isComplete()}
              loadOptions={(text: string) =>
                listsQuery({
                  variables: {
                    filters: [
                      {
                        field: ListQueryFilterFields.Name,
                        operation: FilterOperation.Like,
                        value: text
                      }
                    ]
                  }
                  // TODO: remove
                  // @ts-ignore
                }).then(response =>
                  formatListList(response.data?.lists?.nodes || [])
                )
              }
              onAddNew={canCreateLists ? () => onAddNewList() : undefined}
            />
            {ManagerSelect}
          </Fieldset>
        )}
        <RichTextField label="Note" name="note" disabled={isComplete()} />
      </Fieldset>
      <Fieldset legend={"Message"}>
        <MessageForm listId={listId?.value} />
      </Fieldset>
      <FloatingForm
        searchFieldInputs={searchFieldInputs}
        setSearchFieldInputs={setSearchFieldInputs}
        setSubmitForm={setSubmitForm}
        open={showFloatingForm}
        onClose={() => setShowFloatingForm(false)}
        variant={variant}
      />
    </>
  );
};

export default ProjectFormFields;
