export const ROWS_PATH = "rows";
export const rowColumnNumber = [
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
  "Ten",
  "Eleven",
  "Twelve",
  "Thirteen",
  "Fourteen",
  "Fifteen",
  "Sixteen",
  "Seventeen",
  "Eighteen",
  "Nineteen",
  "Twenty",
  "TwentyOne",
  "TwentyTwo",
  "TwentyThree",
  "TwentyFour",
  "TwentyFive",
  "TwentySix",
  "TwentySeven",
  "TwentyEight",
  "TwentyNine",
  "Thirty",
  "ThirtyOne",
  "ThirtyTwo",
  "ThirtyThree",
  "ThirtyFour",
  "ThirtyFive",
  "ThirtySix",
  "ThirtySeven",
  "ThirtyEight",
  "ThirtyNine",
  "Forty",
  "FortyOne",
  "FortyTwo",
  "FortyThree",
  "FortyFour",
  "FortyFive",
  "FortySix",
  "FortySeven",
  "FortyEight",
  "FortyNine",
  "Fifty"
];
