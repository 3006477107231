import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { WindowSizeContext } from "../../../routes";
import { SelectInput as _SelectInput } from "@onehq/anton";
import { TextStatus } from "../../../generated/graphql";
import { addSpacesBetweenWords } from "../../../utils";
import { ReceivedTypes } from "../../../constants";

const Root = styled.div<{ mobile?: boolean }>`
  font-size: ${props =>
    props.mobile ? props.theme.font.sm.size : props.theme.font.base.size};
  position: relative;
  align-items: center;
  width: 100%;
  z-index: 10;
  display: flex;
  ${props =>
    props.mobile &&
    css`
      margin: ${props.theme.space.spacing4};
    `}
`;

const MidSizeFont = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectContainer = styled.div<{ mobile?: boolean }>`
  display: flex;
  font-size: ${props => props.theme.font.sm.size};
  width: 100%;
  max-width: 209px;
  padding-left: 20px;
`;

const SelectInput = styled(_SelectInput)`
  width: 100%;
`;

const textStatuses: string[] = Object.values(TextStatus);
const textStatusOptions = textStatuses
  .filter(t => t !== "Received")
  .concat(ReceivedTypes)
  .sort()
  .map(el => ({
    value: el,
    label: addSpacesBetweenWords(el)
  }));

interface TextTypeSelectProps {
  selectedTextType: string;
  setSelectedTextType: React.Dispatch<React.SetStateAction<string>>;
}

const TextTypeSelect = ({
  selectedTextType,
  setSelectedTextType
}: TextTypeSelectProps) => {
  const windowObserver = useContext(WindowSizeContext);

  return (
    <Root mobile={windowObserver.isMobile}>
      <MidSizeFont>Type</MidSizeFont>
      <SelectContainer mobile={windowObserver.isMobile}>
        {/* @ts-ignore */}
        <SelectInput
          value={selectedTextType}
          options={textStatusOptions}
          name="textTypeSelector"
          onChange={(el: string) => setSelectedTextType(el)}
        />
      </SelectContainer>
    </Root>
  );
};

export default TextTypeSelect;
