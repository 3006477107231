// react modules
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// third-party modules
import { useLazyQuery } from "@apollo/client";
import {
  ActionItemProps,
  Card,
  ConfirmationModal,
  DotColor
} from "@onehq/anton";
import {
  ADD,
  BaseResource,
  GrowlAlertDispatch,
  Link,
  ResourceRoute,
  REMOVE,
  useDispatchGrowlContext
} from "@onehq/framework";
import { useGoogleLogin } from "@react-oauth/google";

// app modules
import {
  UpdateUserMutation,
  UpdateUserStatusDocument,
  useCustomCurrentUserQuery,
  useGetUserQuery,
  UserOperationOption,
  useUpdateUserMutation,
  useUpdateUserRefreshTokenMutation
} from "../../../generated/graphql";
import sections from "../sections";
import {} from "../../../types";
import {
  addAlert,
  addErrorAlert,
  addSpacesBetweenWords,
  formatDate
} from "../../../utils";

export interface UserAnchorProps {
  id: string;
  route: ResourceRoute;
}

const UserAnchor = ({ id, ...props }: UserAnchorProps) => {
  const alert: GrowlAlertDispatch = useDispatchGrowlContext();
  const navigateTo = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);

  const [showLockModal, setShowLockModal] = useState(false);
  const handleLockCloseModal = () => setShowLockModal(false);
  const handleShowLockModal = () => setShowLockModal(true);

  const { data: currentUser } = useCustomCurrentUserQuery();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const [runQuery] = useLazyQuery(UpdateUserStatusDocument);

  const login = useGoogleLogin({
    onSuccess: googleauth => {
      void updateUserRefreshTokenMutation(googleauth.code);
    },
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/drive",
    onError: () => {
      addErrorAlert(
        alert,
        "Missing Authorization",
        "Google Authorization failed"
      );
    }
  });

  const [updateUserMutation] = useUpdateUserMutation({
    onCompleted: (response: UpdateUserMutation) => {
      if (Object.keys(response?.updateUser?.errors || {}).length === 0) {
        alert({
          type: ADD,
          payload: {
            title: "Avatar changed",
            message: "The avatar has been changed successfully",
            variant: "success"
          }
        });
      }
    },
    onError: err => {
      console.error(err); // the error if that is the case
    }
  });

  const [updateUserRefreshToken] = useUpdateUserRefreshTokenMutation();

  const { data, loading } = useGetUserQuery({
    context: {
      headers: {
        isView: "true",
        resource: "User"
      }
    },
    variables: { id }
  });
  const user = data?.user;

  if (loading || !user) return <BaseResource.Anchor skeleton />;

  const isLocked = user.lockedAt != null;

  const updateUserStatusQuery = (operation: String) => {
    const idForAlert = Math.floor(Math.random() * 10000 + 1);
    alert({
      type: ADD,
      payload: {
        id: idForAlert,
        title: "Updating User Status",
        variant: "progress",
        indeterminate: true
      }
    });
    void runQuery({
      fetchPolicy: "no-cache",
      variables: { userId: id, operation },
      onCompleted: () => {
        alert({
          type: REMOVE,
          payload: {
            id: idForAlert,
            title: "",
            variant: "success"
          }
        });
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            id: idForAlert,
            message: `The user has been locked successfully`,
            variant: "success"
          }
        });
        navigateTo("/users");
      },
      onError: err => {
        alert({
          type: REMOVE,
          payload: {
            id: idForAlert,
            title: "",
            variant: "success"
          }
        });
        console.error(err); // the error if that is the case
      }
    });
  };

  const updateUserRefreshTokenMutation = (authcode: string) =>
    updateUserRefreshToken({
      fetchPolicy: "no-cache",
      variables: {
        userId: currentUser?.currentUser.id || "",
        authToken: authcode
      },
      // TODO: remove
      // @ts-ignore
      onCompleted: () => {
        addAlert(alert, "user.tokenupdated");
      },
      onError: err => {
        console.error(err); // the error if that is the case
      }
    });

  const changeAvatar = (image: any) => {
    const idForAlert = Math.floor(Math.random() * 10000 + 1);
    alert({
      type: ADD,
      payload: {
        id: idForAlert,
        title: "Uploading file...",
        variant: "progress",
        indeterminate: true
      }
    });
    const variables: any = {
      id: user.id
    };
    if (image) {
      variables.attributes = {
        mediaInput: image
      };
    } else {
      variables.attributes = {
        mediaDestroy: true
      };
    }
    updateUserMutation({ variables }).then(
      response => {
        alert({
          type: REMOVE,
          payload: {
            id: idForAlert,
            title: "",
            variant: "success"
          }
        });
        if (response.data?.updateUser?.resource?.mediaUrl) {
          user.mediaUrl = response.data.updateUser.resource.mediaUrl;
        }
      },
      error => {
        alert({
          type: REMOVE,
          payload: {
            id: idForAlert,
            title: "",
            variant: "success"
          }
        });
        console.log("err: ", error);
      }
    );
  };

  const kebabMenuItems = [
    {
      name: "Delete",
      icon: "trash2",
      color: "wine50",
      onClick: () => handleShowDeleteModal()
    },
    {
      name: !isLocked ? "Lock" : "Unlock",
      icon: !isLocked ? "lock" : "unlock",
      onClick: () => handleShowLockModal()
    },
    {
      name: "Sign in with Google",
      icon: "google",
      onClick: login,
      style: { fontFamily: "'Roboto', sans-serif" }
    }
  ] as ActionItemProps[];

  const infoCardData = [
    { label: "Name", value: user.name },
    {
      label: "Email",
      value: (
        <Link external target={"_blank"} href={`mailto:${user.email}`}>
          {" "}
          {user.email}{" "}
        </Link>
      )
    }
  ];

  user.team?.id &&
    infoCardData.push({
      label: "Team",
      value: <Link to={`/teams/${user.team?.id}`}> {user.team?.name} </Link>
    });

  user.client?.id &&
    infoCardData.push({
      label: "Client",
      value: <Link to={`/clients/${user.client?.id}`}>{user.client?.name}</Link>
    });

  infoCardData.push(
    {
      label: "Type",
      value: `${addSpacesBetweenWords(user.currentRole.name)} User`
    },
    {
      label: "Created at",
      value: formatDate(user.createdAt)
    },
    {
      label: "Updated at",
      value: formatDate(user.updatedAt)
    }
  );

  const infoCard = <Card.BasicInfo data={infoCardData} />;

  const userStatus = user.deletedAt
    ? "Deleted"
    : user.lockedAt
    ? "Locked"
    : "Active";

  const userStatusColor = (status: string): DotColor => {
    switch (status) {
      case "Active":
        return "pea70" as DotColor;
      case "Locked":
        return "royal50" as DotColor;
      case "Deleted":
        return "wine70" as DotColor;
      default:
        return "pea70" as DotColor;
    }
  };

  let avatarToken = "";

  user?.name?.split(" ").forEach((word: string) => {
    avatarToken += word[0];
  });

  const menuAnchorProps = {
    editLinks: sections,
    cards: [infoCard],
    name: user.name,
    subItem: "User",
    kebabMenuItems,
    type: userStatus,
    statusColor: userStatusColor(userStatus),
    avatarToken,
    onAvatarSubmit: changeAvatar,
    onEditPath: "general",
    onViewPath: "overview",
    photoUrl: user.mediaUrl
      ? `${process.env.REACT_APP_BACKEND_BASE_END_POINT}${user.mediaUrl}`
      : null,
    ...props
  };

  return (
    <>
      <BaseResource.Anchor {...menuAnchorProps} />
      <ConfirmationModal
        message="Are you sure you want to delete this user?"
        title="Delete User?"
        confirmLabel="Delete"
        confirmIcon="trash2"
        open={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        onConfirm={() => void updateUserStatusQuery(UserOperationOption.Delete)}
      />

      <ConfirmationModal
        message={
          "Are you sure you want to " +
          (!isLocked ? "lock" : "unlock") +
          " this user?"
        }
        title={!isLocked ? "Lock User?" : "Unlock User?"}
        confirmLabel={!isLocked ? "Lock" : "Unlock"}
        confirmIcon={!isLocked ? "lock" : "unlock"}
        open={showLockModal}
        handleClose={handleLockCloseModal}
        onConfirm={() =>
          void updateUserStatusQuery(
            !isLocked ? UserOperationOption.Lock : UserOperationOption.Unlock
          )
        }
      />
    </>
  );
};

export default UserAnchor;
