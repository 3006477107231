import { DotColor } from "@onehq/anton";
import { ListStatus } from "../generated/graphql";

export default function listStatusColor(status: ListStatus): DotColor {
  switch (status) {
    case ListStatus.Error:
    case ListStatus.ErrorApp:
    case ListStatus.ErrorDbError:
    case ListStatus.ErrorFileNotFound:
    case ListStatus.ErrorMultiplePhoneNumbersSelected:
    case ListStatus.ErrorSheetExceedsMaxColumns:
    case ListStatus.ErrorSheetIncludesInvalidData:
    case ListStatus.ErrorSheetIsInUseByAProject:
    case ListStatus.ErrorSignInWithGoogle:
    case ListStatus.ErrorTheFileIsNotASheet:
    case ListStatus.ErrorUnableToAccessTheSheet:
    case ListStatus.ErrorUnableToSelectAPhoneNumber:
      return "wine40" as DotColor;
    case ListStatus.Loaded:
      return "royal50" as DotColor;
    case ListStatus.AwaitingLoad:
    case ListStatus.Loading:
      return "sun60" as DotColor;
    case ListStatus.Submitted:
      return "pea60" as DotColor;
    default:
      return "iris60" as DotColor;
  }
}

export function listStatusBadgeColor(status: ListStatus): DotColor {
  switch (status) {
    case ListStatus.Error:
    case ListStatus.ErrorApp:
    case ListStatus.ErrorDbError:
    case ListStatus.ErrorFileNotFound:
    case ListStatus.ErrorMultiplePhoneNumbersSelected:
    case ListStatus.ErrorSheetExceedsMaxColumns:
    case ListStatus.ErrorSheetIncludesInvalidData:
    case ListStatus.ErrorSheetIsInUseByAProject:
    case ListStatus.ErrorSignInWithGoogle:
    case ListStatus.ErrorTheFileIsNotASheet:
    case ListStatus.ErrorUnableToAccessTheSheet:
    case ListStatus.ErrorUnableToSelectAPhoneNumber:
      return "red" as DotColor;
    case ListStatus.Loaded:
      return "blue" as DotColor;
    case ListStatus.Loading:
      return "yellow" as DotColor;
    case ListStatus.Submitted:
      return "green" as DotColor;
    default:
      return "purple" as DotColor;
  }
}
