import { AlertTheme } from "@onehq/anton";
import { GrowlAlertDispatch, ADD, REMOVE } from "@onehq/framework";

// TODO: We should use internationalization to get titles
export const addAlert = (
  callAddAlert: GrowlAlertDispatch,
  alertContentId: string,
  alertID?: number,
  variant?: AlertTheme
) => {
  const { title, message } = getAlertContent(alertContentId);
  const indeterminate = !!alertID;

  callAddAlert({
    type: ADD,
    payload: {
      id: alertID || Math.floor(Math.random() * 10000 + 1),
      title,
      message,
      variant: indeterminate ? "progress" : variant || "success",
      indeterminate
    }
  });
};

export const addErrorAlert = (
  callAddAlert: GrowlAlertDispatch,
  title: string,
  message: string,
  id?: number
) => {
  callAddAlert({
    type: ADD,
    payload: { id, title, message, variant: "error" }
  });
};

export const addSuccessAlert = (
  callAddAlert: GrowlAlertDispatch,
  title: string,
  message: string,
  id?: number
) => {
  callAddAlert({
    type: ADD,
    payload: { id, title, message, variant: "success" }
  });
};

export const removeAlert = (
  callRemoveAlert?: GrowlAlertDispatch,
  alertID?: number
) => {
  if (typeof callRemoveAlert === "function" && alertID) {
    callRemoveAlert({
      type: REMOVE,
      payload: { id: alertID, title: "", variant: "success" }
    });
  }
};

// TODO: cleanup from user and project Form
export const showErrorMessage = (data: Object, setError: Function) => {
  if (data && Object.keys(data).length > 0) {
    Object.entries(data).forEach(([key, value]) => {
      switch (key) {
        // Users cases
        case "lockedAt":
          setError("lockedAtDate", { message: value });
          setError("lockedAtTime", { message: value });
          break;
        case "deletedAt":
          setError("deletedAtDate", { message: value });
          setError("deletedAtTime", { message: value });
          break;

        // Project cases
        case "startAt":
          setError("startAtDate", { message: value });
          setError("startAtTime", { message: value });
          break;
        case "endAt":
          setError("endAtDate", { message: value });
          setError("endAtTime", { message: value });
          break;

        // Other cases
        default:
          setError(key, { message: value });
          return;
      }
    });
  }
};

export interface AlertContent {
  id: string;
  title: string;
  message?: string;
}
// WIP - Internationalization
// TODO: We should use internationalization file
const ALERT_CONTENT: AlertContent[] = [
  { id: "client.save", title: "Saving client..." },
  { id: "client.create", title: "Creating client..." },
  {
    id: "row.created",
    title: "All changes saved",
    message: "The row has been created successfully"
  },
  {
    id: "user.tokenupdated",
    title: "All changes saved",
    message: "Google Authorization saved"
  },
  {
    id: "avatar.changed",
    title: "Avatar changed",
    message: "The avatar has been changed successfully"
  },
  {
    id: "file.uploading",
    title: "Uploading file..."
  },
  { id: "text_default.save", title: "Saving text default..." },
  {
    id: "text_default.changed",
    title: "All changes saved",
    message: "The text default has been edited successfully"
  },
  { id: "organization.save", title: "Saving organization..." },
  {
    id: "organization.changed",
    title: "All changes saved",
    message: "The organization has been edited successfully"
  }
];

const getAlertContent = (alertContentId: string) => {
  const alertContent = ALERT_CONTENT.find(aC => aC.id === alertContentId);

  return { title: alertContent?.title || "", message: alertContent?.message };
};
