/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { badgeCell, BadgeTheme, Table } from "@onehq/anton";

import { LISTS_PATH } from "../../constants";
import { ListFieldsListFragment } from "../../generated/graphql";
import { Link } from "@onehq/framework";
import {
  addSpacesBetweenWords,
  formatDate,
  listStatusBadgeColor
} from "../../utils";
import { BaseTableProps } from "../../types";

export type ListTableProps = BaseTableProps<
  ListFieldsListFragment & { googleSheetKey: string }
>;

export const ListTableColumns = [
  {
    id: "name",
    header: "Name",
    accessorKey: "name"
  },
  {
    id: "sheet",
    header: "Spreadsheet",
    accessorKey: "sheet",
    enableSorting: false
  },
  {
    id: "lastLoadedAt",
    header: "Last Loaded At",
    accessorKey: "lastLoadedAt"
  },
  {
    id: "listStatusId",
    header: "Status",
    accessorKey: "listStatus"
  },
  // {
  //   Header: "Rows Count",
  //   accessor: "rowsCount",
  //   type: "number" as CellType,
  //   enableSorting: false
  // },
  // {
  //   Header: "Projects Count",
  //   accessor: "projectsCount",
  //   type: "number" as CellType,
  //   enableSorting: false
  // },
  {
    id: "createdAt",
    header: "Created At",
    accessorKey: "createdAt"
  },
  {
    id: "updatedAt",
    header: "Updated At",
    accessorKey: "updatedAt"
  }
];

export const listTableDataFormatter = (
  data?: Array<ListFieldsListFragment & { googleSheetKey: string }>
) => [
  ...(data?.map(item => {
    const {
      id,
      name,
      googleSheetKey,
      lastLoadedAt,
      listStatus,
      // projectsCount,
      // rowsCount,
      createdAt,
      updatedAt
    } = item;

    return {
      name: <Link to={`/${LISTS_PATH}/${id}/overview`}>{name}</Link>,
      sheet: (
        <Link
          external
          target={"_blank"}
          href={`https://docs.google.com/spreadsheets/d/${googleSheetKey}`}
        >
          Go to spreadsheet
        </Link>
      ),
      // rowsCount: formatNumber(rowsCount),
      // projectsCount: formatNumber(projectsCount),
      createdAt: formatDate(createdAt),
      updatedAt: formatDate(updatedAt),
      lastLoadedAt: formatDate(lastLoadedAt),
      listStatus: badgeCell({
        value: addSpacesBetweenWords(listStatus),
        color: listStatusBadgeColor(listStatus) as BadgeTheme
      })
    };
  }) || [])
];

const ListTable = ({ data = [], ...props }: ListTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={ListTableColumns}
      data={listTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default ListTable;
