import {
  ProjectQueryFilterFields,
  FilterOperation,
  GetProjectsListCsvDocument,
  TextQueryFilterFields,
  GetTextsListCsvDocument
} from "../generated/graphql";
import { BaseFilterTabsProps } from "../types";
import { PAGE_SIZE } from "../utils";

export const USERS_PATH = "users";

export const USER_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  TEXTS: "Texts",
  PROJECTS: "Projects"
};

export const USER_VIEW_LINKS = [
  {
    id: USER_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    text: USER_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    name: USER_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    path: "/overview"
  },
  {
    id: USER_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    text: USER_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    name: USER_MENU_ANCHOR_VIEW_LINKS.TEXTS,
    path: "/texts"
  },
  {
    id: USER_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    text: USER_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    name: USER_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    path: "/projects"
  }
];

type UserFilterTabsProps = BaseFilterTabsProps & {
  projectTexters?: string[];
};

export const userTabFilters = ({
  id,
  projectTexters = [],
  selectedTab
}: UserFilterTabsProps) => {
  const filterOptions = {
    [USER_MENU_ANCHOR_VIEW_LINKS.PROJECTS]: {
      variables: {
        first: PAGE_SIZE,
        filters: projectTexters?.length
          ? [
              {
                field: ProjectQueryFilterFields.Id,
                operation: FilterOperation.In,
                arrayValues: projectTexters
              }
            ]
          : []
      },
      listDocument: GetProjectsListCsvDocument
    },
    [USER_MENU_ANCHOR_VIEW_LINKS.TEXTS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: TextQueryFilterFields.TexterId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetTextsListCsvDocument
    }
  };
  return filterOptions[selectedTab];
};
