import {
  CustomCurrentUserQuery,
  RestrictionOperation
} from "../generated/graphql";

export const isAllowed = (
  currentUser: CustomCurrentUserQuery | undefined,
  restrictionResourceName: string,
  restrictionOperation: RestrictionOperation
) => {
  if (!currentUser) return false; // currentUser not available yet

  const hasRestriction = currentUser.currentUser.restrictions.some(
    r =>
      r.resource.name === restrictionResourceName &&
      r.restrictionOperation === restrictionOperation
  );

  return !hasRestriction;
};
