// react modules
import React, { useCallback } from "react";
// third-party modules
import { Fieldset, NestedForm } from "@onehq/anton";
import {
  useGetCarrierOptionsQuery,
  useGetStatesQuery
} from "../../generated/graphql";
import ListFilterFields from "./ListFilterFields";
import { BasicOption as Option } from "../../types";
import styled from "styled-components";
import { stringsToOptions } from "../../utils";
import { ListFilterResource, message } from "./utils";

const Root = styled.div`
  width: 100%;

  & #inner-field {
    align-self: flex-end;
  }
`;

export interface ListFiltersFormProps {
  resource: ListFilterResource;
}

const ListFiltersForm = ({ resource }: ListFiltersFormProps) => {
  const { data: statesData } = useGetStatesQuery();
  const { data: carriersData } = useGetCarrierOptionsQuery();

  const Fields = useCallback(() => {
    const carrierNodes = carriersData?.options.nodes || [];
    const carriers = carrierNodes.map(c => c?.name) as string[];
    const carrierOptions = stringsToOptions(carriers, true, str => str);
    const stateList = (statesData?.states.nodes as Option[]) || [];
    const states = stateList.map(({ label, value }) => ({ label, value }));
    const stateOptions = states.sort((a, b) => a.label.localeCompare(b.label));
    return <ListFilterFields states={stateOptions} carriers={carrierOptions} />;
  }, [carriersData, statesData]);

  return (
    <Root>
      <Fieldset>
        <NestedForm
          name="listFiltersAttributes"
          component={Fields}
          addable={false}
          condensed
        />
      </Fieldset>
      <Fieldset>
        <span style={{ margin: "0 8px" }}>{message(resource)}</span>
      </Fieldset>
    </Root>
  );
};

export default ListFiltersForm;
