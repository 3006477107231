import React from "react";
import styled from "styled-components";
import { Link } from "@onehq/framework";
import { useResizeObserver } from "@onehq/hooks";
import { breakpointsNum } from "@onehq/style";
import Logo from "../../assets/imgs/authenticationLogo.png";
import { privacyContent } from "../privacy/PrivacyShow";

export const StyledPageContainer = styled.div<{ isMobile?: boolean }>`
  width: auto;
  height: 100%;
  text-align: justify;
  background-color: #ffffff;
  padding: ${props => (props.isMobile ? "20px" : "100px")};
  font-size: 15px;
  overflow-y: auto;
`;

const HomePage = () => {
  const { ref: sizeRef, width: w = breakpointsNum.large } =
    useResizeObserver<HTMLDivElement>();

  let width: "small" | "medium" | "large";
  if (w <= breakpointsNum.small) {
    width = "small";
  } else if (w <= breakpointsNum.medium) {
    width = "medium";
  } else {
    width = "large";
  }

  const isMobile = width === "small";
  const homePageComponent = (
    <>
      <img
        src={Logo}
        width="200"
        height="200"
        alt="ReadyGOP Logo"
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      />
      <br />
      {`ABOUT US`}
      <br />
      <br />
      {`Better, Faster, Affordable. At our core, we are a research and analytics company. 
        Our expertise is collecting and deploying accurate intelligence. To be efficient and accurate, you need 
        technology that has mastered the use of different communication channels to obtain the response and outcome you need.
        With over 30 years of experience in political, nonprofit, and corporate campaigns, ReadyGOP knows how to help your 
        campaign evaluate any situation and execute on a strategy that is guided by accurate, fast, and affordable research 
        and intelligence to ensure you are always using the right tool for the job.`}
      <br />
      <br />

      <Link to="../login">Login</Link>
      <span> | </span>
      <Link to="../signup">Sign Up</Link>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      {privacyContent}
    </>
  );

  return (
    <StyledPageContainer ref={sizeRef} isMobile={isMobile}>
      {homePageComponent}
    </StyledPageContainer>
  );
};

export default HomePage;
