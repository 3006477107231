import React, { useEffect, useMemo, useState } from "react";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import {
  ADD,
  GrowlAlertDispatch,
  useDispatchGrowlContext
} from "@onehq/framework";
import Userfront from "@userfront/core";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { addSpacesBetweenWords, removeAlert } from "./utils";

const MAX_ALERTS_ON_DISPLAY = 5;

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-floating-promises
Userfront.init(process.env.REACT_APP_USERFRONT_TENANT_ID);

export const accessToken = Userfront.tokens.accessToken;

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_BACKEND_END_POINT,
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`
  }
});

const errorHandlingLink = (
  createAlert: Function,
  alert: GrowlAlertDispatch
) => {
  return onError(({ graphQLErrors, networkError, operation }) => {
    const operationName = addSpacesBetweenWords(
      operation.operationName.charAt(0).toUpperCase() +
        operation.operationName.slice(1)
    );

    if (graphQLErrors) {
      graphQLErrors.forEach(err => createAlert(operationName, err.message));
    }

    if (networkError) {
      if (networkError.message.indexOf("Received status code 401") > 0) {
        Userfront.logout({ redirect: "/login" }).catch(error => {
          console.log(error);
        });
      } else {
        typeof alert === "function" &&
          alert({
            type: ADD,
            payload: {
              title: "Network Error",
              message: JSON.stringify(networkError).slice(0, 200),
              variant: "warning"
            }
          });
      }
    }
  });
};

const ClientProvider = ({ children }: any) => {
  const alert: GrowlAlertDispatch = useDispatchGrowlContext();
  // a FIFO list of alerts
  const [alerts, setAlerts] = useState<number[]>([]);

  useEffect(() => {
    if (alerts.length > MAX_ALERTS_ON_DISPLAY) {
      // if the list have more than MAX, delete all but the last MAX alerts.
      const toDelete = alerts.slice(0, -1 * MAX_ALERTS_ON_DISPLAY);
      toDelete.forEach(d => removeAlert(alert, d));

      // the remaining MAX alerts will be the new list.
      setAlerts(old => old.slice(-1 * MAX_ALERTS_ON_DISPLAY));
    }
  }, [alerts, alert]);

  const createAlert = (title: string, message: string) => {
    const id = Math.floor(Math.random() * 10000 + 1);
    setAlerts(old => [...old, id]);

    typeof alert === "function" &&
      alert({
        type: ADD,
        payload: {
          id,
          title,
          message: JSON.stringify(message).slice(0, 200),
          variant: "error"
        }
      });
  };

  const client = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache(),
        link: errorHandlingLink(createAlert, alert).concat(uploadLink)
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ClientProvider;
