import { Heading, Tooltip } from "@onehq/anton";
import React, { ReactNode } from "react";
import { theme } from "@onehq/style";
import styled from "styled-components";

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding: 0px;
  margin: ${theme.space.spacing8} ${theme.space.spacing8};
`;

const boldTitle = theme.font.sm.type.boldTitle;
const StyledHeading = styled(Heading)`
  font-size: ${theme.font.sm.size};
  line-height: ${boldTitle.lineHeight};
  letter-spacing: ${boldTitle.letterSpacing};
  font-style: ${boldTitle.fontStyle};
  font-weight: ${boldTitle.fontWeight};
  letter-spacing: 0.7px;
  text-transform: uppercase;
  color: ${theme.color.neutral40};
`;

interface PhoneDetailProps {
  header: string;
  value?: React.ReactNode;
}
const PhoneDetail = ({ header, value }: PhoneDetailProps) => (
  <DetailsWrapper>
    {header && <StyledHeading>{header}</StyledHeading>}
    {value}
  </DetailsWrapper>
);

interface PhoneTooltipProps {
  children?: ReactNode;
  description?: string;
}

const PhoneTooltip = ({ children, description }: PhoneTooltipProps) => (
  <Tooltip
    content={
      <PhoneDetail header={"Description"} value={description || "None"} />
    }
    placement={"left"}
    delay={0}
    offset={[0, 18]}
    color="neutral"
  >
    <div style={{ display: "inline-block", margin: 1 }}>
      <span>{children}</span>
    </div>
  </Tooltip>
);

export default PhoneTooltip;
