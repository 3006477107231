import React from "react";
import styled from "styled-components";
import { NumberField, TextField, HiddenField } from "@onehq/anton";
import {
  DeliveryType,
  ProjectStatusGroup,
  UserType
} from "../../../generated/graphql";

const StyledTextField = styled(TextField)`
  padding-left: 0;
`;

interface ProjectTexterFormProps {
  userType?: UserType;
  skeleton?: boolean;
  isRequired?: boolean;
  deliveryType?: DeliveryType;
  projectStatusGroup?: string;
}

const ProjectTexterFormFields = ({
  userType,
  skeleton,
  isRequired,
  deliveryType,
  projectStatusGroup
}: ProjectTexterFormProps) => {
  return (
    <>
      <StyledTextField name="userName" unstyled disabled span={4} label="" />
      <HiddenField name="userId" />
      <HiddenField name="texterId" />
      <NumberField
        label="Quota"
        name={"quota"}
        disabled={
          (userType !== UserType.TeamLead &&
            deliveryType === DeliveryType.External) ||
          (userType !== UserType.Client &&
            deliveryType === DeliveryType.Internal) ||
          projectStatusGroup === ProjectStatusGroup.Ready
        }
        skeleton={skeleton}
        span={4}
        required={isRequired}
        maxLength={10}
      />
    </>
  );
};

export default ProjectTexterFormFields;
