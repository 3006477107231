/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { Table } from "@onehq/anton";
import { Link } from "@onehq/framework";

import {
  CustomCurrentUserQuery,
  UserListFieldsFragment
} from "../../generated/graphql";
import { BaseTableProps } from "../../types";
import { USERS_PATH } from "../../constants";
import { formatDate, addSpacesBetweenWords } from "../../utils";

export interface UserTableProps extends BaseTableProps<UserListFieldsFragment> {
  currentUser?: CustomCurrentUserQuery["currentUser"];
}

export const UserTableColumns = [
  {
    id: "name",
    header: "Name",
    accessorKey: "name"
  },
  {
    id: "email",
    header: "Email",
    accessorKey: "email"
  },
  {
    id: "client",
    header: "Client",
    accessorKey: "client",
    enableSorting: false
  },
  {
    id: "team",
    header: "Team",
    accessorKey: "team",
    enableSorting: false
  },
  {
    id: "userTypeId",
    header: "Type",
    accessorKey: "userType",
    enableSorting: false
  },
  {
    id: "createdAt",
    header: "Created At",
    accessorKey: "createdAt"
  },
  {
    id: "lockedAt",
    header: "Locked At",
    accessorKey: "lockedAt"
  },
  {
    header: "Userfront ID",
    accessorKey: "userfrontId",
    enableSorting: false
  }
];

export const userTableDataFormatter = (
  data?: Array<UserListFieldsFragment>,
  currentUser?: any
) => {
  return (
    data?.map((item: UserListFieldsFragment) => ({
      name:
        currentUser?.userType === "Client" &&
        currentUser?.name !== item.name ? (
          item.name
        ) : (
          <Link to={`/${USERS_PATH}/${item.id}/overview`}>{item.name}</Link>
        ),
      email: (
        <Link external target={"_blank"} href={`mailto:${item.email}`}>
          {item.email}
        </Link>
      ),
      userfrontId: item.userfrontId,
      team: item.team?.name,
      client: (
        <Link to={`/clients/${item.client?.id}/overview`}>
          {item.client?.name}
        </Link>
      ),
      createdAt: formatDate(item.createdAt),
      lockedAt: formatDate(item.lockedAt),
      userType: addSpacesBetweenWords(item.type.name)
    })) || []
  );
};

const UserTable = ({ data = [], ...props }: UserTableProps) => {
  const loading = props.loading;
  const externalSort = props.externalSort;

  return (
    <Table
      columns={UserTableColumns}
      data={userTableDataFormatter(data)}
      skeleton={loading}
      externalSort={externalSort}
    />
  );
};

export default UserTable;
