/* eslint-disable react-hooks/exhaustive-deps */
// react modules
import React, { useMemo, useState, useEffect } from "react";

// third-party modules
import { Button, Fieldset, SearchField, useWatchContext } from "@onehq/anton";
import {
  GrowlAlertDispatch,
  useDispatchGrowlContext,
  ADD,
  REMOVE
} from "@onehq/framework";
import { theme } from "@onehq/style";
import { useGoogleLogin } from "@react-oauth/google";

// app modules
import {
  FilterOperation,
  ListQueryFilterFields,
  ListStatus,
  RestrictionOperation,
  useCustomCurrentUserQuery,
  useGetListsListLazyQuery,
  useLoadListLazyQuery,
  useUpdateUserRefreshTokenMutation
} from "../../generated/graphql";
import { SelectFieldOptionType } from "../../types";
import { DEFAULT_LIMIT } from "../../constants";
import { formatListList, isAllowed } from "../../utils";
import styled from "styled-components";
import FloatingForm from "../../components/Form/FloatingForm";

const Root = styled(Fieldset)`
  align-items: flex-end;
`;

const StyledButton = styled(Button)`
  margin: ${theme.space.spacing8};
`;

const ListLoader = () => {
  // variable states
  const [submitForm, setSubmitForm] = useState(false); // true when list creation is done in floating form
  const [showFloatingForm, setShowFloatingForm] = useState(false);
  const [defaultListOptions, setDefaultListOptions] = useState<
    SelectFieldOptionType[]
  >([]);

  // alert variables
  const alert: GrowlAlertDispatch = useDispatchGrowlContext();
  const idForAlert = useMemo(() => Math.floor(Math.random() * 10000 + 1), []);

  // query hooks
  const { data: currentUser, refetch: refetchUserQuery } =
    useCustomCurrentUserQuery();
  const [listsQuery] = useGetListsListLazyQuery({
    fetchPolicy: "cache-and-network"
  });
  const [loadListQuery] = useLoadListLazyQuery();

  // fetch lists (list of Lists) on render
  useEffect(() => {
    listsQuery({ variables: { limit: DEFAULT_LIMIT } })
      .then(response => {
        const listsData = response.data?.lists?.nodes || [];
        setDefaultListOptions(formatListList(listsData));
      })
      .catch(err => console.log(err));
  }, []);

  // loads the list that was just created
  useEffect(() => {
    if (submitForm) {
      callLoadList();
      setSubmitForm(false);
    }
  }, [submitForm]);

  const onAddNewList = () => {
    setShowFloatingForm(true);
  };

  // constant to handle "Loading" alert
  const addLoadAlert = () =>
    alert({
      type: ADD,
      payload: {
        id: idForAlert,
        title: "Loading list...",
        variant: "progress",
        indeterminate: true
      }
    });

  const removeLoadAlert = () =>
    alert({
      type: REMOVE,
      payload: { id: idForAlert, title: "", variant: "success" }
    });

  const selectedList = useWatchContext("listId");

  const loadList = () => {
    const selectedListId = selectedList.value;
    if (selectedListId && currentUser) {
      loadListQuery({
        variables: {
          listId: selectedListId,
          userId: currentUser.currentUser.id,
          async: false
        }
      })
        .then(result => {
          removeLoadAlert();
          if (result.error && Object.keys(result.error).length > 0) {
            alert({
              type: ADD,
              payload: {
                title: "Error loading list",
                message: result.error.message,
                variant: "error"
              }
            });
          } else {
            alert({
              type: ADD,
              payload: {
                title: "List loaded successfully",
                message: "List loaded successfully",
                variant: "success"
              }
            });
          }
        })
        .catch(e => {
          removeLoadAlert();
          alert({
            type: ADD,
            payload: {
              title: "Error loading list",
              message:
                "There was an error loading the list, please, try again later",
              variant: "error"
            }
          });
          console.log(e);
        });
    } else {
      removeLoadAlert();
    }
  };

  // refetch currentUser data (looking for a refreshToken) if not, ask user to login to google, then load the list
  const callLoadList = () => {
    addLoadAlert();
    refetchUserQuery().then(
      userdata => {
        if (userdata.data.currentUser.hasRefreshToken) {
          void loadList();
        } else {
          removeLoadAlert();
          alert({
            type: ADD,
            payload: {
              title: "Missing Authorization",
              message: "Login to your Google Account to continue",
              variant: "error"
            }
          });
          login();
        }
      },
      err => {
        removeLoadAlert();
        console.log("error refetching user data: ", err);
      }
    );
  };

  // google login logic
  const [updateUserRefreshToken] = useUpdateUserRefreshTokenMutation();
  const updateUserRefreshTokenMutation = (authcode: string) =>
    updateUserRefreshToken({
      fetchPolicy: "no-cache",
      variables: {
        userId: currentUser?.currentUser.id || "",
        authToken: authcode
      },
      onCompleted: () => {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: "Google Authorization saved",
            variant: "success"
          }
        });
      },
      onError: err => {
        alert({
          type: ADD,
          payload: {
            title: "Failed",
            message: err.message,
            variant: "error"
          }
        });
        console.error(err); // the error if that is the case
      }
    });

  // https://www.npmjs.com/package/@react-oauth/google
  const login = useGoogleLogin({
    onSuccess: googleauth =>
      void updateUserRefreshTokenMutation(googleauth.code),
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/drive",
    onError: () => {
      alert({
        type: ADD,
        payload: {
          title: "Missing Authorization",
          message: "Google Authorization failed",
          variant: "error"
        }
      });
    }
  });

  // checks if current user can create lists
  const canCreateLists = isAllowed(
    currentUser,
    "List",
    RestrictionOperation.Create
  );

  return (
    <Root>
      {/* @ts-ignore */}
      <SearchField
        label="List"
        name="listId"
        defaultOptions={defaultListOptions}
        loadOptions={(text: string) => {
          return listsQuery({
            variables: {
              filters: [
                {
                  field: ListQueryFilterFields.Name,
                  operation: FilterOperation.Like,
                  value: text
                },
                {
                  field: ListQueryFilterFields.ListStatusId,
                  operation: FilterOperation.Like,
                  value: `ListStatus::::${ListStatus.Loaded}`
                }
              ]
            }
          }).then(response => {
            return formatListList(response.data?.lists?.nodes || []);
          });
        }}
        onAddNew={canCreateLists ? () => onAddNewList() : undefined}
        required
      />
      <StyledButton
        variant="secondary"
        disabled={!selectedList}
        onClick={callLoadList}
        icon="loader"
      >
        Load List
      </StyledButton>
      <FloatingForm
        open={showFloatingForm}
        onClose={() => setShowFloatingForm(false)}
        variant="List"
        setSubmitForm={setSubmitForm}
        attachNewValueToInput
      />
    </Root>
  );
};

export default ListLoader;
