import { Route } from "@onehq/framework";
import Stepper from "./stepper";
import { STEPPER_PATH } from "../../constants";

export const StepperRoute = new Route({
  name: "Stepper",
  path: `${STEPPER_PATH}/`,
  component: Stepper
});

export const StepperCreatedRoute = new Route({
  name: "Stepper - Created",
  path: `${STEPPER_PATH}/:id`,
  component: Stepper
});
