import React, { useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Alert,
  Fieldset,
  Authentication,
  TextField,
  FormBuilder,
  Button,
  ThemeProvider
} from "@onehq/anton";
import { ErrorResponse } from "../../types";
import Userfront from "@userfront/core";
import Logo from "../../assets/imgs/authenticationLogo.png";
import { PageContainer, ButtonsWrapper } from "../../components/authStyles";

interface PasswordResetFormAttributes {
  password: string;
  passwordCheck: string;
}

const AuthBase = Authentication.Base;

// @ts-ignore
const ResponsiveTextField = props => <TextField span={24} {...props} />;

const PasswordResetPage = () => {
  const navigateTo = useNavigate();

  const [error, setError] = useState<String>("");
  const [success, setSuccess] = useState<String>("");
  const refForm = useRef<HTMLFormElement>(null);

  const [searchParams] = useSearchParams();
  const urlUuid = searchParams.get("uuid");
  const urlToken = searchParams.get("token");

  const handleSubmit = (attr: PasswordResetFormAttributes) => {
    if (attr.password !== attr.passwordCheck) {
      setError("Password must match");
      setTimeout(function () {
        setError("");
      }, 3000);
    } else {
      Userfront.resetPassword({
        password: attr.password,
        /* @ts-ignore */
        uuid: urlUuid,
        /* @ts-ignore */
        token: urlToken
      })
        .then(res => {
          console.log(res);
          setSuccess("Password changed successfully");
        })
        .catch((error: ErrorResponse) => {
          setError(error.message ? error.message : "");
          setTimeout(function () {
            setError("");
          }, 3000);
        });
    }
  };

  const onSubmit = () => {
    refForm?.current?.submit();
  };

  const formComponent = (
    <FormBuilder
      ref={refForm}
      autosave={false}
      type="dialogForm"
      values={{
        password: "",
        passwordCheck: ""
      }}
      // eslint-disable-next-line @typescript-eslint/require-await
      onSubmit={async (props: PasswordResetFormAttributes) => {
        handleSubmit(props);
      }}
    >
      <Fieldset>
        <ResponsiveTextField type="password" label="Password" name="password" />
        <ResponsiveTextField
          type="password"
          label="Repeat Password"
          name="passwordCheck"
        />
        <ButtonsWrapper>
          <Button
            variant="primary"
            fullWidth
            onClick={() => onSubmit()}
            data-testid={"button"}
          >
            Submit
          </Button>
          <Button
            variant="inline"
            onClick={() => navigateTo("/login")}
            data-testid={"button"}
          >
            Go back to login
          </Button>
        </ButtonsWrapper>
      </Fieldset>
    </FormBuilder>
  );

  return (
    <ThemeProvider>
      <PageContainer>
        <AuthBase
          headerTitle={"Set your new password"}
          logoImg={Logo}
          logoStyle={{ height: "100 px" }}
        >
          {formComponent}
          {error && <Alert title="error" message={error} variant="warning" />}
          {success && (
            <Alert
              title="Password Changed"
              message={success}
              variant="success"
            />
          )}
        </AuthBase>
      </PageContainer>
    </ThemeProvider>
  );
};

export default PasswordResetPage;
