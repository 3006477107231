import { Route } from "@onehq/framework";
import HomePage from "./homepage";

const route = new Route({
  name: "Homepage",
  path: "",
  component: HomePage
});
// TODO: delete this section after new home page implementation

export default route;
