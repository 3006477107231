import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ADD, useDispatchGrowlContext } from "@onehq/framework";
import TextForm from "../sections/TextForm";
import {
  useCreateTextMutation,
  CreateTextMutation,
  useNewTextQuery
} from "../../../generated/graphql";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { textFormOmitValues as formOmitValues } from "../sections";
import * as _ from "lodash";

interface ResourceProps {
  type: string;
  id: string;
}

const TextNew = () => {
  useDocumentTitle("New Text");
  const navigateTo = useNavigate();
  const location = useLocation();
  const resource = location.state as ResourceProps;
  const alert = useDispatchGrowlContext();

  const { data, loading: loadingNew } = useNewTextQuery();

  const newData = {
    ...data?.newText,
    texterId: data?.newText?.texter?.id
  };
  const additionalValues = {
    texterName: data?.newText?.texter?.name
  };
  const formValues = {
    ..._.omit(newData, [...formOmitValues])
  };

  function concatenateErrorMessages(
    response: CreateTextMutation["createText"]
  ) {
    let errorMessages: string[] = [];

    if (response?.errors) {
      const errors = response.errors;

      for (const key in errors) {
        const error = errors[key];

        if (Array.isArray(error)) {
          errorMessages = errorMessages.concat(error.map(e => e));
        } else if (typeof error === "string") {
          errorMessages.push(error);
        }
      }
    }

    return errorMessages.length > 0
      ? errorMessages.join(", ")
      : "No errors found.";
  }

  const [createText] = useCreateTextMutation({
    onCompleted: (response: CreateTextMutation) => {
      if (
        response?.createText?.errors &&
        Object.keys(response?.createText?.errors).length === 0
      ) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: "The text has been created successfully",
            variant: "success"
          }
        });
        if (response.createText?.resource?.id) {
          navigateTo(`/texts/${response.createText?.resource?.id}`, {
            state: { textId: response.createText?.resource?.id }
          });
        } else {
          navigateTo(`/texts`);
        }
      } else {
        const errorMessages = concatenateErrorMessages(response?.createText);
        alert({
          type: ADD,
          payload: {
            title: "Error creating Text",
            message: errorMessages ?? "error",
            variant: "error"
          }
        });
      }
    },
    onError: err => {
      alert({
        type: ADD,
        payload: {
          title: "Error creating Text",
          message: `${err.message}` ?? "error",
          variant: "error"
        }
      });
    }
  });

  return (
    <>
      {!loadingNew && (
        <TextForm
          mutation={createText}
          values={formValues || {}}
          resourceToFill={resource}
          additionalValues={additionalValues}
        />
      )}
    </>
  );
};

export default TextNew;
