import AMP_LOGO from "../../assets/opts/logos/amp_logo.png";
import AMP_ICON from "../../assets/opts/icons/amp_icon.png";
import AMP_APPLE_ICON from "../../assets/opts/icons/amp_apple_icon.png";
import AUD_LOGO from "../../assets/opts/logos/auditordave_logo.png";
import AUD_APPLE_ICON from "../../assets/opts/icons/auditordave_apple_icon.png";
import AUD_ICON from "../../assets/opts/icons/auditordave_icon.png";
import RGO_LOGO from "../../assets/opts/logos/readygop_logo.png";
import RGO_APPLE_ICON from "../../assets/opts/icons/readygop_apple_icon.png";
import RGO_ICON from "../../assets/opts/icons/readygop.ico";
import WFW_LOGO from "../../assets/opts/logos/winningforwomen_logo.png";
import WFW_ICON from "../../assets/opts/icons/winningforwomen_icon.ico";
import WFW_APPLE_ICON from "../../assets/opts/icons/winningforwomen_apple_icon.ico";
import LFW_LOGO from "../../assets/opts/logos/leavittforwv_logo.png";
import LFW_APPLE_ICON from "../../assets/opts/icons/leavittforwv_apple_icon.png";
import LFW_ICON from "../../assets/opts/icons/leavittforwv_icon.png";
import YOP_LOGO from "../../assets/opts/logos/youropinion_logo.png";
import YOP_APPLE_ICON from "../../assets/opts/icons/youropinion_apple_icon.png";
import YOP_ICON from "../../assets/opts/icons/youropinion_icon.png";
import CCC_ICON from "../../assets/opts/icons/ccc_icon.png";
import CCC_LOGO from "../../assets/opts/logos/ccc_logo.png";
import CCC_APPLE_ICON from "../../assets/opts/icons/ccc_apple_icon.png";
import SWB_APPLE_ICON from "../../assets/opts/icons/loudermilk_apple_icon.png";
import SWB_LOGO from "../../assets/opts/logos/loudermilk_logo.png";
import SWB_ICON from "../../assets/opts/icons/loudermilk_icon.png";
import FRF_APPLE_ICON from "../../assets/opts/icons/frankyfranco_apple_icon.png";
import FRF_LOGO from "../../assets/opts/logos/frankyfranco_logo.png";
import FRF_ICON from "../../assets/opts/icons/frankyfranco_icon.png";
import TFC_APPLE_ICON from "../../assets/opts/icons/templetonforcongress_apple_icon.png";
import TFC_LOGO from "../../assets/opts/logos/templetonforcongress_logo.png";
import TFC_ICON from "../../assets/opts/icons/templetonforcongress_icon.png";
import RRZ_APPLE_ICON from "../../assets/opts/icons/ryanzinke_apple_icon.png";
import RRZ_LOGO from "../../assets/opts/logos/ryanzinke_logo.png";
import RRZ_ICON from "../../assets/opts/icons/ryanzinke_icon.png";
import MBB_APPLE_ICON from "../../assets/opts/icons/marshablackburn_apple_icon.png";
import MBB_LOGO from "../../assets/opts/logos/marshablackburn_logo.png";
import MBB_ICON from "../../assets/opts/icons/marshablackburn_icon.png";
import RSL_APPLE_ICON from "../../assets/opts/icons/rslc_apple_icon.png";
import RSL_LOGO from "../../assets/opts/logos/rslc_logo.png";
import RSL_ICON from "../../assets/opts/icons/rslc_icon.png";
import EFT_APPLE_ICON from "../../assets/opts/icons/jakeellzey_apple_icon.png";
import EFT_LOGO from "../../assets/opts/logos/jakeellzey_logo.png";
import EFT_ICON from "../../assets/opts/icons/jakeellzey_icon.png";
import SES_APPLE_ICON from "../../assets/opts/icons/stevescalise_apple_icon.png";
import SES_LOGO from "../../assets/opts/logos/stevescalise_logo.png";
import SES_ICON from "../../assets/opts/icons/stevescalise_icon.png";
import APR_APPLE_ICON from "../../assets/opts/icons/apr_apple_icon.png";
import APR_LOGO from "../../assets/opts/logos/apr_logo.png";
import APR_ICON from "../../assets/opts/icons/apr_icon.png";

// TODO: this, at one point, we should get from the DB (CMS style...?)

export const OptInData = {
  experienceamp: {
    appleIcon: AMP_APPLE_ICON,
    icon: AMP_ICON,
    form: { clientName: "Amp", logo: AMP_LOGO },
    legal: {
      organizationName: "Experience Amp",
      organizationWeb: "www.experienceamp.com",
      organizationUrl: "https://www.experienceamp.com",
      organizationEmail: "hi@experienceamp.com",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.experienceamp.com"
  },
  readygop: {
    appleIcon: RGO_APPLE_ICON,
    icon: RGO_ICON,
    form: { clientName: "ReadyGOP", logo: RGO_LOGO },
    legal: {
      organizationName: "ReadyGOP",
      organizationWeb: "www.readygop.com",
      organizationUrl: "https://www.readygop.com",
      organizationEmail: "hi@readygop.com",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.readygop.com"
  },
  "winning-for-women": {
    appleIcon: WFW_APPLE_ICON,
    icon: WFW_ICON,
    form: { clientName: "Winning For Women", logo: WFW_LOGO },
    legal: {
      organizationName: "Winning For Women",
      organizationWeb: "www.winningforwomen.com",
      organizationUrl: "https://www.winningforwomen.com",
      organizationEmail: "hi@winningforwomen.com",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.winningforwomen.com"
  },
  auditordave: {
    appleIcon: AUD_APPLE_ICON,
    icon: AUD_ICON,
    form: { clientName: "Auditor Dave", logo: AUD_LOGO },
    legal: {
      organizationName: "Auditor Dave",
      organizationWeb: "www.auditordave.com",
      organizationUrl: "https://www.auditordave.com",
      organizationEmail: "hi@auditordave.com",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.auditordave.com"
  },
  leavittforwv: {
    appleIcon: LFW_APPLE_ICON,
    icon: LFW_ICON,
    form: { clientName: "Leavitt For WV", logo: LFW_LOGO },
    legal: {
      organizationName: "Leavitt For WV",
      organizationWeb: "www.leavittforwv.com",
      organizationUrl: "https://www.leavittforwv.com",
      organizationEmail: "hi@leavittforwv.com",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.leavittforwv.com"
  },
  "your-opinion": {
    appleIcon: YOP_APPLE_ICON,
    icon: YOP_ICON,
    form: { clientName: "Your-Opinion", logo: YOP_LOGO },
    legal: {
      organizationName: "Your-Opinion.org",
      organizationWeb: "www.your-opinion.org",
      organizationUrl: "https://www.your-opinion.org",
      organizationEmail: "hi@your-opinion.org",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.your-opinion.org"
  },
  ccckypac: {
    appleIcon: CCC_APPLE_ICON,
    icon: CCC_ICON,
    form: { clientName: "Commonwealth Conservative Coalition", logo: CCC_LOGO },
    legal: {
      organizationName: "Commonwealth Conservative Coalition PAC",
      organizationWeb: "www.ccckypac.com",
      organizationUrl: "https://www.ccckypac.com/",
      organizationEmail: "hi@ccckypac.com",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.ccckypac.com"
  },
  standwithbarry: {
    appleIcon: SWB_APPLE_ICON,
    icon: SWB_ICON,
    form: { clientName: "Barry Loudermilk", logo: SWB_LOGO },
    legal: {
      organizationName: "Barry Loudermilk",
      organizationWeb: "www.standwithbarry.com/",
      organizationUrl: "https://www.standwithbarry.com/",
      organizationEmail: "hi@standwithbarry.com",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.standwithbarry.com"
  },
  frankyfranco: {
    appleIcon: FRF_APPLE_ICON,
    icon: FRF_ICON,
    form: { clientName: "Franky Franco for Congress", logo: FRF_LOGO },
    legal: {
      organizationName: "Franky Franco for Congress",
      organizationWeb: "www.frankyfranco.com",
      organizationUrl: "https://www.frankyfranco.com/",
      organizationEmail: "hi@frankyfranco.com",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.frankyfranco.com"
  },
  templetonforcongress: {
    appleIcon: TFC_APPLE_ICON,
    icon: TFC_ICON,
    form: { clientName: "Catherine Templeton for Congress", logo: TFC_LOGO },
    legal: {
      organizationName: "Catherine Templeton for Congress",
      organizationWeb: "www.templetonforcongress.com",
      organizationUrl: "https://templetonforcongress.com/",
      organizationEmail: "hi@templetonforcongress.com",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.templetonforcongress.com"
  },
  "zinke.house": {
    appleIcon: RRZ_APPLE_ICON,
    icon: RRZ_ICON,
    form: { clientName: "Representative Ryan Zinke", logo: RRZ_LOGO },
    legal: {
      organizationName: "Representative Ryan Zinke",
      organizationWeb: "www.zinke.house.gov",
      organizationUrl: "https://zinke.house.gov/",
      organizationEmail: "hi@zinke.house.gov",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.zinke.house.gov"
  },
  marshablackburn: {
    appleIcon: MBB_APPLE_ICON,
    icon: MBB_ICON,
    form: { clientName: "Marsha Blackburn for U.S. Senate", logo: MBB_LOGO },
    legal: {
      organizationName: "Marsha Blackburn for U.S. Senate",
      organizationWeb: "www.marshablackburn.com",
      organizationUrl: "https://www.marshablackburn.com/",
      organizationEmail: "hi@marshablackburn.com",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.marshablackburn.gov"
  },
  rslc: {
    appleIcon: RSL_APPLE_ICON,
    icon: RSL_ICON,
    form: {
      clientName: "Republican State Leadership Committee",
      logo: RSL_LOGO
    },
    legal: {
      organizationName: "Republican State Leadership Committee",
      organizationWeb: "www.rslc.gop",
      organizationUrl: "https://www.rslc.gop/",
      organizationEmail: "hi@rslc.gop",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.rslc.gop"
  },
  ellzeyfortexas: {
    appleIcon: EFT_APPLE_ICON,
    icon: EFT_ICON,
    form: {
      clientName: "Jake Ellzey for Texas",
      logo: EFT_LOGO
    },
    legal: {
      organizationName: "Commander Jake Ellzey",
      organizationWeb: "www.ellzeyfortexas.com",
      organizationUrl: "https://www.ellzeyfortexas.com/",
      organizationEmail: "hi@ellzeyfortexas.com",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.ellzeyfortexas.com"
  },
  stevescalise: {
    appleIcon: SES_APPLE_ICON,
    icon: SES_ICON,
    form: {
      clientName: "Steve Scalise for Congress",
      logo: SES_LOGO
    },
    legal: {
      organizationName: "Steve Scalise for Congress",
      organizationWeb: "www.stevescalise.com",
      organizationUrl: "https://stevescalise.com/",
      organizationEmail: "hi@stevescalise.com",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.stevescalise.com"
  },
  americanpromise: {
    appleIcon: APR_APPLE_ICON,
    icon: APR_ICON,
    form: {
      clientName: "American Promise",
      logo: APR_LOGO
    },
    legal: {
      organizationName: "American Promise",
      organizationWeb: "www.americanpromise.net",
      organizationUrl: "https://americanpromise.net/",
      organizationEmail: "hi@americanpromise.net",
      organizationPhone: "(785) 341-6275"
    },
    urlOrigin: "legal.americanpromise.net"
  }
};
