// third-party modules
import React from "react";
import { FormSection } from "@onehq/framework";

import ClientGeneralForm from "./ClientGeneralForm";
import ClientNumbersForm from "./ClientNumbersForm";
import ClientOptIn from "./ClientOptIn";
import ListFiltersForm from "../../../components/ListFilter/ListFiltersForm";

export * from "./ClientGeneralForm";
export default [
  new FormSection("General", ClientGeneralForm),
  new FormSection("Opt in", ClientOptIn),
  new FormSection("Phone Numbers", ClientNumbersForm),
  new FormSection("Filters", () => <ListFiltersForm resource="Client" />)
];
