import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction
} from "react";
import { TextQueryFilterInput, TextStatus } from "../generated/graphql";

interface ResourceTabsProps {
  projectsOnTeam?: string[];
  projectTexters?: string[];
  tabFilters?: TextQueryFilterInput[];
  teamsInProject?: string[];
  selectedTextType?: string;
  after?: string | null;
  sortBy?: string | null;
  sortOrder?: string | null;
}

interface ResourceTabsProviderProps {
  value: ResourceTabsProps;
  setValue: Dispatch<SetStateAction<ResourceTabsProps>>;
}

const ResourceTabsContext = createContext<
  ResourceTabsProviderProps | undefined
>(undefined);

export const defaultResourceTabsProps = {
  projectsOnTeam: [],
  projectTexters: [],
  tabFilters: [],
  teamsInProject: [],
  selectedTextType: TextStatus.Active,
  after: null,
  sortBy: null,
  sortOrder: null
};

export const ResourceTabsProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [value, setValue] = useState<ResourceTabsProps>(
    defaultResourceTabsProps
  );

  return (
    <ResourceTabsContext.Provider value={{ value, setValue }}>
      {children}
    </ResourceTabsContext.Provider>
  );
};

export const useResourceTabsProvider = () => {
  const context = useContext(ResourceTabsContext);
  if (!context) {
    throw new Error(
      "useResourceTabsProvider must be used within a ResourceTabsProvider"
    );
  }
  return context;
};
