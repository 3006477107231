// react modules
import React from "react";

// third-party modules
import { Fieldset, TextField, PhoneField } from "@onehq/anton";

const UserPhonesForm = () => {
  return (
    <Fieldset>
      <PhoneField label="Number" name="number" />
      <TextField label="Description" name="description" />
    </Fieldset>
  );
};

export default UserPhonesForm;
