import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { theme } from "@onehq/style";
import { Button, SearchInput as _SearchInput, Icon } from "@onehq/anton";
import { SelectFieldOptionType } from "../types";
import { WindowSizeContext } from "../routes";
import { components } from "react-select";
import {
  FilterOperation,
  GetPhonesListQuery,
  PhoneQueryFilterFields,
  PhoneStatusGroup,
  useGetPhonesListLazyQuery,
  useGetPhonesListQuery
} from "../generated/graphql";
import { DEFAULT_LIMIT } from "../constants";
import { formatPhoneList } from "./options";

interface TabFilterProps {
  placeholder?: string;
  resource?: any;
  setResource: React.Dispatch<
    React.SetStateAction<{ label: string; value: string }[]>
  >;
  handleRefetch?: () => any;
}
const { spacing4, spacing20 } = theme.space;
const { sm, base } = theme.font;

const Root = styled.div<{ mobile?: boolean }>`
  font-size: ${props => (props.mobile ? sm.size : base.size)};
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${spacing20} 0;
  z-index: 2;
  display: flex;
  ${props =>
    props.mobile &&
    css`
      margin: ${spacing4};
    `}
`;

const MidSizeFont = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchContainer = styled.div<{ mobile?: boolean }>`
  display: flex;
  font-size: ${sm.size};
  width: 100%;
  max-width: 418px;
  padding-left: 12px;
`;

const SearchInput = styled(_SearchInput)`
  width: 100%;
`;

const ClearContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: center;
  width: 100%;
`;

const ToPhoneTabFilter = (props: TabFilterProps) => {
  const windowObserver = useContext(WindowSizeContext);
  const { handleRefetch, placeholder, resource, setResource } = props;
  const [defaultToPhoneOptions, setDefaultToPhoneOptions] = useState<
    SelectFieldOptionType[]
  >([]);

  const [phonesQuery] = useGetPhonesListLazyQuery({
    fetchPolicy: "cache-and-network"
  });

  // getting To Phone list for the default option filter
  const { data: toPhonesList } = useGetPhonesListQuery({
    variables: {
      filters: [
        {
          field: PhoneQueryFilterFields.PhoneTypeId,
          operation: FilterOperation.NotEqual,
          value: "PhoneType::::Twilio"
        }
      ],
      statusGroupFilter: PhoneStatusGroup.ToPhone,
      limit: DEFAULT_LIMIT
    }
  });

  useEffect(() => {
    if (toPhonesList) {
      setDefaultToPhoneOptions(
        formatPhoneList(toPhonesList.phones?.nodes || [])
      );
    }
  }, [toPhonesList]);

  // clear filter valur function
  const clearFilter = () => {
    setResource([]);
    handleRefetch && handleRefetch();
  };

  // onChange filter value function
  const onChangeFilter = (items: any[]) => {
    if (items && items.values()) {
      const object: { label: string; value: string }[] = items.map(object => ({
        label: object.label,
        value: object.value
      }));
      setResource(object);
      handleRefetch && handleRefetch();
    }
  };

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon name={"search"} color={"neutral55"} />
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <Root mobile={windowObserver.isMobile}>
      <MidSizeFont>Filters</MidSizeFont>
      <SearchContainer mobile={windowObserver.isMobile}>
        {/* @ts-ignore */}
        <SearchInput
          controlPosition="left"
          isMulti
          name="tabFilter"
          placeholder={placeholder}
          defaultOptions={defaultToPhoneOptions}
          value={resource}
          onChange={onChangeFilter}
          loadOptions={(text: string) =>
            phonesQuery({
              variables: {
                filters: [
                  {
                    field: PhoneQueryFilterFields.Number,
                    operation: FilterOperation.Like,
                    value: text
                  },
                  {
                    field: PhoneQueryFilterFields.PhoneTypeId,
                    operation: FilterOperation.NotEqual,
                    value: "PhoneType::::Twilio"
                  }
                ],
                statusGroupFilter: PhoneStatusGroup.ToPhone
              }
            })
              // TODO: remove
              // @ts-ignore
              .then(response =>
                formatPhoneList(
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                  (response.data?.phones
                    ?.nodes as GetPhonesListQuery["phones"]["nodes"]) || []
                )
              )
          }
          configComponents={{
            ClearIndicator: null,
            DropdownIndicator
          }}
        />
      </SearchContainer>
      <ClearContainer>
        <Button key={"clearFilter"} variant="inline" onClick={clearFilter}>
          Clear Filter
        </Button>
      </ClearContainer>
    </Root>
  );
};

export default ToPhoneTabFilter;
