import { Route } from "@onehq/framework";

import Login from "./Login";
import Logout from "./Logout";
import PasswordRecovery from "./PasswordRecovery";
import PasswordReset from "./PasswordReset";
import SignUp from "./SignUp";
import UpdatePassword from "./UpdatePassword";

const MainLoginRoute = new Route({
  name: "Main Login",
  path: "",
  component: Login
});

const LoginRoute = new Route({
  name: "Login",
  path: "login",
  component: Login
});

const LogoutRoute = new Route({
  name: "Logout",
  path: "logout",
  component: Logout
});

const PasswordRecoveryRoute = new Route({
  name: "PasswordRecovery",
  path: "password-recovery",
  component: PasswordRecovery
});

const PasswordResetRoute = new Route({
  name: "PasswordReset",
  path: "reset",
  component: PasswordReset
});

const SignUpRoute = new Route({
  name: "SignUp",
  path: "signup",
  component: SignUp
});

const UpdatePasswordRoute = new Route({
  name: "UpdatePassword",
  path: "update-password",
  component: UpdatePassword
});

export {
  LogoutRoute,
  LoginRoute,
  MainLoginRoute,
  PasswordRecoveryRoute,
  PasswordResetRoute,
  SignUpRoute,
  UpdatePasswordRoute
};
