import React from "react";

import { Fieldset, NestedForm, TextField } from "@onehq/anton";

const NestedTextDefaults: React.FC = () => {
  return (
    <>
      <TextField label="Key" name="field" required span={12} />
      <TextField label="Value" name="valueField" required span={12} />
    </>
  );
};

const TextDefaultForm = () => {
  return (
    <Fieldset>
      <NestedForm
        name="textDefaults"
        component={NestedTextDefaults}
        removable
        addable
      />
    </Fieldset>
  );
};

export default TextDefaultForm;
