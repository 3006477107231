import React from "react";
import {
  useUpdateTextMutation,
  UpdateTextMutation,
  useGetTextQuery
} from "../../../generated/graphql";
import TextForm, {
  textFormOmitValues as formOmitValues
} from "../sections/TextForm";
import {
  ADD,
  GrowlAlertDispatch,
  useDispatchGrowlContext
} from "@onehq/framework";
import { addErrorAlert } from "../../../utils";
import { useParams } from "react-router-dom";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import _ from "lodash";

const TextEdit = () => {
  const params = useParams();
  const alert: GrowlAlertDispatch = useDispatchGrowlContext();

  const id = params.id ? params.id : "";
  const text = useGetTextQuery({
    fetchPolicy: "cache-and-network",
    variables: { id }
  });

  const values: any = { ...text?.data?.text } || {};
  useDocumentTitle([
    "Text",
    `${values?.project?.name} - ${values?.texter?.name}`
  ]);

  const formValuesAux = _.omit(values, formOmitValues); // removing unnecessary values for edition
  const formValues = {
    ...formValuesAux,
    projectId: values.project?.id,
    texterId: values.texter?.id,
    fromPhoneId: values.fromPhone?.id,
    toPhoneId: values.toPhone?.id
  };

  const additionalValues = {
    projectName: values.project?.name,
    texterName: values.texter?.name,
    fromPhoneNumber: values.fromPhone?.number,
    toPhoneNumber: values.toPhone?.number
  };

  const [updateUser] = useUpdateTextMutation({
    onCompleted: (response: UpdateTextMutation) => {
      if (
        response.updateText?.errors &&
        Object.keys(response.updateText?.errors).length === 0
      ) {
        alert({
          type: ADD,
          payload: {
            title: "All changes saved",
            message: "The text has been edited successfully",
            variant: "success"
          }
        });
      } else {
        response.updateText?.errors &&
          addErrorAlert(
            alert,
            "Error, text not updated",
            // eslint-disable-next-line
            Object.keys(response.updateText.errors)
              .map(el =>
                el === "base"
                  ? response.updateText?.errors[el]
                  : `${el}: ${response.updateText?.errors[el]}`
              )
              .join(". ")
          );
      }
    },
    onError: err => {
      console.error(err); // the error if that is the case
    }
  });

  return (
    <>
      <TextForm
        mutation={updateUser}
        values={formValues}
        additionalValues={additionalValues}
      />
    </>
  );
};

export default TextEdit;
