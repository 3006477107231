import { Route } from "@onehq/framework";

import PrivacyShow from "./PrivacyShow";

const PrivacyRoute = new Route({
  name: "Privacy",
  path: "privacy",
  component: PrivacyShow
});

export default PrivacyRoute;
