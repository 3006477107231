import {
  ClientQueryFilterFields,
  FilterOperation,
  GetClientsListCsvDocument,
  GetProjectsListCsvDocument,
  ProjectQueryFilterFields
} from "../generated/graphql";
import { BaseFilterTabsProps } from "../types";
import { PAGE_SIZE } from "../utils";

export const CLIENTS_PATH = "clients";

export const CLIENT_MENU_ANCHOR_VIEW_LINKS = {
  OVERVIEW: "Overview",
  PROJECTS: "Projects",
  AGENCIES: "Agencies",
  LIST_FILTERS: "List Filters"
};

export const CLIENT_VIEW_LINKS = [
  {
    id: CLIENT_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    text: CLIENT_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    name: CLIENT_MENU_ANCHOR_VIEW_LINKS.OVERVIEW,
    path: "/overview"
  },
  {
    id: CLIENT_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    text: CLIENT_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    name: CLIENT_MENU_ANCHOR_VIEW_LINKS.PROJECTS,
    path: "/projects"
  },
  {
    id: CLIENT_MENU_ANCHOR_VIEW_LINKS.AGENCIES,
    text: CLIENT_MENU_ANCHOR_VIEW_LINKS.AGENCIES,
    name: CLIENT_MENU_ANCHOR_VIEW_LINKS.AGENCIES,
    path: "/agencies"
  }
];

export const clientTabFilters = ({ id, selectedTab }: BaseFilterTabsProps) => {
  const filterOptions = {
    [CLIENT_MENU_ANCHOR_VIEW_LINKS.AGENCIES]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: ClientQueryFilterFields.AgencyId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetClientsListCsvDocument
    },
    [CLIENT_MENU_ANCHOR_VIEW_LINKS.PROJECTS]: {
      variables: {
        first: PAGE_SIZE,
        filters: [
          {
            field: ProjectQueryFilterFields.ClientId,
            operation: FilterOperation.Equal,
            value: id
          }
        ]
      },
      listDocument: GetProjectsListCsvDocument
    }
  };
  return filterOptions[selectedTab];
};
